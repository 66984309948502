import React from "react"

import { Grid } from "@material-ui/core"

import { Portlet } from "@/components"
import InfoItemInput from "@/components/InfoItemInput"

import ManagementUserAccessDataDialog from "@/pages/Admin/Security/ManagementUserAccessData/ManagementPasswordDialog"
import MFAInputItem from "@/pages/Admin/Security/ManagementUserAccessData//MFAInputItem"

import HardCoded from "@/services/HardCoded"

const ManagementUserAccessData = () => {
	const handleOpenManagementPasswordDialog = (): void => {
		ManagementUserAccessDataDialog.open()
	}

	return (
		<Grid
			container
			direction="column"
			spacing={3}
		>
			<Grid
				item
			>
				<Portlet>
					<Grid
						container
						direction="column"
						spacing={3}
					>
						{HardCoded.checkFeatureFlag("mfa") && (
							<Grid
								item
							>
								<MFAInputItem />
							</Grid>
						)}

						<Grid
							item
						>
							<InfoItemInput
								title="Senha"
								onEdit={handleOpenManagementPasswordDialog}
							/>
						</Grid>
					</Grid>
				</Portlet>
			</Grid>
		</Grid>
	)
}

export default ManagementUserAccessData
