import _ from "lodash"

import { ChatBotFlowBlockContent, RegisterContactToActiveCampaignActionOption } from "@/protocols/chatBot"
import { ConstructionResources, TagOption } from "@/protocols/chatBotConstructor"

type SaveInputLocationData = {
	slug: string
	name: string
	variableName: string
	type: ChatBotFlowBlockContent["locationToSaveInput"]["type"]
	customFieldId?: number
}

type CurrentAttendanceTransferOption = {
	context: {
		name: string
		type: ChatBotFlowBlockContent["attendanceTransferData"]["type"]
	}
	attendance: {
		name: string
		type: "team" | "user"
		teamId?: number
		userId?: number
	}
}

type FormattedAttendanceTransferOptions = {
	context: CurrentAttendanceTransferOption["context"][]
	attendance: CurrentAttendanceTransferOption["attendance"][]
}

export const getCurrentTagsOptions = (
	constructionResources: ConstructionResources,
	chatBotFlowBlockContent: ChatBotFlowBlockContent
) => {
	const tagsToAdd: TagOption[] = []
	const tagsToRemove: TagOption[] = []

	chatBotFlowBlockContent.clientTagsManagementData?.actions?.forEach(action => {
		const tag = constructionResources.tagOptions?.find(({ id }) => id === action.tagId)

		if (tag) {
			const tagOption: TagOption = {
				id: tag.id,
				color: tag.color,
				name: tag.name
			}

			if (action.type === "add") {
				tagsToAdd.push(tagOption)
			}

			if (action.type === "remove") {
				tagsToRemove.push(tagOption)
			}
		}
	})

	return {
		tagsToAdd,
		tagsToRemove
	}
}

export const formatSaveInputLocationOptions = (constructionResources: ConstructionResources): SaveInputLocationData[] => {
	const customFieldOptions: SaveInputLocationData[] = constructionResources.saveInputLocationOptions?.["custom-field"]?.map(option => ({
		slug: `custom-field-${option.customFieldId}`,
		customFieldId: option.customFieldId,
		name: option.name,
		type: "custom-field",
		variableName: option.variableName
	}))

	const clientVariableOptions: SaveInputLocationData[] = constructionResources.saveInputLocationOptions?.client?.map(option => ({
		slug: `client-${option.name}`,
		name: option.name,
		type: "client",
		variableName: option.name
	}))

	const allOptions: SaveInputLocationData[] = [
		...clientVariableOptions,
		...customFieldOptions
	]

	return allOptions
}

export const getCurrentSaveInputLocationOption = (
	constructionResources: ConstructionResources,
	chatBotFlowBlockContent: ChatBotFlowBlockContent
) => {
	const saveInputLocationOptions = formatSaveInputLocationOptions(constructionResources)

	const customFieldId = chatBotFlowBlockContent?.locationToSaveInput?.customFieldId
	const type = chatBotFlowBlockContent?.locationToSaveInput?.type
	const contactVariableName = chatBotFlowBlockContent?.locationToSaveInput?.contactVariableName

	const option = _.find(saveInputLocationOptions, {
		...(type === "custom-field" && ({ customFieldId })),
		...(type === "client" && ({ variableName: contactVariableName })),
		type
	})

	return option
}

export const getCurrentGoToFlowOption = (
	constructionResources: ConstructionResources,
	chatBotFlowBlockContent: ChatBotFlowBlockContent
) => {
	const goToFlowOptions = constructionResources.goToFlowOptions

	const option = _.find(goToFlowOptions, {
		chatBotFlowId: chatBotFlowBlockContent.goToChatBotFlowId
	})

	return option
}

export const formatAttendanceTransferOptions = (constructionResources: ConstructionResources): FormattedAttendanceTransferOptions => {
	const formattedOptions: FormattedAttendanceTransferOptions = {
		context: [],
		attendance: []
	}

	constructionResources.attendanceTransferOptions.context.map(context => {
		formattedOptions.context.push({
			name: context.title,
			type: context.type
		})
	})

	constructionResources.attendanceTransferOptions.attendance.users.map(user => {
		formattedOptions.attendance.push({
			type: "user",
			name: user.name,
			userId: user.id
		})
	})

	constructionResources.attendanceTransferOptions.attendance.teams.map(team => {
		formattedOptions.attendance.push({
			type: "team",
			name: team.name,
			teamId: team.id
		})
	})

	return formattedOptions
}

export const getCurrentAttendanceTransferOption = (
	constructionResources: ConstructionResources,
	chatBotFlowBlockContent: ChatBotFlowBlockContent
): CurrentAttendanceTransferOption => {
	const formattedOptions = formatAttendanceTransferOptions(constructionResources)

	const teamId = chatBotFlowBlockContent?.attendanceTransferData?.teamId
	const userId = chatBotFlowBlockContent?.attendanceTransferData?.userId
	const type = chatBotFlowBlockContent?.attendanceTransferData?.type

	const context = _.find(formattedOptions.context, { type })

	let attendance: CurrentAttendanceTransferOption["attendance"] | undefined

	const shouldFindAttendance = Boolean(teamId) || Boolean(userId)

	if (shouldFindAttendance) {
		attendance = _.find(formattedOptions.attendance, {
			...(teamId && { teamId }),
			...(userId && { userId })
		})
	}

	return {
		context: context as CurrentAttendanceTransferOption["context"],
		attendance: attendance as CurrentAttendanceTransferOption["attendance"]
	}
}

export const getWebhookUrl = (chatBotFlowBlockContent: ChatBotFlowBlockContent) => {
	return chatBotFlowBlockContent?.webhook?.url
}

export const getCurrentOptionByContactAlreadyExists = (constructionResources: ConstructionResources, chatBotFlowBlockContent: ChatBotFlowBlockContent): RegisterContactToActiveCampaignActionOption | undefined => {
	const registerContactToActiveCampaignActionOptions = constructionResources?.registerContactToActiveCampaignActionOptions

	const option = _.find(registerContactToActiveCampaignActionOptions, {
		name: chatBotFlowBlockContent?.registerContactToActiveCampaignActionOption?.name,
		slug: chatBotFlowBlockContent?.registerContactToActiveCampaignActionOption?.slug
	})

	return option
}

export const getCurrentDeal = (chatBotFlowBlockContent: ChatBotFlowBlockContent) => {
	return chatBotFlowBlockContent?.deal
}

export const getCurrentAutoSendMessageOption = (chatBotFlowBlockContent: ChatBotFlowBlockContent) => {
	const option = {
		respect: "Sim",
		"no-respect": "Não"
	}

	if (!chatBotFlowBlockContent.autoSendMessagesOption) {
		return null
	}

	return option[chatBotFlowBlockContent.autoSendMessagesOption]
}

export const getCurrentActiveCampaignTagName = (chatBotFlowBlockContent: ChatBotFlowBlockContent) => {
	return chatBotFlowBlockContent?.activeCampaignTagName
}

export const getCurrentMetaAdsEventName = (chatBotFlowBlockContent: ChatBotFlowBlockContent) => {
	return chatBotFlowBlockContent?.metaAdsConversionEvent?.name
}

export const getCurrentAttendanceTransferDescription = (chatBotFlowBlockContent: ChatBotFlowBlockContent) => {
	return chatBotFlowBlockContent?.attendanceAssignmentData?.assignmentObservation
}

export const getWillShowAttendanceTransferDescription = (chatBotFlowBlockContent: ChatBotFlowBlockContent) => {
	const transferTypeWithOptionDescription: ChatBotFlowBlockContent["attendanceTransferData"]["type"][] = [
		"random-team-attendant",
		"random-attendant",
		"specific-attendant",
		"specific-team"
	]

	return transferTypeWithOptionDescription.includes(chatBotFlowBlockContent?.attendanceTransferData?.type as ChatBotFlowBlockContent["attendanceTransferData"]["type"])
}

export const getCurrentWABATemplate = (constructionResources: ConstructionResources, chatBotFlowBlockContent: ChatBotFlowBlockContent) => {
	const currentWABAMessageTemplate = constructionResources.wabaChannelMessageTemplates.find(wabaChannelMessageTemplate => wabaChannelMessageTemplate.id === chatBotFlowBlockContent.wabaChannelMessageTemplate?.id)

	return currentWABAMessageTemplate
}
