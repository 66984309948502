import colors from "@/styles/colors"
import newColors from "@/styles/newColors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
	icon: {
		width: "32px",
		height: "32px",
		color: newColors.purple[800]
	},
	editIcon: {
		color: colors.grayScale[4],
		fontSize: "20px"
	},
	listItemButton: {
		background: colors.grayScale[12],
		opacity: "0.7",
		width: "64px",
		height: "64px",
		borderRadius: "8px",
		gap: "8px",
		"&:hover": {
			backgroundColor: colors.unrelated.D5D5D5
		}
	}
}))

export default useStyles
