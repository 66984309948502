import { makeStyles } from "@material-ui/core"

import { isSmallScreen } from "@/utils/checkDevice"
import colors from "@/styles/colors"
import newColors from "@/styles/newColors"

export const CHAT_LEFT_SIDE_WIDTH = "418px"

const useStyles = makeStyles(theme => ({
	chatDrawer: {
		width: "100%",
		height: "100%",
		display: "flex",
		flex: 1
	},
	chatDrawerPaper: {
		position: isSmallScreen ? "absolute" : "relative",
		width: "100%",
		flexWrap: "wrap"
	},
	chatRightSideContainer: {
		flex: 1,
		backgroundColor: colors.unrelated.E5E0DB,
		maxHeight: "100%",
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	fixedHeightContainer: {
		height: "100%",
		flex: 1,
		position: "relative"
	},
	chatHeader: {
		backgroundColor: colors.unrelated.EDEDED,
		padding: theme.spacing(1, 2),
		flexWrap: "wrap"
	},
	logo: {
		width: 40,
		display: "flex",
		alignSelf: "center",
		color: newColors.getPrimaryColor(),
		margin: "10px"
	},
	closeIcon: {
		color: colors.grayScale[1]
	}
}))

export default useStyles
