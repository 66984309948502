import { makeStyles } from "@material-ui/core"

import newColors from "@/styles/newColors"

const useStyles = makeStyles({
	title: {
		color: newColors.yellow[400],
		fontSize: 16,
		fontWeight: "normal"
	},
	titleIcon: {
		color: newColors.yellow[400]
	}
})

export default useStyles
