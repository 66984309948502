import React from "react"
import _ from "lodash"
import {
	Grid,
	Select,
	MenuItem,
	Typography,
	Divider as MuiDivider
} from "@material-ui/core"

import colors from "@/styles/colors"

import {
	RULE_LOGIC_NAME,
	VALIDATION_TYPE_NAME
} from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/genericConditionConstants"

import { PopConfirm, Divider } from "@/components"

import BlockBodySectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionEditor"
import CreateConditionButton from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/CreateConditionButton"
import DeleteButton from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/DeleteButton"

import useConditionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useConditionEditor"
import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"
import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/GenericConditionSectionEditor/styles"

import {
	ChatBotFlowBlockRule,
	ChatBotFlowBlockRuleValidation,
	ChatBotFlowBlockRuleValidationSlug,
	ChatBotFlowBlockRuleValidationType,
	ChatBotFlowBlockType
} from "@/protocols/chatBot"
import { ConstructionResources } from "@/protocols/chatBotConstructor"

import { isLastItem } from "@/utils/array"

export type AvailableValidationInputParamProps = {
	onChangeValidationValue: (value: string | number) => void
	onChangeValidation: (validation: Partial<ChatBotFlowBlockRuleValidation>) => void
	value: string | number
	validation: ChatBotFlowBlockRuleValidation
	constructionResources: ConstructionResources
}

type ValidationInputParam = {
	render: React.FC<AvailableValidationInputParamProps>
}

export type AvailableValidationInputValue = {
	/**
	 * You can use this param in case you want to fully override the default
	 * rendered validation element.
	 */
	validationRender?: React.FC<AvailableValidationInputParamProps>
	title: string
	defaultFirstParamValue: string
	firstParam?: ValidationInputParam
	secondParam?: ValidationInputParam
	types?: ChatBotFlowBlockRuleValidationType[]
}

type AvailableValidationInput = {
	[key in ChatBotFlowBlockRuleValidationSlug]?: AvailableValidationInputValue
}

type GenericConditionSectionEditorProps = {
	availableValidationInputs: AvailableValidationInput
	onSave: (chatBotFlowBlockRule: ChatBotFlowBlockRule) => void
	onDelete?: () => void
	chatBotFlowBlockRule: ChatBotFlowBlockRule
	chatBotFlowBlockType: ChatBotFlowBlockType
	hideChildSelection?: boolean
	title?: string
}

const GenericConditionSectionEditor: React.FC<GenericConditionSectionEditorProps> = (props) => {
	const {
		onSave,
		chatBotFlowBlockRule,
		children,
		hideChildSelection,
		onDelete,
		availableValidationInputs,
		chatBotFlowBlockType,
		title
	} = props

	const classes = useStyles()
	const conditionEditor = useConditionEditor({ chatBotFlowBlockRule })
	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()

	const getDefaultValidationDataByValidationSlug = (slug: ChatBotFlowBlockRuleValidationSlug) => {
		const block = chatBotFlowConstructorStore.getFlowBlockByType(chatBotFlowBlockType)

		if (block) {
			const allValidations = block.available_next_block_flow_rules.reduce((validations: ChatBotFlowBlockRuleValidation[], nextBlockFlowRule) => ([
				...validations,
				...nextBlockFlowRule.validations
			]), [])

			const validation = allValidations.find(validation => validation.slug === slug)

			/**
			 * WARNING:
			 * - Make sure to use clone deep to avoid changing the data inside 'constructionResources'
			 * by reference.
			 */
			return _.cloneDeep(validation)
		}
	}

	const handleCreateValidation = () => {
		const defaultSlug = Object.keys(availableValidationInputs)[0] as ChatBotFlowBlockRuleValidationSlug

		const defaultValidationData = getDefaultValidationDataByValidationSlug(defaultSlug)

		conditionEditor.createValidation(defaultValidationData || {})
	}

	const handleDeleteValidation = (index: number) => {
		PopConfirm.open({
			title: "Excluir condição",
			description: "Tem certeza? Essa ação é irreversível.",
			confirmButtonText: "EXCLUIR",
			onConfirm: () => conditionEditor.deleteValidation(index)
		})
	}

	return (
		<BlockBodySectionEditor
			title={title || "CONDIÇÃO"}
			onSave={() => onSave(conditionEditor.rule)}
			onOpen={conditionEditor.reset}
			onDelete={onDelete}
			hideChildSelection={hideChildSelection}
			AsideDrawerProps={{
				children: (
					<Grid
						container
						spacing={1}
					>
						{conditionEditor.rule.validations?.map((validation, index) => {
							const validationInput = availableValidationInputs[validation.slug as ChatBotFlowBlockRuleValidationSlug]

							if (!validationInput) {
								return null
							}

							const availableValidations = Object.entries(availableValidationInputs).map(([key, value]) => ({
								slug: key as ChatBotFlowBlockRuleValidationSlug,
								title: value.title
							}))

							return (
								<Grid
									key={index}
									item
									xs={12}
								>
									<Grid
										container
										spacing={1}
									>
										{validationInput.validationRender ? (
											<Grid
												item
												xs={12}
											>
												<Grid
													container
												>
													{(
														validationInput.validationRender({
															validation,
															value: validation.second_param,
															onChangeValidationValue: (value) => conditionEditor.changeValidation(index, { second_param: value }),
															onChangeValidation: (validation) => conditionEditor.changeValidation(index, validation),
															constructionResources: chatBotFlowConstructorStore.constructionResources
														})
													)}
												</Grid>
											</Grid>
										) : (
											<>
												<Grid
													item
													xs={12}
												>
													<Grid
														container
													>
														<Select
															value={validation.slug}
															variant="outlined"
															onChange={(event) => {
																const updatedValidationSlug = event.target.value as ChatBotFlowBlockRuleValidationSlug
																const updatedDefaultFirstParamValue = availableValidationInputs?.[updatedValidationSlug]?.defaultFirstParamValue

																const additionalBlockRuleValidation: Partial<ChatBotFlowBlockRuleValidation> = {}
																additionalBlockRuleValidation.first_param = updatedDefaultFirstParamValue

																conditionEditor.changeValidation(index, { slug: updatedValidationSlug, ...additionalBlockRuleValidation })
															}}
															fullWidth
														>
															{availableValidations.map((availableValidation, index) => (
																<MenuItem
																	key={index}
																	value={availableValidation.slug}
																>
																	{availableValidation.title}
																</MenuItem>
															))}
														</Select>
													</Grid>
												</Grid>

												{validationInput.firstParam && (
													<Grid
														item
														xs={12}
													>
														<Grid
															container
														>
															{validationInput.firstParam?.render({
																validation,
																value: validation.first_param,
																onChangeValidationValue: (value) => conditionEditor.changeValidation(index, { first_param: String(value) }),
																onChangeValidation: (validation) => conditionEditor.changeValidation(index, validation),
																constructionResources: chatBotFlowConstructorStore.constructionResources
															})}
														</Grid>
													</Grid>
												)}

												<Grid
													item
													xs={12}
												>
													<Grid
														container
														spacing={1}
													>
														<Grid
															item
															xs={6}
														>
															<Select
																value={validation.type}
																variant="outlined"
																onChange={(event) => conditionEditor.changeValidation(index, { type: event.target.value as ChatBotFlowBlockRuleValidationType })}
																fullWidth
															>
																{validationInput.types?.map(type => (
																	<MenuItem
																		key={type}
																		value={type}
																	>
																		{VALIDATION_TYPE_NAME[type]}
																	</MenuItem>
																))}
															</Select>
														</Grid>

														<Grid
															item
															xs={6}
														>
															{validationInput.secondParam?.render({
																validation,
																value: validation.second_param,
																onChangeValidationValue: (value) => conditionEditor.changeValidation(index, { second_param: value }),
																onChangeValidation: (validation) => conditionEditor.changeValidation(index, validation),
																constructionResources: chatBotFlowConstructorStore.constructionResources
															})}
														</Grid>
													</Grid>
												</Grid>
											</>
										)}

										<Grid
											item
											xs={12}
										>
											<Grid
												container
												alignItems="center"
												justify="flex-end"
											>
												<DeleteButton
													onClick={() => handleDeleteValidation(index)}
												/>
											</Grid>
										</Grid>

										{!isLastItem(index, conditionEditor.rule.validations.length) && (
											<Grid
												item
												xs={12}
											>
												<Grid
													container
													direction="column"
													alignItems="center"
													justify="flex-end"
												>
													<Grid
														container
														alignItems="center"
													>
														<Typography
															variant="caption"
															className={classes.ruleLogicNameText}
														>
															{RULE_LOGIC_NAME[conditionEditor.rule.logic]}
														</Typography>

														<MuiDivider
															className={classes.ruleLogicNameDivider}
														/>
													</Grid>

													<Divider orientation="horizontal" size={1} />
												</Grid>
											</Grid>
										)}
									</Grid>
								</Grid>
							)
						})}

						<Grid
							item
							xs={12}
						>
							<Grid
								container
								alignItems="center"
								justify="flex-end"
							>
								{conditionEditor.rule.validations.length > 0 && (
									<Divider orientation="horizontal" size={4} />
								)}

								<CreateConditionButton
									onClick={handleCreateValidation}
									color={colors.palette.primary}
								>
									Adicionar condição
								</CreateConditionButton>
							</Grid>
						</Grid>
					</Grid>
				)
			}}
			DeletePopConfirmProps={{
				title: "Excluir condição",
				description: "Tem certeza? Essa ação é irreversível.",
				confirmButtonText: "EXCLUIR"
			}}
		>
			{children}
		</BlockBodySectionEditor>
	)
}

export default GenericConditionSectionEditor
