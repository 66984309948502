import React, { useRef, useState } from "react"

import { WABATemplateCategory, WABATemplateHeaderBody, WABATemplateHeaderType, WABATemplateHeaderTypeToTemplateHeaderBody } from "@/@integrations/WABA/protocols/wabaChannelMessageTemplate"
import WABAChannelMessageTemplateService from "@/@integrations/WABA/services/WABAChannelMessageTemplate"

import TextInput, { TextInputHandler } from "@/pages/Attendance/Chat/ConversationPanel/Input/TextInput"
import EmojiPicker from "@/pages/Attendance/Chat/ConversationPanel/Input/EmojiPicker"

import { Box, Typography, TextField, Button, InputLabel, Grid, RadioGroup, Paper, Radio, CardContent, MenuItem, Select, Tooltip, Chip, FormHelperText, InputAdornment, IconButton, FormControlLabel, Checkbox, Menu } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import WhatsappBackground from "@/assets/images/waba/whatsapp_background.png"
import MediaService, { Media } from "@/services/Media"
import ApiService from "@/services/Api"
import {
	Add, Close as CloseIcon
} from "@material-ui/icons"
import VariableInput, { Variable } from "@/components/ChatMessageBuilder/VariableInput"
import MessageItem from "@/components/ChatMessageBuilder/MessageItem"
import { Breadcrumb, Divider, Portlet, TextEditor } from "@/components"
import useStyles from "@/pages/Admin/Templates/ManageTemplateForm/styles"
import useCustomStyles from "@/styles/custom"
import useDidMount from "@/hooks/useDidMount"
import useValidation, { ErrorType } from "@/hooks/useValidation"
import { hasVariable } from "@/utils/message"
import { useGlobalStateStore } from "@/store/GlobalState"
import { useHistory, useLocation } from "react-router-dom"
import ErrorHandlerService from "@/services/ErrorHandler"
import { ButtonMessageData, ButtonTypes } from "@/protocols/channel"
import { generateUUID } from "@/utils/id"
import TemplateFileInput from "@/pages/Admin/Templates/ManageTemplateForm/TemplateFileInput"
import { TextEditorInputHandler } from "@/components/TextEditor"

type LanguageCode = "pt_BR" | "en" | "es"

type ManageTemplateHeader = {
	type: WABATemplateHeaderType
	content?: WABATemplateHeaderBody<WABATemplateHeaderType>
}

export type ManageTemplate = {
	id?: string
	category: AvailableWABATemplateCategory
	content: string
	header?: ManageTemplateHeader
	name: string
	languageCode: LanguageCode
	footer?: string
	buttons?: ButtonMessageData[]
	mediaData?: {
		base64: string
		mimeType: string
	}
	allowTemplateCategoryChange?: boolean
}

type CategoryOption = {
	label: string
	category: AvailableWABATemplateCategory
	description: string
}

type AvailableWABATemplateCategory = Exclude<WABATemplateCategory, "authentication" | "otp">

const MAX_BUTTON_LIMIT = 10

const ManageTemplateForm = () => {
	const location = useLocation<{ isEditMode: number, id: string }>()
	const isEditMode = Boolean(location.state?.isEditMode)
	const [manageTemplateData, setManageTemplateData] = useState<ManageTemplate>({
		languageCode: "pt_BR",
		allowTemplateCategoryChange: false
	} as ManageTemplate)
	const [variables, setVariables] = useState<Variable[]>([] as Variable[])
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

	const [loading, setLoading] = useState(false)
	const {
		validation,
		triggerValidation,
		clearValidation
	} = useValidation()
	const customClasses = useCustomStyles()
	const classes = useStyles({ isEditMode })
	const history = useHistory()
	const globalStateStore = useGlobalStateStore()
	const footerInputRef = useRef<TextInputHandler>(null)
	const headerInputRef = useRef<TextInputHandler>(null)
	const editorRef = useRef<TextEditorInputHandler>(null)
	const textHeaderContent: WABATemplateHeaderTypeToTemplateHeaderBody["text"] = manageTemplateData?.header?.content as WABATemplateHeaderTypeToTemplateHeaderBody["text"]
	const isUsingMaxVariablesOnHeader = manageTemplateData?.header?.type === "text" && hasVariable(headerInputRef.current?.getCurrentValue())
	const inboxChannelId = globalStateStore.currentChannel?.id

	const CATEGOTY_TO_READABLE_STATUS: Record<AvailableWABATemplateCategory, string> = {
		marketing: "Marketing",
		utility: "Utilidade"
	}

	const CATEGORY_OPTION: CategoryOption[] = [
		{
			label: CATEGOTY_TO_READABLE_STATUS.marketing,
			category: "marketing",
			description: "Promoções, ofertas de produtos e outras novidades para aumentar o reconhecimento da marca e o engajamento do público."
		},
		{
			label: CATEGOTY_TO_READABLE_STATUS.utility,
			category: "utility",
			description:
				"Envie atualizações de conta, status de pedidos, alertas e outras informações importantes para manter seus clientes informados."
		}
	]

	const LANGUAGE_CODE_TO_READABLE_LANGUAGE: Record<ManageTemplate["languageCode"], string> = {
		pt_BR: "Portugues (BR)",
		en: "Ingles",
		es: "Espanhol"
	}

	const TEMPLATE_HEADER_OPTIONS_TO_READABLE_OPTION: Partial<Record<WABATemplateHeaderType | "", string>> = {
		text: "Texto",
		file: "Arquivo",
		picture: "Imagem",
		video: "Vídeo",
		"": "Nenhum"
	}

	const BUTTON_TYPE_TO_READABLE_OPTION: Record<ButtonTypes, string> = {
		"copy-code": "Copiar código",
		"quick-reply": "Resposta rápida",
		phone: "Número de telefone",
		url: "URL",
		unknown: "Desconhecido"
	}

	const handleHeaderTextContentChange = (value: string) => {
		setManageTemplateData(lastState => ({
			...lastState,
			header: {
				type: "text",
				content: {
					text: value as string
				}
			}
		}))
	}

	const handleHeaderTypeChange = (value: ManageTemplateHeader["type"]) => {
		setManageTemplateData(lastState => ({
			...lastState,
			header: {
				type: value
			}
		}))
	}

	const handleFormDataChance = <K extends keyof ManageTemplate>(
		key: K,
		value: ManageTemplate[K]
	) => {
		clearValidation(key)

		setManageTemplateData(lastState => ({
			...lastState,
			[key]: value
		}))
	}

	const handleAddButtons = (type: ButtonTypes): void => {
		/**
		 * Meta limits the addition of buttons to a maximum of 10.
		 */
		const reachedMaximumButtonQuantity = manageTemplateData?.buttons && manageTemplateData?.buttons?.length >= MAX_BUTTON_LIMIT

		if (reachedMaximumButtonQuantity) {
			return
		}

		setManageTemplateData((lastState) => {
			const buttons = lastState.buttons || []
			const newButton: ButtonMessageData = {
				type,
				text: "",
				id: generateUUID()
			}

			return {
				...lastState,
				buttons: [...buttons, newButton]
			}
		})
	}
	const handleRemoveButtons = (index: number): void => {
		setManageTemplateData((lastState) => ({
			...lastState,
			buttons: lastState?.buttons?.filter((_, i) => i !== index)
		}))
	}

	const getAllAvaiableVariables = async (): Promise<void> => {
		const response = await ApiService.get(`/channels/${globalStateStore.currentChannel?.integrationId}/all/variables`)
		setVariables(response.data)
	}

	const loadTemplateOnEditMode = async (): Promise<void> => {
		const templateId = location?.state?.id

		if (isEditMode) {
			try {
				const { data } = await ApiService.get(`/waba/channel/${globalStateStore.currentChannel?.id}/message/single-template/${templateId}`)

				setManageTemplateData({
					...data
				})

				editorRef.current?.addText(data.content)
				footerInputRef.current?.addText(data.footer)
			} catch (error) {
				ErrorHandlerService.handle(error as ErrorType)
			}
		}
	}

	const handleButtonTextChange = (value: string, index: number) => {
		setManageTemplateData((lastState) => {
			const buttons = lastState.buttons

			if (!buttons) {
				return lastState
			}

			const updatedButtons = [...buttons]

			updatedButtons[index].text = value

			return { ...lastState, buttons: updatedButtons }
		})
	}

	const handleAddPhoneNumber = (value: string, index: number) => {
		setManageTemplateData((lastState) => {
			const buttons = lastState.buttons

			if (!buttons) {
				return lastState
			}

			const updatedButtons = [...buttons]

			updatedButtons[index].phone_number = value

			return { ...lastState, buttons: updatedButtons }
		})
	}

	const handleRedirectToTemplateList = () => {
		history.push("/admin/templates")
	}
	const syncTemplate = async (): Promise<void> => {
		try {
			await WABAChannelMessageTemplateService.syncOneTemplate(manageTemplateData?.id, inboxChannelId)
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
	}

	const handleManageTemplate = async (): Promise<void> => {
		setLoading(true)
		try {
			if (isEditMode) {
				const templateId = location?.state?.id
				await WABAChannelMessageTemplateService.editMessageTemplate({ ...manageTemplateData, id: templateId }, globalStateStore.currentChannel?.id)
				await syncTemplate()
			} else {
				await WABAChannelMessageTemplateService.createMessageTemplate(manageTemplateData, globalStateStore.currentChannel?.id)
			}
			setLoading(false)
			handleRedirectToTemplateList()
			await WABAChannelMessageTemplateService.syncAllTemplates(inboxChannelId)
		} catch (error) {
			triggerValidation(error as ErrorType)
		}
	}

	const handleAddMediaMessage = async (medias: Media[]) => {
		const [media] = medias

		const content = MediaService.turnBlobIntoFile(media.data, media.data.name)
		const type = manageTemplateData.header?.type

		const mediaContent = {
			media: {
				key: content?.name,
				name: content?.name,
				url: content && URL.createObjectURL(content)
			}
		}

		const mediaData = await MediaService.turnFileIntoBase64(media.data)
		const mimeType = media.data.type

		setManageTemplateData(lastState => ({
			...lastState,
			header: {
				type: type as WABATemplateHeaderType,
				content: mediaContent
			},
			mediaData: {
				base64: mediaData,
				mimeType: mimeType
			}
		}))
	}

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget as HTMLElement)
	}

	const handleClose = (type: ButtonTypes | null) => {
		if (type) {
			handleAddButtons(type)
		}
		setAnchorEl(null)
	}

	useDidMount(() => {
		getAllAvaiableVariables()
		loadTemplateOnEditMode()
	})

	return (
		<>
			<Grid item xs={12}>
				<Grid item xs={12}>
					<Divider orientation="horizontal" size={4} />
					<Breadcrumb data={[
						{ name: "Templates", pathname: "/admin/templates" },
						{ name: isEditMode ? "Editar" : "Criar", pathname: "/admin/template/manage" }
					]} />
				</Grid>
				{isEditMode ? <Grid item>
					<Alert severity="warning" icon={false}>
						O processo de edição pode levar algum tempo, pois o template precisa passar por uma análise na GupShup antes de ser atualizado.
					</Alert>
					<Divider orientation="horizontal" size={2} />
				</Grid> : <Divider orientation="horizontal" size={4} />}
				<Grid container xs={12} spacing={2} direction="row">
					<Grid item xs={7}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Grid container>
									<Portlet>
										<Grid item xs={12}>
											<Grid container spacing={2}>
												<Grid item xs={12}>
													<Grid container direction="column">
														<Grid item>
															<InputLabel className={`${customClasses.inputLabel} ${classes.disabledOptionOnEditMode}`}>
																Nome do Template
															</InputLabel>
														</Grid>
														<Grid item style={{ lineHeight: "14px" }}>
															<Typography variant="caption">O nome do template só pode conter letras minúsculas, números e sublinhados.</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={12}>
													<TextField
														disabled={isEditMode}
														name="name"
														value={manageTemplateData.name}
														onChange={({ target }) => {
															const formattedValue = target.value.replace(/\s+/g, "_").replace(/[^a-zA-Z0-9_]/g, "").toLocaleLowerCase()
															handleFormDataChance("name", formattedValue)
														}}
														variant="outlined"
														placeholder="Nome do Template"
														fullWidth
														error={!!validation.name}
														helperText={validation.name}
													/>
												</Grid>
											</Grid>
										</Grid>
									</Portlet>
								</Grid>
							</Grid>

							<Grid item xs={12}>
								<Grid container>
									<Portlet>
										<Grid item xs={12}>
											<Grid container spacing={2}>
												<Grid item xs={12}>
													<Grid container>
														<Grid item>
															<InputLabel className={`${customClasses.inputLabel} ${classes.disabledOptionOnEditMode}`}>
																Categoria
															</InputLabel>
														</Grid>
														<Grid item style={{ lineHeight: "14px" }}>
															<Typography variant="caption">Selecione o tipo de mensagem do seu template. A Meta pode ajustar a categoria automaticamente.</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={12}>
													<RadioGroup
														value={manageTemplateData.category}
														onChange={({ target }) =>
															handleFormDataChance("category", target.value as AvailableWABATemplateCategory)
														}
														aria-label="Categoria"
														style={{
															display: "flex",
															flexDirection: "row",
															justifyContent: "space-between"
														}}
													>
														{CATEGORY_OPTION.map((option) => (
															<Paper
																key={option.label}
																className={classes.categoryOption}
																component="label"
																style={{
																	cursor: isEditMode ? "not-allowed" : "pointer"
																}}
															>
																<Grid container direction="row">
																	<Grid container direction="row" alignItems="center">
																		<Grid item>
																			<Radio
																				className={classes.radioIcon}
																				disabled={isEditMode}
																				checked={manageTemplateData.category === option.category}
																				value={option.category}
																				inputProps={{ name: "category" }}
																			/>
																		</Grid>
																		<Grid item>
																			<Typography
																				className={`${classes.disabledOptionOnEditMode} ${classes.radioOptionText}`}
																			>
																				{option.label}
																			</Typography>
																		</Grid>
																	</Grid>

																	<Box sx={{ ml: 2 }}>
																		<Typography
																			variant="body2"
																			className={`${classes.disabledOptionOnEditMode} ${classes.descriptionText}`}
																		>
																			{option.description}
																		</Typography>
																	</Box>

																	<input
																		type="radio"
																		style={{ display: "none" }}
																		disabled={isEditMode}
																		value={option.category}
																		checked={manageTemplateData.category === option.category}
																		onChange={({ target }) =>
																			handleFormDataChance("category", target.value as AvailableWABATemplateCategory)
																		}
																	/>
																</Grid>
															</Paper>
														))}
													</RadioGroup>
													{!!validation.category && <FormHelperText error={!!validation.category}>{validation.category}</FormHelperText>}
												</Grid>
											</Grid>
										</Grid>
									</Portlet>
								</Grid>
							</Grid>

							<Grid item xs={12}>
								<Grid container>
									<Portlet>
										<Grid item xs={12}>
											<Grid container spacing={2}>
												<Grid item xs={12}>
													<Grid container direction="column">
														<Grid item>
															<InputLabel className={`${customClasses.inputLabel} ${classes.disabledOptionOnEditMode}`}>
																Idioma
															</InputLabel>
														</Grid>
														<Grid item style={{ lineHeight: "14px" }}>
															<Typography variant="caption">Selecione o idioma que seu template será enviado.</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={12}>
													<Select
														style={{ width: "100%" }}
														value={manageTemplateData.languageCode}
														displayEmpty
														disabled={isEditMode}
														variant="outlined"
														onChange={({ target }) => handleFormDataChance("languageCode", target.value as LanguageCode)}
														label="Idioma"
														error={!!validation.languageCode}
													>
														{Object.keys(LANGUAGE_CODE_TO_READABLE_LANGUAGE).map((key) => (
															<MenuItem key={key} value={key}>
																{LANGUAGE_CODE_TO_READABLE_LANGUAGE[key as ManageTemplate["languageCode"]]}
															</MenuItem>
														))}
													</Select>
												</Grid>
											</Grid>
										</Grid>
									</Portlet>
								</Grid>
							</Grid>

							<Grid item xs={12}>
								<Grid container>
									<Portlet>
										<Grid item xs={12}>
											<Grid container spacing={2}>
												<Grid item xs={12}>
													<InputLabel className={customClasses.inputLabel}>
														Cabeçalho {<Chip label={"Opcional"} className={classes.statusChip} />}
													</InputLabel>
												</Grid>
												<Grid item xs={12}>
													<Select
														style={{ width: "100%" }}
														displayEmpty
														variant="outlined"
														value={manageTemplateData?.header?.type && TEMPLATE_HEADER_OPTIONS_TO_READABLE_OPTION[manageTemplateData?.header?.type]}
														renderValue={() => manageTemplateData?.header?.type ? TEMPLATE_HEADER_OPTIONS_TO_READABLE_OPTION[manageTemplateData?.header?.type] : "Nenhum"}
														onChange={({ target }) => handleHeaderTypeChange(target.value as WABATemplateHeaderType)}
													>
														{Object.keys(TEMPLATE_HEADER_OPTIONS_TO_READABLE_OPTION).map((key) => (
															<MenuItem key={key} value={key}>
																{TEMPLATE_HEADER_OPTIONS_TO_READABLE_OPTION[key as WABATemplateHeaderType]}
															</MenuItem>
														))}
													</Select>
												</Grid>
											</Grid>
										</Grid>
									</Portlet>
								</Grid>
							</Grid>
							{manageTemplateData?.header?.type && <Grid item xs={12}>
								<Grid container>
									<Portlet>
										<Grid item xs={12} className={classes.container} >
											<Grid container direction="column">
												<Grid item>
													<InputLabel className={customClasses.inputLabel}>
														Conteúdo do cabeçalho
													</InputLabel>
												</Grid>
												<Grid item xs={12}>
													{manageTemplateData?.header?.type === "text" && <>
														<TextInput
															isOutlined
															onMedia={() => { return "" }}
															onSubmit={() => { return "" }}
															onChange={(target) => handleHeaderTextContentChange(target)}
															minRows={1}
															ref={headerInputRef}
															defaultValue={textHeaderContent?.text}
															inputProps={{ maxLength: 60, disableUnderline: true }}
														/>

														<Grid container direction="row" justifyContent="space-between" alignItems="center">
															<Grid item>
																<Grid container direction="row">
																	<Tooltip
																		title="Não é possivel adicionar mais de uma variavel a um cabeçalho"
																		disableHoverListener={!isUsingMaxVariablesOnHeader}
																	>
																		<Grid item>
																			<VariableInput
																				disabled={isUsingMaxVariablesOnHeader || manageTemplateData?.header?.type !== "text"}
																				variables={variables}
																				onVariable={(variable) => headerInputRef.current?.addTextByCursor?.(`{{ ${variable} }}`)}
																			/>
																		</Grid>
																	</Tooltip>
																</Grid>
															</Grid>
															<Grid item>
																<Typography variant="body2" color="textSecondary" align="right">
																	{`${textHeaderContent?.text ? textHeaderContent.text.length : 0} / ${60}`}
																</Typography>
															</Grid>
														</Grid>
													</>}

													{manageTemplateData?.header?.type !== "text" && <>
														<TemplateFileInput content={manageTemplateData.header.content} mediaType={manageTemplateData.header.type} onMedia={(file) => handleAddMediaMessage(file)} />
													</>}
												</Grid>
											</Grid>
										</Grid>
									</Portlet>
								</Grid>
							</Grid>}

							<Grid item xs={12} className={classes.container}>
								<Grid container>
									<Portlet>
										<Grid item xs={12}>
											<Grid container spacing={2} xs={12} direction="column" style={{ maxWidth: "none" }}>
												<Grid item xs={12}>
													<InputLabel className={customClasses.inputLabel}>
														Conteúdo
													</InputLabel>
												</Grid>
												<Grid item xs={12}>
													<TextEditor ref={editorRef} maxLength={1024} onChanged={(value) => handleFormDataChance("content", value)} />
												</Grid>
												<Grid item>
													<Grid container direction="row" justifyContent="space-between" alignItems="center">
														<Grid item>
															<Grid container direction="row">
																<Grid item>
																	<EmojiPicker
																		onEmoji={(emoji) => editorRef.current?.addEntityByCursor("TEXT", emoji)}
																	/>
																</Grid>
																<Grid item>
																	<VariableInput
																		variables={variables}
																		onVariable={(variable) => editorRef.current?.addEntityByCursor("VARIABLE", variable)}
																	/>
																</Grid>
															</Grid>
														</Grid>
														<Grid item>
															<Typography variant="body2" color="textSecondary" align="right">
																{`${manageTemplateData.content ? manageTemplateData?.content.length : 0} / ${1024}`}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Portlet>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Grid container>
									<Portlet>
										<Grid item xs={12}>
											<Grid container spacing={2} direction="column">
												<Grid item>
													<InputLabel className={customClasses.inputLabel}>
														Rodapé {<Chip label={"Opcional"} className={classes.statusChip} />}
													</InputLabel>
												</Grid>
												<Grid item>
													<TextInput
														isOutlined
														onMedia={() => { return "" }}
														onSubmit={() => { return "" }}
														inputProps={{ maxLength: 60 }}
														ref={footerInputRef}
														defaultValue={manageTemplateData?.footer}
														onChange={(value) => handleFormDataChance("footer", value)}
													/>
												</Grid>
												<Grid item>
													<Typography variant="body2" color="textSecondary" align="right">
														{`${manageTemplateData.footer ? manageTemplateData?.footer.length : 0} / ${60}`}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Portlet>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Grid container>
									<Portlet>
										<Grid item xs={12}>
											<Grid container spacing={2}>
												<Grid item>
													<Grid container>
														<Grid item>
															<InputLabel className={customClasses.inputLabel}>
																Botões {<Chip label={"Opcional"} className={classes.statusChip} />}
															</InputLabel>
														</Grid>
														<Grid item style={{ lineHeight: "14px" }}>
															<Typography variant="caption">Escolha os botões a serem adicionados ao template. Você pode escolher até 10 botões</Typography>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={12}>
													<Grid container xs={12} justifyContent="flex-start" direction="column" spacing={2}>
														<Grid item>
															<Grid container spacing={1}>
																<Grid item>
																	<Button variant="outlined" color="primary" onClick={() => handleAddButtons("quick-reply")} style={{ height: 40, fontSize: 14 }} startIcon={<Add />}>
																		Resposta Rápida
																	</Button>
																</Grid>
																<Grid item>
																	<Button
																		variant="outlined"
																		color="primary"
																		onClick={handleClick}
																		style={{ height: 40, fontSize: 14 }}
																		startIcon={<Add />}
																	>
																		CTA
																	</Button>
																	<Menu
																		anchorEl={anchorEl}
																		open={Boolean(anchorEl)}
																		onClose={() => handleClose(null)}
																	>
																		<MenuItem onClick={() => handleClose("phone")}>Número de Telefone</MenuItem>
																	</Menu>
																</Grid>
															</Grid>
														</Grid>

														{manageTemplateData?.buttons && manageTemplateData?.buttons?.length > 0 && (
															<Grid item xs={12}>
																{manageTemplateData?.buttons?.map((button, index) => (
																	<Grid container direction="column" spacing={1} key={index}>
																		<Grid item>
																			<InputLabel className={customClasses.inputLabel}>
																				{BUTTON_TYPE_TO_READABLE_OPTION[button.type]}
																			</InputLabel>
																		</Grid>
																		{button.type === "quick-reply" ? <Grid item>
																			<Grid container alignItems="center" direction="row" spacing={2} xs={12}>
																				<Grid item xs={11}>
																					<TextInput
																						defaultValue={button?.text}
																						isOutlined
																						onMedia={() => ""}
																						onSubmit={() => ""}
																						onChange={(value) => handleButtonTextChange(value || button.text, index)}
																					/>
																				</Grid>
																				<Grid item xs={1}>
																					<InputAdornment position="end">
																						<IconButton size="small" onClick={() => handleRemoveButtons(index)}>
																							<CloseIcon fontSize="small" />
																						</IconButton>
																					</InputAdornment>
																				</Grid>
																			</Grid>
																		</Grid> : <Grid item>
																			<Grid container alignItems="center" direction="row" spacing={2} xs={12}>
																				<Grid item xs={6}>
																					<TextInput
																						placeholder="Nome do botão"
																						defaultValue={button?.text}
																						isOutlined
																						onMedia={() => ""}
																						onSubmit={() => ""}
																						onChange={(value) => handleButtonTextChange(value || button.text, index)}
																					/>
																				</Grid>
																				<Grid item xs={5}>
																					<TextInput
																						placeholder="Número de telefone"
																						defaultValue={button?.phone_number}
																						isOutlined
																						onMedia={() => ""}
																						onSubmit={() => ""}
																						onChange={(value) => handleAddPhoneNumber(value || button?.phone_number, index)}
																					/>
																				</Grid>
																				<Grid item xs={1}>
																					<InputAdornment position="end">
																						<IconButton size="small" onClick={() => handleRemoveButtons(index)}>
																							<CloseIcon fontSize="small" />
																						</IconButton>
																					</InputAdornment>
																				</Grid>
																			</Grid>
																		</Grid>}
																	</Grid>
																))}
															</Grid>
														)}
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Portlet>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<FormControlLabel
									control={
										<Checkbox
											checked={manageTemplateData.allowTemplateCategoryChange || false}
											onChange={({ target }) => handleFormDataChance("allowTemplateCategoryChange", target.checked)}
										/>
									}
									label="Permitir que a Meta altere a categoria do template durante a aprovação"
								/>
							</Grid>
							<Grid item xs={12}>
								<Grid container justifyContent="flex-end">
									<Grid item>
										<Button variant="text" color="primary" onClick={handleRedirectToTemplateList} style={{ height: 40, fontSize: 14 }}>
											Cancelar
										</Button>
									</Grid>
									<Divider orientation="vertical" size={1} />
									<Grid item>
										<Button type="submit" variant="contained" color="primary" disabled={loading} onClick={handleManageTemplate} style={{ height: 40, fontSize: 14 }}>
											{isEditMode ? "Editar" : "Criar"} e enviar
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={4}>
						<Portlet style={{
							padding: 10,
							flexDirection: "column",
							justifyContent: "start",
							borderRadius: 8,
							position: "sticky",
							top: 18
						}}>
							<Grid container className={classes.messagePreview}>
								<Grid item>
									<Typography className={customClasses.inputLabel}>
										Mensagem
									</Typography>
								</Grid>
								<Grid item>
									<CardContent
										className={classes.previewMessageContent}
										style={{
											backgroundImage: `url(${WhatsappBackground})`
										}}>
										<MessageItem
											type={"text"}
											content={manageTemplateData.content}
											extraData={
												{
													...(manageTemplateData?.header?.type && {
														header: manageTemplateData.header
													}),
													...(manageTemplateData?.footer && {
														footer: { text: manageTemplateData?.footer }
													}),
													...(manageTemplateData?.buttons && {
														button: {
															buttons: manageTemplateData.buttons
														}
													})
												}}
											isDefaultSize={false}
											copySpecial={false}
										/>
									</CardContent>
								</Grid>
							</Grid>
						</Portlet>
					</Grid>
				</Grid>
			</Grid>
		</>
	)
}

export default ManageTemplateForm
