import DataTableWithPagination from "@/components/DataTableWithPagination"
import { DEFAULT_WHERE_DATA, DefaultWhereDataType } from "@/utils/pagination"
import { Grid, IconButton, InputAdornment, TextField } from "@material-ui/core"
import React, { useEffect, useState } from "react"

import {
	Search as SearchIcon,
	Close as CloseIcon
} from "@material-ui/icons"

import {
	ProductReportClient,
	LoadingOverlay,
	ProductReportActionButtonGroup
} from "@/components"
import ApiService from "@/services/Api"
import { formatDateInBrazilianDate, formatDateInHours } from "@/utils/time"
import useDebounce from "@/hooks/useDebounce"
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { ptBR } from "date-fns/locale"
import DateFnsUtils from "@date-io/date-fns"
import {
	ReactComponent as ChatBotIcon
} from "@/assets/icons/chat_bot.svg"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/EntrantFlowReport/styles"
import Autocomplete from "@material-ui/lab/Autocomplete"
import ErrorHandlerService from "@/services/ErrorHandler"
import { TableColumn } from "@/components/DataTable"

type ChatBotEntryReasons =
	"added-by-attendant" |
	"associated-to-tag" |
	"added-by-chatbot" |
	"contact-sent-message" |
	"webhook-triggered"

type ChatBotEntryReasonLabels =
	"Adicionado manualmente" |
	"Adicionado pelo bot" |
	"Associado a uma tag" |
	"Contato enviou mensagem" |
	"Gatilho de webhook acionado"

const ChatBotEntryReasonToDescription: Record<ChatBotEntryReasons, ChatBotEntryReasonLabels> = {
	"added-by-attendant": "Adicionado manualmente",
	"added-by-chatbot": "Adicionado pelo bot",
	"associated-to-tag": "Associado a uma tag",
	"contact-sent-message": "Contato enviou mensagem",
	"webhook-triggered": "Gatilho de webhook acionado"
}

type Contact = {
	id: string
	name: string
	phone: string
	email: string
}

type Entrants = {
	id: number
	instanceId: number
	clientId: number
	chatBotFlowId: number
	botEntryReason: ChatBotEntryReasons
	addedBy: string
	createdAt: Date
	updatedAt: Date
	deletedAt: Date
	contact: Contact
}

type EntrantContactDataRows = {
	nickname?: string
	name?: string
	phoneNumber?: string
}

type EntrantsDataRows = {
	createdAt?: string
	contact?: EntrantContactDataRows
	email?: string
	botEntryReason?: ChatBotEntryReasonLabels
}

type EntrantsData = {
	count: number,
	rows: EntrantsDataRows[]
}

const renderContact = (value: EntrantContactDataRows): React.ReactNode => {
	return (
		<ProductReportClient client={value} />
	)
}

const entrantsTableColumns: TableColumn<EntrantsDataRows>[] = [
	{ field: "createdAt", label: "Data / Hora" },
	{ field: "contact", label: "Contato", rowRender: renderContact },
	{ field: "email", label: "Email" },
	{ field: "botEntryReason", label: "Ação que adicionou no Bot" }
]

type EntrantFlowReportProps = {
	instanceId: number
	chatBotFlowId: number
	opened: boolean
}

type Filters = {
	search: string | null
	fromDate: Date | null,
	toDate: Date | null,
	botEntryReason?: ChatBotEntryReasons | null
}

const EntrantFlowReport: React.FC<EntrantFlowReportProps> = (props) => {
	const {
		instanceId,
		chatBotFlowId,
		opened
	} = props

	const classes = useStyles()
	const [entrantsData, setEntrantsData] = useState<EntrantsData>({} as EntrantsData)
	const [paginationState, setPaginationState] = useState<DefaultWhereDataType>(DEFAULT_WHERE_DATA)
	const [filters, setFilters] = useState<Filters>({
		search: null,
		fromDate: null,
		toDate: null,
		botEntryReason: null
	})
	const [loading, setLoading] = useState<boolean>(false)

	const getDateFormattedForEntrantsColumn = (date: Date) => {
		return `${formatDateInBrazilianDate(date)} | ${formatDateInHours(date)}`
	}

	const formatEntrantsData = (data: Entrants[]): EntrantsDataRows[] => {
		const formattedData = data.map((entrant) => {
			const botEntryReasonDescription = ChatBotEntryReasonToDescription[entrant.botEntryReason]
			return {
				createdAt: getDateFormattedForEntrantsColumn(new Date(entrant.createdAt)),
				contact: {
					nickname: entrant.contact.name,
					phoneNumber: entrant.contact.phone
				},
				email: entrant.contact.email,
				botEntryReason: botEntryReasonDescription
			}
		})

		return formattedData
	}

	const handleFetchChatBotEntrants = async () => {
		setLoading(true)
		try {
			const response = await ApiService.get(`/chat-bot/contacts-in-bot-history/${instanceId}/${chatBotFlowId}`, {
				params: {
					...paginationState,
					...filters
				}
			})

			const formattedData = formatEntrantsData(response.data.rows)

			setEntrantsData({
				count: response.data.count,
				rows: formattedData
			})
		} catch (error) {
			ErrorHandlerService.handle(error as Error)
		}

		setLoading(false)
	}

	const handleChangeEditFilters = (data: Partial<Filters>) => {
		setFilters({
			...filters,
			...data
		})
	}

	const handlePaginationState = (data: Partial<DefaultWhereDataType>) => {
		setPaginationState(lastState => ({
			...lastState,
			...data
		}))
	}

	const handleRefreshData = () => {
		setFilters({
			search: null,
			fromDate: null,
			toDate: null,
			botEntryReason: null
		})
	}

	useDebounce(
		async () => handleFetchChatBotEntrants(),
		filters,
		750
	)

	useEffect(() => {
		handleFetchChatBotEntrants()
	}, [opened, paginationState])

	return (
		<Grid container direction="column" spacing={2}>

			<Grid item>
				<Grid container justifyContent="flex-end">
					<ProductReportActionButtonGroup
						refresh={{
							tooltip: "Atualizar",
							onClick: (handleRefreshData)
						}}
					/>
				</Grid>
			</Grid>

			<Grid item>
				<Grid container xs={12} justifyContent="space-between">

					<Grid item>
						<TextField
							value={filters.search}
							placeholder="Nome, email ou telefone"
							variant="outlined"
							onChange={({ target }) => handleChangeEditFilters({ search: target.value })}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											size="small"
											onClick={() => handleChangeEditFilters({ search: "" })}
										>
											<CloseIcon fontSize="small" />
										</IconButton>
									</InputAdornment>
								)
							}}
							fullWidth
							size="small"
							style={{
								minWidth: "300px"
							}}
						/>
					</Grid>

					<Grid item>

						<Grid container direction="row" spacing={2}>

							<Grid item>
								<Autocomplete
									value={filters.botEntryReason
										? [filters.botEntryReason, ChatBotEntryReasonToDescription[filters.botEntryReason]]
										: null
									}
									options={Object.entries(ChatBotEntryReasonToDescription)}
									getOptionLabel={([, value]) => value}
									onChange={(_, newValue) => {
										handleChangeEditFilters({ botEntryReason: newValue ? newValue[0] as ChatBotEntryReasons : null })
									}}
									fullWidth
									style={{
										padding: "0px"
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											variant="outlined"
											placeholder="Ação que adicionou no bot"
											InputProps={{
												...params.InputProps,
												startAdornment: (
													<InputAdornment position="start" style={{ display: "flex", alignItems: "center" }}>
														<ChatBotIcon fontSize="small" style={{ height: "26px", width: "26px" }} />
													</InputAdornment>
												),
												style: {
													height: "40px",
													display: "flex",
													alignItems: "center",
													paddingTop: "1px",
													paddingRight: 0
												}
											}}
											style={{
												minWidth: "320px"
											}}
										/>
									)}
								/>
							</Grid>

							<Grid item>
								<MuiPickersUtilsProvider
									locale={ptBR}
									utils={DateFnsUtils}
								>
									<Grid
										className={classes.dateContainer}
									>
										<KeyboardDateTimePicker
											okLabel="Pronto"
											cancelLabel="Cancelar"
											ampm={false}
											value={filters.fromDate}
											inputVariant="outlined"
											onChange={(date) => handleChangeEditFilters({ fromDate: date })}
											placeholder="De"
											size="small"
											format="dd/MM/yyyy HH:mm"
											InputAdornmentProps={{
												position: "start"
											}}
											style={{ padding: 0, width: "180px" }}
										/>
									</Grid>
								</MuiPickersUtilsProvider>
							</Grid>

							<Grid item>
								<MuiPickersUtilsProvider
									locale={ptBR}
									utils={DateFnsUtils}
								>
									<Grid
										className={classes.dateContainer}
									>
										<KeyboardDateTimePicker
											okLabel="Pronto"
											cancelLabel="Cancelar"
											ampm={false}
											value={filters.toDate}
											inputVariant="outlined"
											onChange={(date) => handleChangeEditFilters({ toDate: date })}
											placeholder="Até"
											size="small"
											format="dd/MM/yyyy HH:mm"
											InputAdornmentProps={{
												position: "start"
											}}
											style={{ padding: 0, width: "180px" }}
										/>
									</Grid>
								</MuiPickersUtilsProvider>
							</Grid>

						</Grid>

					</Grid>

				</Grid>

				<Grid item xs={12} style={{ marginTop: "32px" }}>
					<LoadingOverlay
						loading={loading}
					>
						<DataTableWithPagination
							columns={entrantsTableColumns}
							data={entrantsData.rows}
							page={paginationState.page}
							rowsPerPage={paginationState.rowsPerPage}
							totalRows={entrantsData.count}
							onPaginationChange={handlePaginationState}
						>
						</DataTableWithPagination>
					</LoadingOverlay>
				</Grid>
			</Grid>

		</Grid>
	)
}

export default EntrantFlowReport
