import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"

const useStyles = makeStyles({
	alertText: {
		fontSize: 12,
		lineHeight: "14px"
	},
	sessionTitle: {
		color: colors.palette.primary,
		fontWeight: 700
	},
	deviceIcon: {
		color: colors.palette.primary
	}
})

export default useStyles
