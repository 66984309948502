import React from "react"

import { Variable } from "@/components/ChatMessageBuilder/VariableInput"

export const formatChatMessageBuilderVariables = (rawVariables: Variable[]): Variable[] =>
	rawVariables.map(({ title, value }) => ({
		title: title
			? <p>
				<small>{title}</small>
			</p>
			: null,
		value
	}))
