import React, { useState } from "react"

import {
	ReportProblemOutlined as WarningIcon
} from "@material-ui/icons"
import {
	Link,
	Typography
} from "@material-ui/core"

import {
	ActionDialog,
	Notification
} from "@/components"

import { renderComponent } from "@/utils/node"
import { letalkLinks } from "@/utils/link"

import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"

import { ErrorType } from "@/hooks/useValidation"

import useStyles from "@/pages/Admin/Security/DevicesAndSessions/DeviceItem/FinishSessionDialog/styles"

type FinishSessionDialogProps = {
	sessionId: number
	onReload?: () => void
}

type FinishSessionDialogType = {
	open: (props: FinishSessionDialogProps) => void
}

const FinishSessionDialog: FinishSessionDialogType & React.FC<FinishSessionDialogProps> = (props) => {
	const {
		sessionId,
		onReload
	} = props

	const [opened, setOpened] = useState(true)

	const classes = useStyles()

	const handleClose = () => {
		setOpened(false)
	}

	const handleFinishDeviceSession = async () => {
		try {
			await ApiService.delete("/session/remove", {
				data: {
					deviceSessionId: sessionId
				}
			})

			Notification.success({
				message: "Sessão do dispositivo finalizada com sucesso!"
			})
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)

			Notification.error({ message: "Algo deu errado e não conseguimos identificar. Entre em contato." })
		}
	}

	const handleSave = async () => {
		await handleFinishDeviceSession()

		handleClose()

		onReload?.()
	}

	return (
		<ActionDialog
			fullWidth
			openDialog={opened}
			onClose={handleClose}
			title={"ATENÇÃO"}
			dialogTitleIcon={<WarningIcon className={classes.titleIcon}/>}
			cancelText="CANCELAR"
			onSave={handleSave}
			saveText="CONFIRMAR"
			dialogTitleClassName={classes.title}
		>
			<Typography>
				Essa ação removerá o acesso do usuário à plataforma. Caso não reconheça esse acesso é
				recomendado realizar o processo de
				{" "}
				<Link
					href={letalkLinks.inboxRecoveryPassword}
					target="_blank"
				>
					redefinição de senha
				</Link>
				{" "}
				para garantir que não haja nenhum acesso não autorizado em sua conta novamente.
			</Typography>
		</ActionDialog>
	)
}

FinishSessionDialog.open = (props: FinishSessionDialogProps) => {
	renderComponent(
		"finish-session",
		<FinishSessionDialog
			{...props}
		/>
	)
}

export default FinishSessionDialog
