import React from "react"
import { Portlet } from "@/components"
import { Grid, IconButton, Typography } from "@material-ui/core"
import { Link } from "react-router-dom"
import {
	ArrowForwardIos as GoToConstructionIcon
} from "@material-ui/icons"
import useStyles from "@/pages/Admin/Reports/components/ReportCard/styles"
import { ReportsType } from "@/protocols/reports"

type ReportCardProps = {
	icon: React.ReactNode
	title: string
	description: string
	type: ReportsType
}

const getConstructorPath = (type: ReportsType) => {
	return `/admin/report/${type}`
}

const ReportCard: React.FC<ReportCardProps> = (props) => {
	const { icon, title, description, type } = props

	const classes = useStyles()

	const StyledIcon = React.cloneElement(icon as React.ReactElement, {
		className: classes.icon
	})

	return (
		<Portlet elevation={1}>
			<Grid
				container
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				style={{
					paddingLeft: "6px",
					paddingRight: "6px"
				}}
			>
				<Grid
					item
				>
					<Grid
						container
						spacing={6}
						alignItems="center"
					>
						<Grid item>
							{StyledIcon}
						</Grid>
						<Grid item>
							<Link
								to={getConstructorPath(type)}
								style={{ textDecoration: "none" }}
							>
								<Typography
									variant="h4"
								>
									{title}
								</Typography>
							</Link>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Typography
						style={{
							fontSize: "14px",
							fontWeight: 400,
							lineHeight: "20px"
						}}
					>
						{description}
					</Typography>
				</Grid>
				<Grid
					item
				>
					<Link
						to={getConstructorPath(type)}
						style={{ textDecoration: "none" }}
					>
						<IconButton
							className={classes.listItemButton}
						>
							<GoToConstructionIcon
								className={classes.editIcon}
							/>
						</IconButton>
					</Link>
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default ReportCard
