import React from "react"
import _ from "lodash"
import { SaveAlt as DefaultIcon } from "@material-ui/icons"

import { BlockThemeConfig } from "@/protocols/chatBotFlow"
import { ChatBotFlowBlockType } from "@/protocols/chatBot"
import { FlowBlockCategory } from "@/protocols/chatBotConstructor"

import { blockCategoryThemeConfig } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/blockCategoryTheme"
import { blockThemeConfig } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/blockTheme"
import colors from "@/styles/colors"
import newColors from "@/styles/newColors"

export const getConsolidatedBlockTheme = (blockType: ChatBotFlowBlockType, blockCategoryType: FlowBlockCategory["type"]): BlockThemeConfig => {
	const expectedConfig: BlockThemeConfig = {
		color: {
			blockIcon: newColors.yellow[400],
			blockHeaderBackground: colors.unrelated.FEF6EB,
			blockSelected: newColors.yellow[400]
		},
		icon: {
			Component: <DefaultIcon />
		}
	}

	const blockCategoryTheme = blockCategoryThemeConfig[blockCategoryType]
	const blockTheme = blockThemeConfig[blockType]

	expectedConfig.color = _.merge(expectedConfig.color, blockCategoryTheme?.color, blockTheme?.color)
	expectedConfig.icon = blockTheme?.icon || expectedConfig.icon

	return expectedConfig
}
