import React from "react"
import useCustomStyles from "@/styles/custom"
import {
	TableHead,
	Table,
	TableContainer,
	TableRow,
	TableCell,
	TableBody,
	Grid,
	Typography
} from "@material-ui/core"
import { Divider } from "@/components"

export type AlignOptions = "right" | "left" | "center"

export type TableColumn<RowData> = {
	field: keyof RowData
	label: string
	align?: AlignOptions
	width?: number
	headRender?: (value: string) => React.ReactNode
	rowRender?: (value: RowData[keyof RowData]) => React.ReactNode
}

type DataTableProps<RowData> = {
	columns: TableColumn<RowData>[]
	data: RowData[]
}

const DataTable = <RowData, >(props: DataTableProps<RowData>) => {
	const {
		columns,
		data
	} = props

	const customClasses = useCustomStyles()

	return (
		<>
			<TableContainer>
				<Table stickyHeader size="small">
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									key={String(column.field)}
									align={column.align || "left"}
									style={{ width: `${column.width}%` || "auto" }}
								>
									{column.headRender ? column.headRender(column.label) : column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody className={customClasses.reportTableBodyText}>
						{data?.length > 0 &&
							data.map((row, rowIndex) => (
								<TableRow key={rowIndex}>
									{columns.map((column) => (
										<TableCell
											key={String(column.field)}
											align={column.align || "left"}
										>
											{column.rowRender
												? column.rowRender(row[column.field])
												: row[column.field] ?? "-"}
										</TableCell>
									))}
								</TableRow>
							))}
					</TableBody>
				</Table>

				{!data?.length && (
					<Grid item xs={12}>
						<Divider size={3} orientation="horizontal" />

						<Typography align="center" variant="h2">
							Nenhum resultado encontrado
						</Typography>
					</Grid>
				)}
			</TableContainer>
		</>
	)
}

export default DataTable
