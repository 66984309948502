import StorageService from "@/services/Storage"

export type InstanceFeatureFlagType =
	"webhookCustomFields" |
	"blockedSearch" |
	"groupMentions" |
	"flowDesignTest" |
	"onlyDeleteFirst100ContactsInBot" |
	"conditionButtonAndConditionMenuBlocks" |
	"managerTagsIntegrationText" |
	"isAbleToSeeStatusMessageResponse" |
	"isBlockedToseeGroupList" |
	"willRecordAudioInMpegFormat" |
	"createChatBotByIntegration" |
	"botBlocksAndMBVariable" |
	"lastUseDateIntegration" |
	"messageProcessingSteps" |
	"datadogTracing" |
	"datadogLogs" |
	"canIgnoreMessageBlastAdvise" |
	"canUseWABATemplateButtonBlock" |
	"canFilterOthersTabWithLastMessageTransactedIn16Hours" |
	"canFilterOnGoingAndQueueTabWithLastMessageTransactedIn16Hours" |
	"canFilterOnGoingAndQueueTabWithLastMessageTransactedIn24Hours" |
	"canFilterOnGoingAndQueueTabWithLastMessageTransactedIn72Hours" |
	"hasChatContentValidation" |
	"canUseBulkContactActionInWABAChannel" |
	"canViewAllActiveCampaignChatEventStatus" |
	"canDebugReceivedMessages" |
	"canUseFlowReport" |
	"canUseTagsReport" |
	"canViewThumbnail" |
	"canDeleteContact"

export type AdminFeatureFlagType =
	"mfa" |
	"contactFlowHistory" |
	"copyRawInviteLink" |
	"instanceResponsiblePhoneNumber" |
	Extract<InstanceFeatureFlagType, "messageProcessingSteps"> |
	"canConfigureWABAApiChannel" |
	"canIgnoreAttendantQuantityLimit" |
	"canImportBotsOnWaba"

type FeatureFlagMap = {
	instance: Record<InstanceFeatureFlagType, number[]>
	admin: Record<AdminFeatureFlagType, number[]>
}

export type FeatureFlag = InstanceFeatureFlagType | AdminFeatureFlagType

class HardCodedService {
	private readonly instanceIdMap = {
		letalk: {
			MOTA: 1,
			RAFAEL_LIMA: 1712,
			GUSTAVO_LETALK: 17,
			CS_LETALK: 3411,
			JOHN_LETALK: 3046,
			RAYAN_LETALK: 2245,
			TONIM_LETALK: 3028,
			ISRAEL_LETALK: 7426,
			JU_LETALK: 5420,
			EVENTOS_LETALK: 3098,
			ANA_CS_LETALK: 3665,
			CHECK_REGISTROS: 2225,
			ASSINATURAS: 1745,
			CS: 7074,
			SUPORTE: 5644,
			SDR: 7153,
			CLOSER_VANESSA: 7168,
			CLOSER_ROGERIO: 7161,
			GUI_VILELA: 7324,
			TAGGAR: 7166,
			PEDRO_LUCENA: 7428,
			DOMINUS_COMUNICACAO: 2518,
			FABIANA_RODESKI: 3409,
			SAMUEL_TESTER_LETALK: 7566,
			SAMUEL_TESTER_LETALK_WABA: 7166,
			WELLINGTON_LETALK: 7427,
			RAFAEL_WABA: 7532,
			LET_WABA: 7524,
			PICSIZE: 7745,
			MATHEUS_LETALK: 7425,
			GUSTAVO_LETALK_WABA: 7608
		},
		client: {
			EVENTPLAY_GROWTH: 3090,
			THAIS_DESPACHANTE_DOK: 5391,
			DANIEL_NOGUEIRA: 2487,
			DURVAL_BATISTA_MOBLY: 3724,
			PEDRO_CARDOSO_ALLUGATOR: 3790,
			JENIFER_SANTOS_RBRANDSBR: 3303,
			NATUS_REVESTIMENTOS_LANTAI: 5632,
			PETRUS_LANTAI: 5611,
			GERENTE_REVESTIMENTO_SEM_OFERTA: 5496,
			LUCAS_CARVALHO_CHILE: 2397,
			RAPHAEL_LIMA_DUARTE: 3252,
			SISTEMAS_REFORNARI: 2130,
			SUHIANA_WEDOCTORS: 3334,
			MIGUEL_BRUNO: 4358,
			INFO_ROLEINROMA: 3290,
			FINANCIAL_MOVECRIPTO: 2534,
			MURILO_S_FALEIROS: 5233,
			SISTEMAS_LITORAL_VERDE: 2827,
			NATHAN_LETSEATIT: 5219,
			DAYONE_INTERCAMBIOS: 4796,
			CRIACAO_LANTAI: 5314,
			THIAGO_ADMIN_SCITRUS: 3943,
			MARKETING_INOXDINE: 4055,
			MARCELO_ELETECHSOLAR: 4084,
			LEONARDO_FONSECA_JR: 4535,
			ADMINISTRATIVO_ORAL_UNIC_CURITIBA: 5253,
			ROBSON_COSTA_SOCIEDADE_INDIVIDUAL_DE_ADVOCACIA: 6468,
			LOGISTICA_AVENTURA_OPERADORA_DE_TURISMO_LTDA: 6841,
			CONTATO_MDM: 6648,
			LETSEATIT: 5219,
			APEREAL: 3473,
			NERES_BARBEARIA: 6417,
			THALLERS_MILANIO: 7132,
			AM_ANDRADE: 7409,
			CS_FITX: 7703,
			QUARK_TEC: 5828,
			SUPORTE_FINANCIAL_MOVE: 3703,
			TRANS_BY_SHOP: 2069,
			BRUNO_MIGUEL: 4358,
			MANIA_GOURMET_FORMACAO_PROFISSIONAL: 7771,
			IMPERIO_DO_MARMORE: 3311,
			BALAIO_DE_FLORES: 7829,
			FIELD_CONTROL_LTDA: 7961,
			PROSELF_COMERCIO_DE_PRODUTOS_CAPILARES_LTDA: 7759,
			DON_DRA_DE_SITE: 7891,
			PABLO_WIEDEMANN: 7327
		}
	}

	private readonly adminIdMap = {
		CAROL: 2,
		GUSTAVO: 4,
		TONIM: 5,
		GABRIELA_SUPORTE: 12,
		MOTA: 14,
		JHON: 20,
		LAURA_SUPORTE: 23,
		LETICIA: 24,
		PEDRO_SUPORTE: 30,
		RAYAN: 31,
		RAFAEL_LIMA: 32,
		RENATO_SUPORTE: 33,
		SAMUEL_SUPORTE: 36,
		VITORIA_SUPORTE: 42,
		ANA_SUPORTE: 43,
		MARIANA: 47,
		DANDARA: 49,
		RENATO_CALABREZI: 53,
		SAMUEL_AMPARO: 54,
		JESSICA_MARIANO: 59,
		GUI: 1,
		LHUANA: 52,
		YASMIN_MELGACO: 63
	}

	private readonly featureFlagMap: FeatureFlagMap = {
		instance: {
			blockedSearch: [],
			isBlockedToseeGroupList: [
				this.instanceIdMap.client.THAIS_DESPACHANTE_DOK
			],
			webhookCustomFields: [
				this.instanceIdMap.letalk.GUSTAVO_LETALK,
				this.instanceIdMap.letalk.MOTA,
				this.instanceIdMap.client.EVENTPLAY_GROWTH,
				this.instanceIdMap.letalk.EVENTOS_LETALK,
				this.instanceIdMap.letalk.RAFAEL_LIMA,
				this.instanceIdMap.client.DANIEL_NOGUEIRA,
				this.instanceIdMap.client.DURVAL_BATISTA_MOBLY
			],
			groupMentions: [
				this.instanceIdMap.letalk.GUSTAVO_LETALK,
				this.instanceIdMap.letalk.MOTA
			],
			flowDesignTest: [
				this.instanceIdMap.letalk.RAFAEL_LIMA
			],
			onlyDeleteFirst100ContactsInBot: [
				this.instanceIdMap.client.PEDRO_CARDOSO_ALLUGATOR
			],
			conditionButtonAndConditionMenuBlocks: [
				this.instanceIdMap.letalk.ANA_CS_LETALK
			],
			managerTagsIntegrationText: [
				this.instanceIdMap.letalk.GUSTAVO_LETALK,
				this.instanceIdMap.letalk.TONIM_LETALK,
				this.instanceIdMap.letalk.MOTA
			],
			isAbleToSeeStatusMessageResponse: [
				this.instanceIdMap.letalk.GUSTAVO_LETALK,
				this.instanceIdMap.letalk.TONIM_LETALK,
				this.instanceIdMap.letalk.MOTA
			],
			willRecordAudioInMpegFormat: [
				this.instanceIdMap.letalk.RAFAEL_LIMA,
				this.instanceIdMap.letalk.SAMUEL_TESTER_LETALK,
				this.instanceIdMap.letalk.TONIM_LETALK
			],
			createChatBotByIntegration: [
				this.instanceIdMap.letalk.TONIM_LETALK,
				this.instanceIdMap.letalk.GUSTAVO_LETALK,
				this.instanceIdMap.letalk.JU_LETALK,
				this.instanceIdMap.letalk.MOTA
			],
			botBlocksAndMBVariable: [
				this.instanceIdMap.letalk.GUSTAVO_LETALK
			],
			lastUseDateIntegration: [
				this.instanceIdMap.letalk.TONIM_LETALK,
				this.instanceIdMap.letalk.GUSTAVO_LETALK,
				this.instanceIdMap.letalk.JU_LETALK,
				this.instanceIdMap.letalk.MOTA
			],
			messageProcessingSteps: [
				this.instanceIdMap.letalk.JOHN_LETALK,
				this.instanceIdMap.letalk.GUSTAVO_LETALK,
				this.instanceIdMap.letalk.JU_LETALK,
				this.instanceIdMap.letalk.MOTA,
				this.instanceIdMap.letalk.SUPORTE,
				this.instanceIdMap.letalk.PEDRO_LUCENA,
				this.instanceIdMap.letalk.RAYAN_LETALK
			],
			datadogTracing: [
				this.instanceIdMap.client.DON_DRA_DE_SITE,
				this.instanceIdMap.client.PABLO_WIEDEMANN
			],
			datadogLogs: [
				this.instanceIdMap.client.DON_DRA_DE_SITE
			],
			canDebugReceivedMessages: [
				this.instanceIdMap.client.DON_DRA_DE_SITE
			],
			hasChatContentValidation: [
				this.instanceIdMap.letalk.SAMUEL_TESTER_LETALK
			],
			canIgnoreMessageBlastAdvise: [
				this.instanceIdMap.letalk.DOMINUS_COMUNICACAO,
				this.instanceIdMap.letalk.FABIANA_RODESKI
			],
			canFilterOthersTabWithLastMessageTransactedIn16Hours: [
				this.instanceIdMap.client.MANIA_GOURMET_FORMACAO_PROFISSIONAL
			],
			canFilterOnGoingAndQueueTabWithLastMessageTransactedIn16Hours: [
				this.instanceIdMap.client.MANIA_GOURMET_FORMACAO_PROFISSIONAL
			],
			canFilterOnGoingAndQueueTabWithLastMessageTransactedIn24Hours: [
				this.instanceIdMap.client.NERES_BARBEARIA,
				this.instanceIdMap.client.THALLERS_MILANIO,
				this.instanceIdMap.client.AM_ANDRADE,
				this.instanceIdMap.client.QUARK_TEC,
				this.instanceIdMap.client.TRANS_BY_SHOP,
				this.instanceIdMap.client.PROSELF_COMERCIO_DE_PRODUTOS_CAPILARES_LTDA
			],
			canFilterOnGoingAndQueueTabWithLastMessageTransactedIn72Hours: [
				this.instanceIdMap.client.BRUNO_MIGUEL
			],
			canUseWABATemplateButtonBlock: [
				this.instanceIdMap.letalk.TAGGAR,
				this.instanceIdMap.letalk.SAMUEL_TESTER_LETALK
			],
			canUseBulkContactActionInWABAChannel: [
				this.instanceIdMap.letalk.TAGGAR
			],
			canViewAllActiveCampaignChatEventStatus: [
				this.instanceIdMap.letalk.SAMUEL_TESTER_LETALK,
				this.instanceIdMap.letalk.SAMUEL_TESTER_LETALK_WABA,
				this.instanceIdMap.letalk.TAGGAR,
				this.instanceIdMap.letalk.RAYAN_LETALK
			],
			canUseFlowReport: [
				this.instanceIdMap.letalk.GUSTAVO_LETALK,
				this.instanceIdMap.letalk.MATHEUS_LETALK,
				this.instanceIdMap.letalk.TONIM_LETALK,
				this.instanceIdMap.letalk.GUSTAVO_LETALK_WABA
			],
			canUseTagsReport: [
				this.instanceIdMap.letalk.GUSTAVO_LETALK,
				this.instanceIdMap.letalk.MATHEUS_LETALK,
				this.instanceIdMap.letalk.TONIM_LETALK,
				this.instanceIdMap.letalk.GUSTAVO_LETALK_WABA,
				this.instanceIdMap.letalk.GUI_VILELA,
				this.instanceIdMap.letalk.JU_LETALK,
				this.instanceIdMap.letalk.MOTA,
				this.instanceIdMap.letalk.SUPORTE,
				this.instanceIdMap.letalk.CS_LETALK,
				this.instanceIdMap.letalk.LET_WABA,
				this.instanceIdMap.letalk.RAFAEL_WABA
			],
			canViewThumbnail: [
				this.instanceIdMap.letalk.TONIM_LETALK
			],
			canDeleteContact: [
				this.instanceIdMap.letalk.TONIM_LETALK,
				this.instanceIdMap.letalk.GUSTAVO_LETALK
			]
		},
		admin: {
			contactFlowHistory: [
				this.adminIdMap.TONIM,
				this.adminIdMap.JHON
			],
			messageProcessingSteps: [
				this.adminIdMap.ANA_SUPORTE,
				this.adminIdMap.JHON,
				this.adminIdMap.GUSTAVO,
				this.adminIdMap.MOTA,
				this.adminIdMap.ANA_SUPORTE,
				this.adminIdMap.LAURA_SUPORTE,
				this.adminIdMap.PEDRO_SUPORTE,
				this.adminIdMap.RENATO_SUPORTE,
				this.adminIdMap.SAMUEL_SUPORTE,
				this.adminIdMap.VITORIA_SUPORTE,
				this.adminIdMap.GABRIELA_SUPORTE
			],
			mfa: [
				this.adminIdMap.GUSTAVO,
				this.adminIdMap.JHON,
				this.adminIdMap.RAYAN,
				this.adminIdMap.SAMUEL_SUPORTE
			],
			copyRawInviteLink: [
				this.adminIdMap.JHON,
				this.adminIdMap.SAMUEL_SUPORTE
			],
			instanceResponsiblePhoneNumber: [
				this.adminIdMap.RAYAN,
				this.adminIdMap.LETICIA,
				this.adminIdMap.CAROL,
				this.adminIdMap.DANDARA,
				this.adminIdMap.MARIANA,
				this.adminIdMap.GABRIELA_SUPORTE
			],
			canConfigureWABAApiChannel: [
				this.adminIdMap.TONIM,
				this.adminIdMap.MOTA,
				this.adminIdMap.SAMUEL_SUPORTE,
				this.adminIdMap.LETICIA,
				this.adminIdMap.ANA_SUPORTE,
				this.adminIdMap.RAFAEL_LIMA,
				this.adminIdMap.RENATO_CALABREZI,
				this.adminIdMap.JESSICA_MARIANO,
				this.adminIdMap.PEDRO_SUPORTE,
				this.adminIdMap.GUSTAVO,
				this.adminIdMap.GUI,
				this.adminIdMap.LHUANA,
				this.adminIdMap.YASMIN_MELGACO
			],
			canIgnoreAttendantQuantityLimit: [
				this.adminIdMap.GUSTAVO,
				this.adminIdMap.JHON,
				this.adminIdMap.RAYAN,
				this.adminIdMap.GABRIELA_SUPORTE,
				this.adminIdMap.DANDARA,
				this.adminIdMap.LETICIA,
				this.adminIdMap.SAMUEL_AMPARO,
				this.adminIdMap.SAMUEL_SUPORTE
			],
			canImportBotsOnWaba: [
				this.adminIdMap.RENATO_SUPORTE,
				this.adminIdMap.JHON,
				this.adminIdMap.RENATO_CALABREZI
			]
		}
	}

	checkFeatureFlag (type: FeatureFlag): boolean {
		const instanceIds = this.featureFlagMap.instance[type as InstanceFeatureFlagType] || []
		const adminIds = this.featureFlagMap.admin[type as AdminFeatureFlagType] || []
		const hasInstanceFeatureFlag = instanceIds.includes(this.instanceId)
		const hasAdminFeatureFlag = adminIds.includes(this.adminId)

		const hasFeatureFlag = hasInstanceFeatureFlag || hasAdminFeatureFlag

		return hasFeatureFlag
	}

	isAdmin (): boolean {
		return Boolean(this.adminId)
	}

	private get instanceId (): number {
		const instanceId = StorageService.get<string>(StorageService.reservedKeys.INBOX_INSTANCE_ID)

		return Number(instanceId)
	}

	private get adminId (): number {
		const adminId = StorageService.get<string>(StorageService.reservedKeys.INBOX_ADMIN_ID)

		return Number(adminId)
	}
}

export default new HardCodedService()
