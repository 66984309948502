import { ChatBotFlowBlockType } from "@/protocols/chatBot"
import { BlockComponentConfig } from "@/protocols/chatBotFlow"

import DefaultNode from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Nodes/DefaultNode"

import SaveInputBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/SaveInputBlock"
import ManageClientTagsBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/ManageClientTagsBlock"
import GoToFlowBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/GoToFlowBlock"
import FinishFlowBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/FinishFlowBlock"
import FinishAttendanceBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/FinishAttendanceBlock"
import SendWebhookBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/SendWebhookBlock"
import ConditionalButtonBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/ConditionalButtonBlock"
import ConditionalMenuBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/ConditionalMenuBlock"
import SendMessageBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/SendMessageBlock"
import AttendanceScheduleBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/AttendanceScheduleBlock"
import AttendanceTransferBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/AttendanceTransferBlock"
import RepeatBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/RepeatBlock"
import WaitBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/WaitBlock"
import ConditionBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/ConditionBlock"
import AskAndWaitBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/AskAndWaitBlock"
import RegisterContactInActiveCampaignBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/RegisterContactInActiveCampaignBlock"
import RegisterDealInActiveCampaignBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/RegisterDealInActiveCampaignBlock"
import OptOutOptInBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/OptOutOptInBlock"
import ValidateAndSaveBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/ValidateAndSaveBlock"
import ConditionalTextBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/ConditionalTextBlock"
import RandomPathBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/RandomPathBlock"
import AIAgentConversationBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/AIAgentConversationBlock"
import RegisterTagInActiveCampaignBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/RegisterTagInActiveCampaignBlock"
import ValidateAndSaveBlockV2 from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/ValidanteAndSaveBlockV2"
import SendWABATemplateMessageAndWaitBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/SendWABATemplateMessageAndWaitBlock"
import ConditionalWABAButtonTemplateBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/ConditionalWABAButtonTemplateBlock"
import SendMetaAdsEventConversionBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/SendMetaAdsEventConversionBlock"

export const blockComponentConfig: Record<ChatBotFlowBlockType, BlockComponentConfig> = {
	"send-message": {
		Component: {
			Block: SendMessageBlock
		},
		nodeName: "sendmessageblock"
	},
	"save-input": {
		Component: {
			Block: SaveInputBlock
		},
		nodeName: "saveinputblock"
	},
	"restart-flow": {
		Component: {
			Block: DefaultNode
		},
		nodeName: "restartflowblock"
	},
	"manage-client-tags": {
		Component: {
			Block: ManageClientTagsBlock
		},
		nodeName: "manageclienttagsblock"
	},
	"go-to-flow": {
		Component: {
			Block: GoToFlowBlock
		},
		nodeName: "gotoflowblock"
	},
	"finish-attendance": {
		Component: {
			Block: FinishAttendanceBlock
		},
		nodeName: "finishattendanceblock"
	},
	"conditional-text": {
		Component: {
			Block: ConditionalTextBlock
		},
		nodeName: "conditionaltextblock"
	},
	"conditional-menu": {
		Component: {
			Block: ConditionalMenuBlock
		},
		nodeName: "conditionalmenublock"
	},
	"conditional-button": {
		Component: {
			Block: ConditionalButtonBlock
		},
		nodeName: "conditionalbuttonblock"
	},
	"attendance-schedule": {
		Component: {
			Block: AttendanceScheduleBlock
		},
		nodeName: "attendancescheduleblock"
	},
	"attendance-transfer": {
		Component: {
			Block: AttendanceTransferBlock
		},
		nodeName: "attendancetransferblock"
	},
	"assign-attendance-to-general-queue": {
		Component: {
			Block: DefaultNode
		},
		nodeName: "assignattendancetogeneralqueueblock"
	},
	"assign-attendance-to-account-manager": {
		Component: {
			Block: DefaultNode
		},
		nodeName: "assignattendancetoaccountmanagerblock"
	},
	"assign-attendance-randomly": {
		Component: {
			Block: DefaultNode
		},
		nodeName: "assignattendancerandomlyblock"
	},
	"assign-attendance": {
		Component: {
			Block: DefaultNode
		},
		nodeName: "assignattendanceblock"
	},
	"send-webhook": {
		Component: {
			Block: SendWebhookBlock
		},
		nodeName: "sendwebhookblock"
	},
	"finish-flow": {
		Component: {
			Block: FinishFlowBlock
		},
		nodeName: "finishflowblock"
	},
	repeat: {
		Component: {
			Block: RepeatBlock
		},
		nodeName: "repeatblock"
	},
	condition: {
		Component: {
			Block: ConditionBlock
		},
		nodeName: "conditionblock"
	},
	"register-contact-to-active-campaign": {
		Component: {
			Block: RegisterContactInActiveCampaignBlock
		},
		nodeName: "registercontacttoactivecampaignblock"
	},
	wait: {
		Component: {
			Block: WaitBlock
		},
		nodeName: "waitblock"
	},
	"register-deal-to-active-campaign": {
		Component: {
			Block: RegisterDealInActiveCampaignBlock
		},
		nodeName: "registerdealtoactivecampaignblock"
	},
	"ask-and-wait": {
		Component: {
			Block: AskAndWaitBlock
		},
		nodeName: "askandwaitreponse"
	},
	"optout-optin": {
		Component: {
			Block: OptOutOptInBlock
		},
		nodeName: "optoutoptin"
	},
	"validate-and-save": {
		Component: {
			Block: ValidateAndSaveBlock
		},
		nodeName: "validateandsave"
	},
	"random-path-flow": {
		Component: {
			Block: RandomPathBlock
		},
		nodeName: "randompathblock"
	},
	"ai-agent-conversation": {
		Component: {
			Block: AIAgentConversationBlock
		},
		nodeName: "aiagentconversation"
	},
	"register-tag-to-active-campaign": {
		Component: {
			Block: RegisterTagInActiveCampaignBlock
		},
		nodeName: "registertagtoactivecampaign"
	},
	"meta-ads-conversion-event": {
		Component: {
			Block: SendMetaAdsEventConversionBlock
		},
		nodeName: "metaadsconversionevent"
	},
	"validate-and-save-v2": {
		Component: {
			Block: ValidateAndSaveBlockV2
		},
		nodeName: "validateandsavev2"
	},
	"send-waba-template-message-and-wait": {
		Component: {
			Block: SendWABATemplateMessageAndWaitBlock
		},
		nodeName: "sendwabatemplatemessageandwait"
	},
	"conditional-waba-button-template": {
		Component: {
			Block: ConditionalWABAButtonTemplateBlock
		},
		nodeName: "conditionalwababuttontemplate"
	}
}
