import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"
import newColors from "@/styles/newColors"

const useStyles = makeStyles({
	tabs: {
		position: "relative",
		"&::before": {
			content: "close-quote",
			position: "absolute",
			width: "100%",
			height: "1px",
			bottom: 0,
			left: 0,
			backgroundColor: newColors.grey[200]
		}
	},
	tab: {
		color: `${colors.grayScale[4]} !important`,
		opacity: 1,
		fontSize: 14
	},
	tabIndicator: {
		backgroundColor: colors.palette.primary
	},
	disableTab: {
		color: newColors.grey[400],
		boxShadow: "none",
		opacity: 0.7
	},
	tabArea: {
		display: "flex",
		gap: 12
	}
})

export default useStyles
