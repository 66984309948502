import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"
import newColors from "@/styles/newColors"

const useStyles = makeStyles(theme => ({
	container: {
		height: "100%",
		width: "100%",
		backgroundColor: colors.palette.background,
		"& .react-flow__attribution": {
			display: "none"
		},
		"& .react-flow__node": {
			zIndex: "-1 !important"
		},
		position: "relative"
	},
	flowHidden: {
		opacity: 0,
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	flowVisible: {
		opacity: 1,
		/**
		 * It is necessary to overwrite the position property that comes standard
		 * in the react-flow lib to avoid the bug of drawing in the background of the bot flow
		 */
		position: "initial !important"
	},
	fitViewLoading: {
		position: "absolute",
		top: "50%",
		right: "50%"
	},
	validateButton: {
		position: "absolute",
		padding: theme.spacing(1, 2),
		zIndex: 9,
		bottom: theme.spacing(2.25),
		right: theme.spacing(7),
		border: "1px solid",
		boxShadow: "none",
		borderColor: "rgba(126, 13, 214, 0.12)",
		fontWeight: 500,
		fontSize: theme.spacing(1.75),
		backgroundColor: "rgba(126, 13, 214, 0.1)",
		color: colors.palette.primary,
		letterSpacing: 0.4,
		"&:hover": {
			backgroundColor: "rgba(126, 13, 214, 0.2)",
			boxShadow: "none"
		}
	},
	validateButtonIcon: {
		fill: "none",
		marginTop: "3px"
	},
	savingFlowOverlay: {
		position: "absolute",
		backgroundColor: newColors.grey[0],
		opacity: 0.5,
		height: "100%",
		zIndex: 99999
	}
}))

export default useStyles
