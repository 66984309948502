import React, { useState } from "react"

import { InfoDialog } from "@/components"
import { renderComponent } from "@/utils/node"

import ManagementUserAccessData from "@/pages/Admin/Security/ManagementUserAccessData"

type ManagementUserAccessDataSettingsDialogType = {
	open: () => void
}

const ManagementUserAccessDataSettingsDialog: ManagementUserAccessDataSettingsDialogType & React.FC = () => {
	const [opened, setOpened] = useState(true)

	const handleClose = () => {
		setOpened(false)
	}

	return (
		<InfoDialog
			title="DADOS DE ACESSO DO USUÁRIO"
			openDialog={opened}
			onClose={handleClose}
			maxWidth="md"
		>
			<ManagementUserAccessData />
		</InfoDialog>
	)
}

ManagementUserAccessDataSettingsDialog.open = () => {
	renderComponent("management-user-access-data-settings", <ManagementUserAccessDataSettingsDialog />)
}

export default ManagementUserAccessDataSettingsDialog
