import React, { useState } from "react"

import {
	Grid,
	Tab,
	Tabs
} from "@material-ui/core"

import { Breadcrumb } from "@/components"

import DevicesAndSessions from "@/pages/Admin/Security/DevicesAndSessions"
import ManagementUserAccessData from "@/pages/Admin/Security/ManagementUserAccessData"

import useStyles from "@/pages/Admin/Security/styles"

type SecurityTab = {
	title: string
	type: "device-and-session" | "management-user-access-data"
}

const SECURITY_TABS: SecurityTab[] = [
	{
		title: "DISPOSITIVOS E SESSÕES",
		type: "device-and-session"
	},
	{
		title: "DADOS DE ACESSO DO USUÁRIO",
		type: "management-user-access-data"
	}
]

const Security = () => {
	const [currentSecurityTabType, setCurrentSecurityTabType] = useState<SecurityTab["type"]>("device-and-session")

	const classes = useStyles()

	const handleChangeCurrentSecurityTabType = (type: SecurityTab["type"]): void => {
		setCurrentSecurityTabType(type)
	}

	const handleOnChangeTabValue = (value: SecurityTab["type"]) => {
		handleChangeCurrentSecurityTabType(value)
	}

	return (
		<Grid
			container
			spacing={4}
		>
			<Grid
				item
				xs={12}
			>
				<Breadcrumb
					data={[
						{ name: "Segurança", pathname: "/admin/security" }
					]}
				/>
			</Grid>

			<Grid
				item
				xs={12}
			>
				<Grid
					container
					direction="column"
					spacing={2}
				>
					<Grid
						item
					>
						<Tabs
							color="primary"
							value={currentSecurityTabType}
							className={classes.tabs}
							onChange={(_, value) => handleOnChangeTabValue(value)}
							classes={{
								indicator: classes.tabIndicator
							}}
						>
							{SECURITY_TABS.map((securityTab, index) => {
								return (
									<Tab
										key={index}
										label={
											<Grid className={classes.tabArea} justifyContent="center" alignItems="center">
												<span>{securityTab.title}</span>
											</Grid>

										}
										value={securityTab.type}
										className={classes.tab}
									/>
								)
							})}
						</Tabs>
					</Grid>

					<Grid
						item
					>
						{currentSecurityTabType === "device-and-session" && (
							<DevicesAndSessions
								onReload={() => window.location.reload()}
							/>
						)}

						{currentSecurityTabType === "management-user-access-data" && (
							<ManagementUserAccessData />
						)}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default Security
