import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles({
	infoIconSize: {
		width: 16,
		height: 16
	},
	danger: {
		color: colors.palette.error
	},
	iconButton: {
		"&:hover": {
			backgroundColor: "transparent"
		}
	}
})

export default useStyles
