import React from "react"

import {
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Popover, Tooltip
} from "@material-ui/core"
import {
	InfoOutlined as InfoIcon,
	MoreVert as MoreVertIcon
} from "@material-ui/icons"

import useStyles from "@/pages/Admin/Security/DevicesAndSessions/DeviceItem/DeviceOptionsMenu/styles"
import colors from "@/styles/colors"

type DeviceOptionsColorType = "danger"

export type DeviceOptionsMenuOptionData = {
	title: string
	icon?: JSX.Element
	showIcon?: boolean
	color?: DeviceOptionsColorType
	onClick?: () => Promise<void> | void
	disabled?: boolean
	tooltipTitle?: string
}

export type DeviceOptionsMenuProps = {
	options: DeviceOptionsMenuOptionData[]
	iconSize?: "small" | "medium"
	closeOnClick?: boolean
}

const DeviceOptionsMenu = (props: DeviceOptionsMenuProps) => {
	const [deviceOptionsMenuAnchorEl, setDeviceOptionsMenuAnchorEl] = React.useState<null | HTMLElement>(null)
	const isDeviceMenuOptionsOpen = Boolean(deviceOptionsMenuAnchorEl)

	const {
		options,
		iconSize,
		closeOnClick
	} = props

	const classes = useStyles()

	const handleDeviceOptionsMenuClose = () => {
		setDeviceOptionsMenuAnchorEl(null)
	}

	const handleDeviceOptionsMenuClick = (event: React.MouseEvent<HTMLElement>) => {
		setDeviceOptionsMenuAnchorEl(event.currentTarget)
	}

	const customStyleByColor: Record<DeviceOptionsColorType, string> = {
		danger: classes.danger
	}

	return <>
		<IconButton
			aria-label="more"
			aria-controls="long-menu"
			aria-haspopup="true"
			size={iconSize || "medium"}
			className={classes.iconButton}
			onClick={handleDeviceOptionsMenuClick}
		>
			<MoreVertIcon />
		</IconButton>
		<Popover
			open={isDeviceMenuOptionsOpen}
			anchorEl={deviceOptionsMenuAnchorEl}
			onClose={handleDeviceOptionsMenuClose}
			anchorOrigin={{ vertical: "top", horizontal: "left" }}
			transformOrigin={{ vertical: "top", horizontal: "left" }}
		>
			<List>
				{options.map((option, index) => (
					<Grid
						key={index}
						container
						justifyContent="space-between"
						alignItems="center"
						style={{
							padding: "0px 8px"
						}}
					>
						<Grid
							item
							{...(!option.disabled && {
								xs: 12
							})}
						>
							<ListItem
								button
								onClick={() => {
									if (closeOnClick) {
										handleDeviceOptionsMenuClose()
									}
									option?.onClick?.()
								}}
								key={option.title}
								disabled={option?.disabled}
							>
								{
									option.showIcon && <ListItemIcon
										style={{ minWidth: "30px" }}
										className={option.color && customStyleByColor[option.color]}>
										{option.icon}
									</ListItemIcon>
								}
								<ListItemText
									primary={option.title}
									classes={{
										primary: option.color && customStyleByColor[option.color]
									}}
								/>
							</ListItem>
						</Grid>

						{option.disabled && (
							<Grid
								item
							>
								<Grid
									container
									alignItems="center"
								>
									<Tooltip
										title={option?.tooltipTitle || ""}
									>
										<InfoIcon className={classes.infoIconSize} htmlColor={colors.grayScale[3]} />
									</Tooltip>
								</Grid>
							</Grid>
						)}
					</Grid>
				))}
			</List>
		</Popover>
	</>
}

export default DeviceOptionsMenu
