import { Grid, IconButton, Switch } from "@material-ui/core"
import React, { useState } from "react"

import { Loading, Notification } from "@/components"
import InfoItemInput from "@/components/InfoItemInput"

import { useGlobalStateStore } from "@/store/GlobalState"
import useValidation, { ErrorType } from "@/hooks/useValidation"

import UserService from "@/services/User"

const MFAInputItem = () => {
	const globalStateStore = useGlobalStateStore()
	const userStore = globalStateStore.user

	const {
		triggerValidation
	} = useValidation()

	const [loadingMfa, setLoadingMfa] = useState<boolean>(false)

	const isLoginAuthenticationActive = userStore?.authenticationsData?.["email-login"]?.active || false

	const handleMfaChange = async () => {
		setLoadingMfa(true)
		try {
			await UserService.updateMFAStatus({
				type: "email-login",
				isActive: isLoginAuthenticationActive
			})

			globalStateStore.setUserData({
				authenticationsData: {
					...userStore?.authenticationsData,
					"email-login": {
						...userStore?.authenticationsData["email-login"],
						active: !isLoginAuthenticationActive
					}
				}
			})

			Notification.success({ message: `MFA ${isLoginAuthenticationActive ? "Desabilitado" : "Habilitado"} com sucesso!` })
		} catch (err) {
			Notification.error({ message: "Erro ao alterar MFA, busque o suporte" })
			triggerValidation(err as ErrorType)
		}

		setLoadingMfa(false)
	}

	return (
		<Grid
		>
			<InfoItemInput
				title={`${isLoginAuthenticationActive ? "Desabilitar" : "Habilitar"} MFA`}
				disableEdit
			>
				{
					loadingMfa ? (
						<Loading
							loading={true}
						></Loading>
					) : (
						<IconButton>
							<Switch
								checked={userStore?.authenticationsData?.["email-login"]?.active || false}
								onClick={handleMfaChange}
							/>
						</IconButton>
					)
				}
			</InfoItemInput>
		</Grid>
	)
}

export default MFAInputItem
