import React, { useState } from "react"

import {
	TextField,
	InputAdornment,
	IconButton,
	Grid
} from "@material-ui/core"
import {
	Visibility as ShowPassword,
	VisibilityOff as HiddenPassword
} from "@material-ui/icons"

import { ActionDialog, Notification } from "@/components"

import UserService, { UserDataProps } from "@/services/User"

import useValidation, { ErrorType } from "@/hooks/useValidation"

import { renderComponent } from "@/utils/node"

type UserAccessDataType = Extract<keyof UserDataProps, "email"> | "password"

type DialogContext = UserAccessDataType

export type UserDialogDataProps = {
	context: DialogContext
	value: string
	newValue: string
	confirmNewValue: string
}

type VisiblePasswordProps = {
	currentPassword: boolean
	newPassword: boolean
	confirmNewPassword: boolean
}

type ManagementPasswordDialogType = {
	open: () => void
}

const ManagementPasswordDialog: ManagementPasswordDialogType & React.FC = () => {
	const [userPasswordData, setUserPasswordData] = useState<UserDialogDataProps>({
		context: "password",
		value: "",
		newValue: "",
		confirmNewValue: ""
	})
	const [loading, setLoading] = useState<boolean>(false)
	const [open, setOpen] = useState<boolean>(true)
	const [visiblePassword, setVisiblePassword] = useState<VisiblePasswordProps>({
		currentPassword: false,
		newPassword: false,
		confirmNewPassword: false
	})

	const {
		validation,
		triggerValidation,
		clearValidation
	} = useValidation()

	const handleCloseDialog = () => {
		setOpen(false)
	}

	const handleClickShowPassword = (type: "currentPassword" | "newPassword" | "confirmNewPassword") => {
		setVisiblePassword({
			...visiblePassword,
			[type]: !visiblePassword[type]
		})
	}

	const handleUpdatePasswordClose = () => {
		clearValidation("currentPassword")
		clearValidation("newPassword")
		clearValidation("confirmNewPassword")
	}

	const handleUpdatePassword = async () => {
		setLoading(true)
		try {
			await UserService.updatePassword({
				currentPassword: userPasswordData.value,
				newPassword: userPasswordData.newValue,
				confirmNewPassword: userPasswordData.confirmNewValue
			})

			Notification.success({ message: "Senha alterada com sucesso!" })

			handleUpdatePasswordClose()
			handleCloseDialog()
		} catch (error) {
			triggerValidation(error as ErrorType)
		}
		setLoading(false)
	}

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()
	}

	const handleValue = (field: string, value: string): void => {
		handleUpdatePasswordClose()

		setUserPasswordData(lastState => ({
			...lastState,
			[field]: value
		}))
	}

	return (
		<ActionDialog
			title="Alterar senha"
			openDialog={open}
			fullWidth
			loading={loading}
			onClose={handleCloseDialog}
			onSave={handleUpdatePassword}
		>
			<Grid
				container
				direction="column"
				spacing={2}
			>
				<Grid
					item
				>
					<TextField
						placeholder="Senha"
						value={userPasswordData.value}
						onChange={({ target }) => handleValue("value", target.value)}
						variant="outlined"
						color="primary"
						multiline
						fullWidth
						error={!!validation.currentPassword}
						helperText={validation.currentPassword}
					/>
				</Grid>
				<Grid
					item
				>
					<TextField
						id="input-new-password"
						name="new-password"
						value={userPasswordData?.newValue}
						onChange={
							({ target }) => handleValue("newValue", target.value)
						}
						variant="outlined"
						placeholder="Nova senha"
						type={visiblePassword.newPassword ? "text" : "password"}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="alterar visibilidade da senha"
										onClick={() => handleClickShowPassword("newPassword")}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{visiblePassword.newPassword ? <ShowPassword /> : <HiddenPassword />}
									</IconButton>
								</InputAdornment>
							)
						}}
						fullWidth
						helperText={validation.newPassword}
						error={validation.newPassword}
					/>
				</Grid>
				<Grid
					item
				>
					<TextField
						id="input-confirm-new-password"
						name="confirm-new-password"
						value={userPasswordData?.confirmNewValue}
						onChange={
							({ target }) => handleValue("confirmNewValue", target.value)
						}
						variant="outlined"
						placeholder="Confirmar nova senha"
						type={visiblePassword.confirmNewPassword ? "text" : "password"}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="alterar visibilidade da senha"
										onClick={() => handleClickShowPassword("confirmNewPassword")}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{visiblePassword.confirmNewPassword ? <ShowPassword /> : <HiddenPassword />}
									</IconButton>
								</InputAdornment>
							)
						}}
						fullWidth
						helperText={validation.confirmNewPassword}
						error={validation.confirmNewPassword}
					/>
				</Grid>
			</Grid>
		</ActionDialog>
	)
}

ManagementPasswordDialog.open = () => {
	renderComponent(
		"management-user-access-data-dialog",
		<ManagementPasswordDialog />
	)
}

export default ManagementPasswordDialog
