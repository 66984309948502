import { ContactsFiltersConditions } from "@/pages/Admin/ClientCatalog/ClientManageFilters/ContactsFilters"
import { Client } from "@/protocols/clientCatalog"
import ApiService from "@/services/Api"

class ClientCatalogContactService {
	async disableContactsByFilter (filterConditions?: ContactsFiltersConditions[]) {
		await ApiService.put("/contacts/disable-contacts", {
			customFilterConditions: filterConditions
		})
	}

	async disableContact (client?: Client) {
		await ApiService.put("/contacts/disable-contact", {
			client
		})
	}
}

export default new ClientCatalogContactService()
