import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"
import newColors from "@/styles/newColors"

type StylesProps = {
	isMFAActive: boolean
}

const useStyles = makeStyles(() => ({
	deviceName: {
		fontSize: 14,
		textTransform: "capitalize",
		color: colors.grayScale[4],
		fontWeight: "bold",
		lineHeight: "12px"
	},
	localizationText: {
		fontSize: 10,
		textTransform: "capitalize",
		color: newColors.grey[400],
		fontWeight: "normal"
	},
	disabledIcon: {
		width: 16,
		height: 16,
		color: colors.grayScale[4],
		opacity: 0.5,
		marginRight: 10
	},
	infoText: {
		opacity: 0.5,
		fontSize: 14
	},
	deviceItem: {
		background: colors.grayScale[12],
		opacity: 0.8,
		borderRadius: 8,
		width: 64,
		height: 64,
		color: colors.grayScale[4],
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	mfaIcon: (props: StylesProps) => ({
		width: 12,
		height: 14,
		color: props.isMFAActive ? newColors.blue[600] : newColors.grey[400]
	})
}))

export default useStyles
