import React, { useRef, useState } from "react"
import { AlertContainer, Loading, Portlet } from "@/components"
import DataTable, { TableColumn } from "@/components/DataTable"
import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"
import { Button, Chip, Grid, InputAdornment, Link, TextField, Tooltip, Typography } from "@material-ui/core"
import { Color } from "@/protocols/color"
import TagService from "@/services/Tag"
import newColors from "@/styles/newColors"
import useStyles from "@/pages/Admin/Reports/TagsReport/styles"
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { ptBR } from "date-fns/locale"
import DateFnsUtils from "@date-io/date-fns"
import {
	LocalOffer as TagFilterIcon,
	Info as InfoIcon
} from "@material-ui/icons"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { Tag } from "@/protocols/tag"
import { deviceIsMobile } from "@/utils/checkDevice"
import colors from "@/styles/colors"
import TagReportsSkeleton from "@/skeletons/Admin/TagsReportSkeleton"
import { convertRGBAObjectToString } from "@/utils/color"
import { getDaysInHours, getIntervalDate } from "@/utils/time"
import useCustomStyles from "@/styles/custom"
import { Alert } from "@material-ui/lab"
import useDidMount from "@/hooks/useDidMount"
import { reportsTypeToTitle } from "@/protocols/reports"
import { LetalkIcon } from "@/assets/icons"
import { externalLinks } from "@/utils/link"

type TagsReportResponseData = {
	result: {
		tagId: number
		count: number
	}[]
}

type TagsReportBarGraphRow = {
	percent: number
	color: Color
}

type TagsReportDataRows = {
	name: string
	count: number
	barGraph: TagsReportBarGraphRow
}

type Filters = {
	tags: Tag[] | null
	fromDate: Date | null,
	toDate: Date | null,
}

type TagMetricsUsageAction = "pageEntry" | "filterClick"

const renderColumnPercent = (value: TagsReportBarGraphRow): React.ReactNode => {
	return (
		<Grid container>
			<Grid container
				style={{
					width: value?.percent > 90 ? `${value?.percent * 0.875}%` : `${value?.percent}%`,
					backgroundColor: value?.color ? convertRGBAObjectToString(value?.color) : "gray",
					height: "20px",
					borderRadius: "4px"
				}}
			>
			</Grid>
			<Typography
				variant="h5"
				style={{
					marginLeft: "6px"
				}}
			>
				{`${value?.percent?.toFixed(1)}%`}
			</Typography>
		</Grid>
	)
}

const renderLabelContacts = (value: string): React.ReactNode => {
	return (
		<Grid container direction="row" spacing={1} alignItems="center">
			<Grid item style={{ display: "flex", alignItems: "center" }}>
				{value}
			</Grid>
			<Grid item style={{ display: "flex", alignItems: "center" }}>
				<Tooltip title="Quantidade de contatos adicionados na tag">
					<InfoIcon
						htmlColor={colors.grayScale[3]}
						style={{ cursor: "pointer", fontSize: "1rem" }}
					/>
				</Tooltip>
			</Grid>
		</Grid>
	)
}

const tagsReportTableColumns: TableColumn<TagsReportDataRows>[] = [
	{ field: "name", label: "TAG", width: 25 },
	{ field: "count", label: "CONTATOS", headRender: renderLabelContacts, width: 25 },
	{ field: "barGraph", label: "PORCENTAGEM", rowRender: renderColumnPercent, width: 50 }
]

const formatTagsReportData = async (selectedTags: Tag[] | null, tagsCountData: TagsReportResponseData, allTagsInInstace: Tag[]) : Promise<TagsReportDataRows[]> => {
	const tagsInfo = new Map(allTagsInInstace.map((tag) => [tag.id, tag]))
	const tagsCount = new Map(tagsCountData.result.map((tag) => [tag.tagId, tag.count]))
	const tagsTotalClientsCount = tagsCountData?.result?.reduce((acc, currentValue) => acc + Number(currentValue.count), 0) || 1

	const tagsToFormat = [
		...tagsCountData.result,
		/*
 		* As the server only returns tags with at least one associated client,
 		* include selected tags without associated clients in the report with a count of 0.
		*/
		...(selectedTags?.filter(tag => !tagsCount.has(tag.id))?.map(tag => ({
			tagId: tag.id,
			count: 0
		})) || [])
	]

	const formattedData = tagsToFormat?.map((tag) => {
		const tagCount = tag.count
		const tagInfo = tagsInfo.get(tag.tagId)

		return {
			name: tagInfo?.name ?? "",
			count: tagCount,
			barGraph: {
				percent: (tagCount / tagsTotalClientsCount) * 100,
				color: tagInfo?.color
			}
		}
	})

	return formattedData
}

const handleLogUsage = async (usageAction: TagMetricsUsageAction) => {
	await ApiService.post("tags/metrics/log-usage", {
		usageAction
	})
}

const TagsReport: React.FC = () => {
	const MAX_TAGS_USER_CAN_DISPLAY = 30
	const ONE_MONTH_IN_HOURS = getDaysInHours(30)

	const fromDateDefault = getIntervalDate(ONE_MONTH_IN_HOURS).fromDate
	const toDateDefault = getIntervalDate(ONE_MONTH_IN_HOURS).toDate

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const allTagsInInstance = useRef<Tag[]>([])
	const [tagsReportData, setTagsReportData] = useState<TagsReportDataRows[]>([])
	const [loading, setLoading] = useState<boolean>(true)
	const [filters, setFilters] = useState<Filters>({
		tags: null,
		fromDate: fromDateDefault,
		toDate: toDateDefault
	})

	const hasTagsInInstance = () : boolean => {
		return allTagsInInstance?.current.length > 0
	}

	const handleFetchTagsData = async () => {
		setLoading(true)

		if (!hasTagsInInstance()) {
			setLoading(false)
			return
		}

		try {
			const tagSelectedIds = filters.tags?.map((tag) => (tag.id))

			const response = await ApiService.get("tags/metrics/clients-count", {
				params: {
					...filters,
					tagIds: tagSelectedIds
				}
			})

			const tagsReportResponseData = response.data as TagsReportResponseData
			const formattedData = await formatTagsReportData(filters.tags, tagsReportResponseData, allTagsInInstance.current)

			setTagsReportData(formattedData)
		} catch (error) {
			ErrorHandlerService.handle(error as Error)
		}
		setLoading(false)
	}

	const handleFetchAllTagsInInstance = async () => {
		allTagsInInstance.current = await TagService.getTags() ?? []
	}

	const handleChangeEditFilters = (data: Partial<Filters>) => {
		setFilters({
			...filters,
			...data
		})
	}

	const handleOnClickFilter = async () => {
		await handleLogUsage("filterClick")
		await handleFetchTagsData()
	}

	const hasReachedMaxTags = () : boolean => {
		return (filters.tags?.length ?? 0) >= MAX_TAGS_USER_CAN_DISPLAY
	}

	useDidMount(async () => {
		await handleFetchAllTagsInInstance()
		await handleLogUsage("pageEntry")
		await handleFetchTagsData()
	})

	return (
		<>
			<Grid
				container
				direction="column"
				spacing={6}
			>
				<Grid item>
					<Typography
						variant="h5"
						className={classes.titleText}
					>
						{reportsTypeToTitle["tags-report"]}
					</Typography>
				</Grid>
				<Grid item>
					<AlertContainer
						title="Sua opinião é fundamental!"
						icon={
							<LetalkIcon
								style={{
									width: "35px",
									height: "35px"
								}}
							/>
						}
						alertType="info"
					>
						<Typography
							style={{
								fontSize: "14px",
								fontWeight: 400,
								lineHeight: "20px"
							}}
						>
							Para aprimorarmos nosso relatório de tags, convidamos você a preencher um rápido formulário e compartilhar seu feedback.
							<Link
								href={externalLinks.tagsReportFeedbackForm}
								style={{ cursor: "pointer", marginLeft: "4px" }}
								target="_blank"
							>
								Clique aqui e contribua!
							</Link>
						</Typography>
					</AlertContainer>
				</Grid>
				<Grid item>
					<Portlet
						elevation={1}
					>
						<Grid item xs={12}>
							<Loading
								loading={loading}
								customLoadingElement={<TagReportsSkeleton />}
							>
								<Grid container direction="column" spacing={3}>

									<Grid item>
										<Grid container direction="row" justifyContent="space-between">

											<Grid item>
												<Grid container direction="row" spacing={2} justifyContent="flex-start">

													<Grid item className={classes.tagFilter}>
														<Autocomplete
															multiple
															options={hasReachedMaxTags() ? [] : allTagsInInstance.current}
															getOptionSelected={(option, value) => option.id === value.id}
															getOptionLabel={(option) => option.name}
															noOptionsText={hasReachedMaxTags() ? `Limite de ${MAX_TAGS_USER_CAN_DISPLAY} tags atingido` : "Sem mais opções de tags" }
															value={filters.tags ?? []}
															fullWidth
															filterSelectedOptions
															getOptionDisabled={() => {
																return hasReachedMaxTags()
															}}
															onChange={(_, tags) =>
																handleChangeEditFilters({ tags: tags })
															}
															renderInput={(params) => (
																<TextField
																	{...params}
																	variant="outlined"
																	placeholder="Tags"
																	{...({
																		...params,
																		InputProps: {
																			...params.InputProps,
																			startAdornment: [
																				(
																					<InputAdornment
																						position="start"
																						key="icon"
																						style={{ paddingLeft: "4px" }}
																					>
																						<TagFilterIcon />
																					</InputAdornment>
																				),
																				params.InputProps.startAdornment
																			],
																			endAdornment: (
																				<>
																					{params.InputProps.endAdornment}
																				</>
																			)
																		}
																	})}
																/>
															)}
															renderTags={(value, getTagProps) => {
																return value.map((tag, index) => (
																	<Tooltip
																		key={index}
																		title={tag.name}
																	>
																		<Chip
																			label={tag.name}
																			style={{
																				color: newColors.grey[0],
																				backgroundColor: convertRGBAObjectToString(tag.color),
																				maxWidth: deviceIsMobile() ? "239px" : "270px"
																			}}
																			size="small"
																			{...getTagProps({ index })}
																		/>
																	</Tooltip>
																))
															}}
															size="small"
														/>
													</Grid>

													<Grid item>
														<MuiPickersUtilsProvider
															locale={ptBR}
															utils={DateFnsUtils}
														>
															<Grid item className={classes.dateContainer}>
																<KeyboardDateTimePicker
																	okLabel="Pronto"
																	cancelLabel="Cancelar"
																	ampm={false}
																	value={filters.fromDate ?? fromDateDefault}
																	inputVariant="outlined"
																	onChange={(date) => handleChangeEditFilters({ fromDate: date })}
																	placeholder="De"
																	size="small"
																	format="dd/MM/yyyy HH:mm"
																	InputAdornmentProps={{
																		position: "start",
																		classes: { root: classes.dateInput }
																	}}
																	InputProps={{
																		classes: { root: classes.dateInput }
																	}}
																	KeyboardButtonProps={{
																		className: classes.dateButton
																	}}
																/>
															</Grid>
														</MuiPickersUtilsProvider>
													</Grid>

													<Grid item>
														<MuiPickersUtilsProvider
															locale={ptBR}
															utils={DateFnsUtils}
														>
															<Grid
																className={classes.dateContainer}
															>
																<KeyboardDateTimePicker
																	okLabel="Pronto"
																	cancelLabel="Cancelar"
																	ampm={false}
																	value={filters.toDate ?? toDateDefault}
																	inputVariant="outlined"
																	onChange={(date) => handleChangeEditFilters({ toDate: date })}
																	placeholder="Até"
																	size="small"
																	format="dd/MM/yyyy HH:mm"
																	InputAdornmentProps={{
																		position: "start",
																		className: classes.dateInput
																	}}
																	InputProps={{
																		className: classes.dateInput
																	}}
																	KeyboardButtonProps={{
																		className: classes.dateButton
																	}}
																/>
															</Grid>
														</MuiPickersUtilsProvider>
													</Grid>

												</Grid>
											</Grid>

											<Grid item>
												<Button
													variant="contained"
													color="primary"
													className={customClasses.primaryActionButton}
													onClick={handleOnClickFilter}
													style={{
														height: "40px"
													}}
												>
													Filtrar
												</Button>
											</Grid>

										</Grid>

									</Grid>

									<Grid item>
										<Alert severity={"info"}>
											{`É possível selecionar até ${MAX_TAGS_USER_CAN_DISPLAY} tags. Caso nenhuma seja escolhida, serão exibidas as 30 com maior número de contatos.`}
										</Alert>
									</Grid>

									<Grid item>
										<Grid item>
											<DataTable
												columns={tagsReportTableColumns}
												data={tagsReportData}
											/>
										</Grid>
										<Grid container justifyContent="flex-end" style={{ marginTop: "20px" }}>
											<Grid item>
												<Typography
													variant="h2"
													style={{ color: newColors.grey[400] }}
												>
													Total de Resultados: {tagsReportData?.length}
												</Typography>
											</Grid>
										</Grid>
									</Grid>

								</Grid>
							</Loading>
						</Grid>
					</Portlet>
				</Grid>
			</Grid>
		</>
	)
}

export default TagsReport
