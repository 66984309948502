import React from "react"
import {
	Button,
	Chip,
	Grid,
	Typography
} from "@material-ui/core"

import { AlertContainer, Portlet } from "@/components"

import useCustomStyles from "@/styles/custom"

import { celcashLinks } from "@/utils/link"
import { useGlobalStateStore } from "@/store/GlobalState"
import useStyles from "@/components/Profile/SubscriptionDataPortlet/styles"
import HardCoded from "@/services/HardCoded"

const SubscriptionDataPortlet = () => {
	const classes = useStyles()
	const customClasses = useCustomStyles()
	const globalStateStore = useGlobalStateStore()

	const QUANTITY_LIMIT_TO_BE_CONSIDERED_AS_UNLIMITED = 90000

	const activePlanName = globalStateStore.instance.subscriptionData?.plan_name
	const activeAddonsNames = globalStateStore.instance.subscriptionData?.active_plan_addons_names
	const subscriptionLimits = globalStateStore.instance.subscriptionData?.limits

	function getPermissionChip (exibitionName: string, permissionLimit?: boolean): JSX.Element {
		let permissionLimitExibitionText = "Sim"
		let className = classes.enabledLimitChip

		if (!permissionLimit) {
			permissionLimitExibitionText = "Não"
			className = classes.disabledLimitChip
		}

		return (
			<Chip
				label={`${exibitionName} [${permissionLimitExibitionText}]`}
				className={className}
			/>
		)
	}

	function getQuantityChip (exibitionName: string, quantityLimit?: number): JSX.Element {
		const quantityLimitNumber = quantityLimit || 0

		if (quantityLimitNumber > QUANTITY_LIMIT_TO_BE_CONSIDERED_AS_UNLIMITED) {
			return (
				<Chip
					label={`${exibitionName} [Ilimitado]`}
					className={classes.addonsChip}
				/>
			)
		} else {
			const className = quantityLimit === 0 ? classes.disabledLimitChip : classes.enabledLimitChip

			return (
				<Chip
					label={`${exibitionName} [${quantityLimitNumber}]`}
					className={className}
				/>
			)
		}
	}

	return (
		<Grid
			container
			direction="column"
			spacing={2}
		>
			<Grid
				item
				xs={12}
			>
				<Typography
					variant="h4"
					color="textPrimary"
					className={customClasses.uppercase}
				>
					Informações de assinatura
				</Typography>
			</Grid>
			<Grid
				item
				xs={12}
			>
				<Portlet>
					<Grid
						container
						direction="column"
						spacing={2}
					>
						<Grid
							item
							xs={12}
						>
							<AlertContainer
								alertType="info"
								title="Acesse seus dados de pagamento na celcash"
								customButton={(
									<Button
										variant="contained"
										className={customClasses.primaryActionButton}
										onClick={() => {
											window.open(celcashLinks.paymentDataPage, "_blank")
										}}
									>
										Ver dados de pagamento
									</Button>
								)}
							>
								<Typography variant="body2">
									Obs: Ao clicar no link será necessário informar o CNPJ e email utilizados no momento da compra
								</Typography>
							</AlertContainer>
						</Grid>

						<Grid
							item
							xs={12}
						>
							<Grid
								container
								direction="column"
								spacing={1}
							>
								<Grid
									item
									xs={12}
								>
									<Typography
										className={classes.title}
									>
										Plano
									</Typography>
								</Grid>
								<Grid
									item
									xs={12}
								>
									<Chip
										label={activePlanName}
										className={classes.planChip}
									/>
								</Grid>
							</Grid>
						</Grid>

						<Grid
							item
							xs={12}
						>
							<Grid
								container
								direction="column"
								spacing={1}
							>
								<Grid
									item
									xs={12}
								>
									<Typography
										className={classes.title}
									>
										Adicionais
									</Typography>
								</Grid>
								<Grid
									item
									xs={12}
								>
									<Grid
										className={classes.chipContainer}
									>
										{
											activeAddonsNames?.map((activeAddonName, index) => {
												return (
													<Chip
														key={`active-addon-name-${index}`}
														label={activeAddonName}
														className={classes.addonsChip}
													/>
												)
											})
										}
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						{
							HardCoded.isAdmin() && (
								<>

									<Grid
										item
										xs={12}
									>
										<Grid
											container
											direction="column"
											spacing={1}
										>
											<Grid
												item
												xs={12}
											>
												<Typography
													className={classes.title}
												>
													Limites
												</Typography>

											</Grid>
											<Grid
												item
												xs={12}
											>
												<Grid
													className={classes.chipContainer}
													xs={12}
												>
													{getPermissionChip("Plugin de receber dados active campaign", subscriptionLimits.permission.active_campaign_chat_plugin)}
													{getPermissionChip("Ações em massa para contatos", subscriptionLimits.permission.bulk_contact_action)}
													{getPermissionChip("Acesso ao chatbot", subscriptionLimits.permission.chatbot)}
													{getPermissionChip("Acesso aos grupos", subscriptionLimits.permission.group)}
													{getPermissionChip("Mensagem para grupos no inbox", subscriptionLimits.permission.inbox_message_group)}
													{getPermissionChip("Acesso às integrações", subscriptionLimits.permission.integration)}
													{getPermissionChip("Envio em massa", subscriptionLimits.permission.messageBlast)}

													{getQuantityChip("Bots ativos", subscriptionLimits.quantity.active_chatbot)}
													{getQuantityChip("Quantidade de melhorias de texto por IA", subscriptionLimits.quantity.ai_text_enhancement)}
													{getQuantityChip("Bots criados", subscriptionLimits.quantity.chatbot)}
													{getQuantityChip("Disparos diários", subscriptionLimits.quantity.message)}
													{getQuantityChip("Usuários ativos", subscriptionLimits.quantity.user)}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</>
							)
						}
					</Grid>
				</Portlet>
			</Grid>
		</Grid>
	)
}

export default SubscriptionDataPortlet
