import React from "react"

import {
	Grid, Tooltip,
	Typography
} from "@material-ui/core"
import {
	AccountBox as FirstAccessIcon,
	Replay as LastActiveSessionIcon,
	ExitToApp as FinishSessionIcon,
	PhonelinkErase as UnregisterDeviceIcon,
	VerifiedUser as MFAIcon
} from "@material-ui/icons"
import {
	ComputerChromeDeviceIcon, ComputerDefaultBrowserDeviceIcon,
	ComputerEdgeDeviceIcon,
	ComputerFirefoxDeviceIcon,
	MobileChromeDeviceIcon, MobileDefaultBrowserDeviceIcon,
	MobileEdgeDeviceIcon,
	MobileFirefoxDeviceIcon
} from "@/assets/icons"

import {
	Portlet
} from "@/components"

import FinishSessionDialog from "@/pages/Admin/Security/DevicesAndSessions/DeviceItem/FinishSessionDialog"
import UnregisterDeviceDialog from "@/pages/Admin/Security/DevicesAndSessions/DeviceItem/UnregisterDeviceDialog"
import DeviceOptionsMenu, {
	DeviceOptionsMenuOptionData
} from "@/pages/Admin/Security/DevicesAndSessions/DeviceItem/DeviceOptionsMenu"

import { fullDatetime } from "@/utils/time"

import useStyles from "@/pages/Admin/Security/DevicesAndSessions/DeviceItem/styles"

export type InboxDeviceType = "COMPUTER" | "MOBILE"

export type InboxBrowserType =
	"CHROME" |
	"FIREFOX" |
	"EDGE" |
	"SAFARI" |
	"OPERA" |
	"UNKNOWN"

export type InboxPlatformType =
	"WINDOWS" |
	"ANDROID" |
	"IOS" |
	"MAC_OS" |
	"LINUX" |
	"UNKNOWN"

type SessionLocalization = {
	ip?: string
	city: string
}

type DeviceSession = {
	id: number
	isUserCurrentSession: boolean
	isActiveSession: boolean
	firstAccessDate: Date
	lastActiveSessionDate: Date | string
	localization?: SessionLocalization
}

export type Device = {
	type: InboxDeviceType
	browser: InboxBrowserType
	platform: InboxPlatformType
	session: DeviceSession
	isMFAActive: boolean
}

type DeviceItemProps = {
	device: Device
	onReload?: () => void
}

const DeviceItem: React.FC<DeviceItemProps> = (props) => {
	const {
		onReload,
		device
	} = props

	const isActiveSession = device.session.isActiveSession
	const isCurrentDeviceWithActiveSession = isActiveSession && device.session.isUserCurrentSession

	const classes = useStyles({
		isMFAActive: device.isMFAActive
	})

	const handleGetDeviceIcon = (): React.ReactNode => {
		const deviceTypeToDeviceIcon: Record<Device["type"], Record<Device["browser"], React.ReactNode>> = {
			COMPUTER: {
				CHROME: <ComputerChromeDeviceIcon />,
				EDGE: <ComputerEdgeDeviceIcon />,
				FIREFOX: <ComputerFirefoxDeviceIcon />,
				OPERA: <ComputerDefaultBrowserDeviceIcon />,
				SAFARI: <ComputerDefaultBrowserDeviceIcon />,
				UNKNOWN: <ComputerDefaultBrowserDeviceIcon />
			},
			MOBILE: {
				CHROME: <MobileChromeDeviceIcon />,
				EDGE: <MobileEdgeDeviceIcon />,
				FIREFOX: <MobileFirefoxDeviceIcon />,
				OPERA: <MobileDefaultBrowserDeviceIcon />,
				SAFARI: <MobileDefaultBrowserDeviceIcon />,
				UNKNOWN: <MobileDefaultBrowserDeviceIcon />
			}
		}

		return deviceTypeToDeviceIcon[device.type][device.browser]
	}

	const handleGetDeviceName = (): string => {
		const deviceTypeToDeviceName: Record<Device["type"], string> = {
			COMPUTER: "Computador",
			MOBILE: "Telefone"
		}

		return deviceTypeToDeviceName[device.type]
	}

	const handleGetFinishSessionTooltipTitle = (): string => {
		if (!isActiveSession) {
			return "Essa funcionalidade está disponível apenas quando o dispositivo tem uma sessão ativa."
		}

		if (isCurrentDeviceWithActiveSession) {
			return "Essa funcionalidade está indisponível, pois essa é a sua sessão atual."
		}

		return ""
	}

	const handleGetUnregisterDeviceTooltipTitle = (): string => {
		if (!device.isMFAActive) {
			return "Essa funcionalidade está disponível apenas quando o dispositivo está registrado."
		}

		if (isCurrentDeviceWithActiveSession) {
			return "Essa funcionalidade está indisponível, pois essa é a sua sessão atual."
		}

		return ""
	}

	const handleGetDeviceOptionsMenu = (): DeviceOptionsMenuOptionData[] => {
		const isDisabledFinishSessionOption = device.session.isUserCurrentSession || !device.session.isActiveSession
		const isDisabledUnregisterDeviceOption = device.session.isUserCurrentSession || !device.isMFAActive

		const deviceOptionsMenu: DeviceOptionsMenuOptionData[] = [
			{
				title: "Encerrar Sessão",
				icon: <FinishSessionIcon />,
				showIcon: true,
				onClick: () => FinishSessionDialog.open({
					sessionId: device.session.id,
					onReload: onReload
				}),
				disabled: isDisabledFinishSessionOption,
				tooltipTitle: handleGetFinishSessionTooltipTitle()
			},
			{
				title: "Descadastrar dispositivo",
				showIcon: true,
				icon: <UnregisterDeviceIcon />,
				onClick: () => UnregisterDeviceDialog.open({
					sessionId: device.session.id,
					onReload: onReload
				}),
				disabled: isDisabledUnregisterDeviceOption,
				tooltipTitle: handleGetUnregisterDeviceTooltipTitle()
			}
		]

		return deviceOptionsMenu
	}

	const handleGetMFATooltipTitle = (): string => {
		if (device.isMFAActive) {
			return "Este dispositivo está registrado no MFA, por isso a autenticação multifator não será solicitada no login."
		}

		return "Este dispositivo não está registrado no MFA, portanto, a autenticação multifator será solicitada a cada login."
	}

	const handleGetPlatformName = (): string => {
		const deviceOperatingSystemTypeToDeviceOperatingSystemName: Record<InboxPlatformType, string> = {
			ANDROID: "Android",
			IOS: "IOS",
			WINDOWS: "Windows",
			UNKNOWN: "",
			LINUX: "Linux",
			MAC_OS: "Mac OS"
		}

		return deviceOperatingSystemTypeToDeviceOperatingSystemName[device.platform]
	}

	const handleGetBrowserName = (): string => {
		const deviceBrowserTypeToDeviceBrowserName: Record<InboxBrowserType, string> = {
			FIREFOX: "Firefox",
			EDGE: "Microsoft Edge",
			CHROME: "Google Chrome",
			OPERA: "Opera",
			SAFARI: "Safari",
			UNKNOWN: ""
		}

		return deviceBrowserTypeToDeviceBrowserName[device.browser]
	}

	const handleGetDeviceInfo = () => {
		const deviceName = handleGetDeviceName()

		const devicePlatform = handleGetPlatformName()
		const deviceBrowser = handleGetBrowserName()

		if (devicePlatform && deviceBrowser) {
			return deviceName.concat(`- ${devicePlatform}/${deviceBrowser}`)
		}

		return deviceName
	}

	return (
		<Portlet
			elevation={1}
		>
			<Grid
				container
				alignItems="center"
			>
				<Grid
					item
					xs={6}
				>
					<Grid
						container
						alignItems="center"
					>
						<Grid
							item
							xs={2}
						>
							<Grid
								container
								justifyContent="center"
								alignItems="center"
							>
								{handleGetDeviceIcon()}
							</Grid>
						</Grid>

						<Grid
							item
							xs={8}
						>
							<Grid
								container
								direction="column"
							>
								<Grid
									item
								>
									<Grid
										container
										alignItems="center"
										spacing={1}
									>
										<Grid
											item
										>
											<Typography
												className={classes.deviceName}
											>
												{handleGetDeviceInfo()}
											</Typography>
										</Grid>

										<Grid
											item
										>
											<Tooltip title={handleGetMFATooltipTitle()}>
												<MFAIcon
													className={classes.mfaIcon}
												/>
											</Tooltip>
										</Grid>
									</Grid>
								</Grid>

								<Grid
									item
								>
									{device.session?.localization ? (
										<Typography
											className={classes.localizationText}
										>
											<span style={{ fontWeight: "bold" }}>Localização:</span> {device.session.localization.city} | <span style={{ fontWeight: "bold" }}>IP:</span> {device.session.localization?.ip}
										</Typography>
									) : (
										<Typography
											className={classes.localizationText}
										>
											Localização indisponível
										</Typography>
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid
					item
					xs={6}
				>
					<Grid
						container
						alignItems="center"
					>
						<Grid
							item
							xs={10}
						>
							<Grid
								container
								direction="column"
							>
								<Grid
									item
									xs={12}
								>
									<Grid
										container
										alignItems="center"
									>
										<FirstAccessIcon
											className={classes.disabledIcon}
										/>

										<Typography
											variant="body1"
											className={classes.infoText}
										>
											Primeiro acesso: {fullDatetime(new Date(device.session.firstAccessDate))}
										</Typography>
									</Grid>
								</Grid>

								{!device.session.isActiveSession && (
									<Grid
										item
										xs={12}
									>
										<Grid
											container
											alignItems="center"
										>
											<LastActiveSessionIcon
												className={classes.disabledIcon}
											/>

											<Typography
												variant="body1"
												className={classes.infoText}
											>
												Ativo pela última vez: {fullDatetime(new Date(device.session.lastActiveSessionDate))}
											</Typography>
										</Grid>
									</Grid>
								)}
							</Grid>
						</Grid>
						<Grid
							item
							xs={2}
						>
							<Grid
								container
								justifyContent="flex-end"
							>
								<Grid
									item
									className={classes.deviceItem}
								>
									<DeviceOptionsMenu
										options={handleGetDeviceOptionsMenu()}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default DeviceItem
