import React from "react"
import { TextField } from "@material-ui/core"

import SelectInput from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/SelectInput"
import { AvailableValidationInputValue } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/GenericConditionSectionEditor"

import {
	getCustomFieldIdFromClientCustomFieldVariable,
	buildClientCustomFieldVariable
} from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowVariable"

import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"

const ClientCustomFieldInput: AvailableValidationInputValue = {
	title: "Campo personalizado",
	defaultFirstParamValue: "",
	firstParam: {
		render: ({ onChangeValidationValue, value, constructionResources }) => {
			const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()

			const customFieldId = getCustomFieldIdFromClientCustomFieldVariable(String(value))

			const selectedCustomField = constructionResources.conditionOptions.customFields?.find((customField) => customField.id === customFieldId)

			return (
				<SelectInput
					defaultOption={selectedCustomField}
					onChange={customField => onChangeValidationValue(customField ? buildClientCustomFieldVariable(customField.id) : "")}
					options={constructionResources.conditionOptions.customFields}
					placeholder="Campo personalizado"
					getOptionSelected={(option, value) => option.id === value.id}
					onUpdate={async () => {
						await chatBotFlowConstructorStore.loadSpecificConstructionResources("conditionOptions")
					}}
					noOptionsText={"Nenhum campo personalizado encontrado"}
				/>
			)
		}
	},
	secondParam: {
		render: ({ onChangeValidationValue, value }) => (
			<TextField
				variant="outlined"
				placeholder="Suporte"
				value={value}
				onChange={(event) => onChangeValidationValue(event.target.value)}
				fullWidth
			/>
		)
	},
	types: ["equals", "contains", "not-contains", "greater-than", "lower-than"]
}

export default ClientCustomFieldInput
