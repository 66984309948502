import React from "react"

import { Skeleton } from "@material-ui/lab"
import { Grid, makeStyles } from "@material-ui/core"

import {
	Portlet
} from "@/components"

const useStyles = makeStyles({
	container: {
		height: 70
	}
})

const DeviceAndSessionsSkeleton = () => {
	const exampleArray = Array.from(Array(1).keys())

	const classes = useStyles()

	const getDevices = () => {
		return (
			<Grid
				container
				direction="column"
				spacing={2}
			>
				{exampleArray.map((_, index) => (
					<Grid
						item
						key={index}
					>
						<Portlet
							elevation={1}
						>
							<Grid
								container
								alignItems="center"
								className={classes.container}
							>
								<Grid
									item
									xs={6}
								>
									<Grid
										container
										alignItems="center"
									>
										<Grid
											item
											xs={2}
										>
											<Skeleton
												variant="rect"
												width={40}
												height={40}
											/>
										</Grid>

										<Grid
											item
											xs={8}
										>
											<Skeleton variant="text" width={200} height={50} />
										</Grid>
									</Grid>
								</Grid>

								<Grid
									item
									xs={6}
								>
									<Grid
										container
										alignItems="center"
									>
										<Grid
											item
											xs={10}
										>
											<Skeleton variant="text" width={200} height={50} />
										</Grid>

										<Grid
											item
											xs={2}
										>
											<Skeleton variant="rect" width={64} height={64} />
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Portlet>
					</Grid>
				))}
			</Grid>
		)
	}

	return (
		<Grid
			container
			direction="column"
			spacing={1}
		>
			<Grid
				item
				xs={12}
			>
				<Skeleton
					variant="rect"
					width="100%"
					height={80}
				/>
			</Grid>

			<Grid
				item
			>
				<Grid
					container
					direction="column"
					spacing={4}
				>
					<Grid
						item
						xs={12}
					>
						<Grid
							container
							direction="column"
							spacing={2}
						>
							<Grid
								item
							>
								<Skeleton
									variant="text"
									width={250}
									height={50}
								/>
							</Grid>

							<Grid
								item
							>
								{getDevices()}
							</Grid>
						</Grid>
					</Grid>

					<Grid
						item
						xs={12}
					>
						<Grid
							container
							direction="column"
							spacing={2}
						>
							<Grid
								item
							>
								<Skeleton
									variant="text"
									width={250}
									height={50}
								/>
							</Grid>

							<Grid
								item
							>
								{getDevices()}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default DeviceAndSessionsSkeleton
