import colors from "@/styles/colors"
import newColors from "@/styles/newColors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(({
	titleText: {
		fontSize: "32px",
		lineHeight: "24px",
		color: newColors.grey[800]
	},
	paper: {
		width: "100%"
	},
	dateContainer: {
		flex: 1,
		padding: 0,
		width: "180px",
		margin: 0
	},
	dateInput: {
		paddingLeft: "0px",
		marginRight: "0px",
		fontSize: "14px",
		height: "40px"
	},
	dateButton: {
		paddingLeft: "9px",
		paddingRight: "9px"
	},
	tagFilter: {
		minWidth: "250px",
		overflowY: "auto",
		maxHeight: 160,
		maxWidth: "500px",
		"&::-webkit-scrollbar": {
			width: "0.3em"
		},
		"&::-webkit-scrollbar-track": {
			"-webkit-box-shadow": `inset 0 0 6px ${colors.unrelated["79797900"]}`
		},
		"&::-webkit-scrollbar-thumb": {
			border: "none",
			outline: 1,
			backgroundColor: "rgba(0,0,0,.1)"
		}
	}
}))

export default useStyles
