import React from "react"
import { Link as LinkRouter } from "react-router-dom"
import {
	Box,
	Button,
	Grid,
	IconButton,
	Typography
} from "@material-ui/core"

import {
	deviceIsMobile,
	isSmallScreen
} from "@/utils/checkDevice"

import {
	Person as AttendantsIcon,
	Contacts as ClientCatalogIcon,
	Assistant as QuickReplyIcon,
	Group as TeamIcon,
	LocalOffer as TagIcon,
	Close as CloseIcon,
	AccountCircle as MyDataIcon,
	Dashboard as DashboardIcon,
	Assessment as ReportIcon,
	Description as TemplateIcon,
	Notifications as NotificationsIcon,
	QuestionAnswer as GoToInboxIcon,
	Https as SecurityIcon
} from "@material-ui/icons"

import {
	ReactComponent as MegaphoneIcon
} from "@/assets/icons/megaphone.svg"

import {
	ReactComponent as ActiveCampaignSVGIcon
} from "@/assets/images/logos/active_campaign.svg"

import {
	ReactComponent as RDStationSVGIcon
} from "@/assets/images/logos/rd_station.svg"

import {
	ListItemMenu,
	Divider,
	AccessibleDrawer,
	SvgIcon
} from "@/components"

import {
	ReactComponent as ChatBotIcon
} from "@/assets/icons/chat_bot.svg"
import logoImg from "@/assets/images/logos/letalk-logo.svg"

import ListItemCategory from "@/components/ListItemCategory"
import { useGlobalStateStore } from "@/store/GlobalState"

import useStyles from "@/pages/Admin/Menu/styles"
import useCustomStyles from "@/styles/custom"
import { MetaLogo } from "@/assets/icons"

type WABAMenuProps = {
	openDrawer: boolean
	onClose: () => void
}

const WABASideBar = (props: WABAMenuProps) => {
	const {
		openDrawer,
		onClose
	} = props

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const globalStateStore = useGlobalStateStore()

	const isShowAllChatBotVersions = globalStateStore.user.botVersions.willShow

	const handleDrawerClose = () => {
		onClose()
	}

	return (
		<AccessibleDrawer
			variant={isSmallScreen ? "temporary" : "permanent"}
			open={!isSmallScreen || openDrawer}
			anchor="left"
			onClose={handleDrawerClose}
			onMobileBackButtonPress={handleDrawerClose}
			withoutTriggerHistoryBackEvent
			className={!isSmallScreen ? classes.drawer : ""}
			classes={{
				paper: !isSmallScreen ? `${classes.paper} ${customClasses.scrollBar}` : customClasses.scrollBar
			}}
		>
			<Grid
				container
				justify="center"
				alignItems="center"
				className={classes.container}
			>
				<Grid
					item
					xs={12}
					className={classes.topInfoContainer}
				>
					{
						isSmallScreen &&
						<>
							<Divider size={2} orientation="horizontal" />
							<Grid container justify="space-between" alignItems="center">
								<Grid item xs>
									<img
										alt="Letalk"
										src={logoImg}
										className={classes.logo}
									/>
								</Grid>

								<Grid item>
									<IconButton
										color="inherit"
										onClick={handleDrawerClose}
									>
										<CloseIcon className={classes.closeIcon} />
									</IconButton>
								</Grid>
							</Grid>
						</>
					}
				</Grid>

				<Grid item xs={12}>
					<Box>
						<Button
							className={classes.goToInboxButton}
							color="primary"
							variant="outlined"
							size="large"
							startIcon={<GoToInboxIcon />}
							component={LinkRouter}
							to={"/attendance"}
							target={deviceIsMobile() ? "_SELF" : "_BLANK"}
						>
							<Box sx={{ ml: 2.25, mr: 1.8 }}>
								<Typography
									color="primary"
								>
									Ir para o Inbox
								</Typography>
							</Box>
						</Button>
					</Box>
				</Grid>

				<ListItemCategory
					title="INÍCIO"
				/>

				<Grid item xs={12}>
					<ListItemMenu
						icon={<DashboardIcon />}
						text="Dashboard"
						to={"/admin/dashboard"}
					/>
				</Grid>

				<Grid item xs={12}>
					<ListItemCategory
						title="WHATSAPP (Oficial)"
					/>
					<ListItemMenu
						icon={<TemplateIcon />}
						text="Templates"
						to={"/admin/templates"}
					/>
				</Grid>

				<ListItemCategory
					title={"CONTATOS"}
				/>

				<Grid item xs={12}>
					<ListItemMenu
						icon={<ClientCatalogIcon />}
						text="Contatos"
						to={"/admin/client-catalog"}
					/>
				</Grid>

				<Grid item xs={12}>
					<ListItemMenu
						icon={<TagIcon />}
						text="Tags"
						to={"/admin/tags"}
					/>
				</Grid>

				<ListItemCategory
					title="AUTOMAÇÕES"
				/>

				<Grid item xs={12}>
					<ListItemMenu
						icon={(
							<SvgIcon
								fontSize="small"
								icon={ChatBotIcon}
							/>
						)}
						text="Bots"
						to={isShowAllChatBotVersions ? "/admin/bots" : "/admin/flow"}
					/>
				</Grid>

				<ListItemCategory
					title="ATENDIMENTO"
				/>

				<Grid item xs={12}>
					<ListItemMenu
						icon={<AttendantsIcon />}
						text="Atendentes"
						to={"/admin/attendant"}
					/>
				</Grid>

				<Grid item xs={12}>
					<ListItemMenu
						icon={<TeamIcon />}
						text="Equipes"
						to={"/admin/teams"}
					/>
				</Grid>

				<Grid item xs={12}>
					<ListItemMenu
						icon={<QuickReplyIcon />}
						text="Respostas Rápidas"
						to={"/admin/quick-reply"}
					/>
				</Grid>

				<ListItemCategory
					title="RELATÓRIOS"
				/>

				<Grid item xs={12}>
					<ListItemMenu
						isNewFeature={true}
						icon={<ReportIcon />}
						text="Relatórios"
						to={"/admin/reports"}
					/>
				</Grid>

				<ListItemCategory
					title="PLUGINS"
				/>

				<Grid item xs={12}>
					<ListItemMenu
						isNewFeature={false}
						icon={<SvgIcon
							icon={ActiveCampaignSVGIcon}
						/>}
						text="Active Campaign"
						to={"/admin/plugins/active-campaign"}
					/>
				</Grid>

				<Grid item xs={12}>
					<ListItemMenu
						isNewFeature={true}
						icon={<MetaLogo width={24} height={24}/>}
						text="Meta Ads"
						to={"/admin/plugins/meta-ads"}
					/>
				</Grid>

				<Grid item xs={12}>
					<ListItemMenu
						isNewFeature={false}
						icon={<SvgIcon
							icon={RDStationSVGIcon}
						/>}
						text="RD Station"
						to={"/admin/plugins/rd-station-marketing"}
					/>
				</Grid>

				<ListItemCategory
					title="MINHA CONTA"
				/>

				<Grid item xs={12}>
					<ListItemMenu
						icon={<MyDataIcon />}
						text="Meus Dados"
						to={"/admin/profile"}
					/>
				</Grid>

				<Grid item xs={12}>
					<ListItemMenu
						icon={<NotificationsIcon />}
						text="Notificações"
						to={"/admin/notifications"}
					/>
				</Grid>

				<Grid item xs={12}>
					<ListItemMenu
						icon={<MegaphoneIcon
							style={{
								padding: 4
							}}
						/>}
						text="Feedbacks"
						to={"/admin/feedback"}
					/>
				</Grid>

				<Grid item xs={12}>
					<ListItemMenu
						icon={<SecurityIcon />}
						text="Segurança"
						to={"/admin/security"}
					/>
				</Grid>
			</Grid>
		</AccessibleDrawer>
	)
}

export default WABASideBar
