import { makeStyles, Grid } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import React from "react"

const useStyles = makeStyles({
	skeleton: {
		transform: "scale(1)"
	}
})

const TagReportsSkeleton = () => {
	const classes = useStyles()

	return (
		<Grid container direction="column" spacing={4}>
			<Grid item>
				<Grid container justifyContent="space-between">
					<Grid item>
						<Grid container direction="row" spacing={2} justifyContent="flex-start">
							<Grid item>
								<Skeleton className={classes.skeleton} height={40} width={230} />
							</Grid>
							<Grid item>
								<Skeleton className={classes.skeleton} height={40} width={176} />
							</Grid>
							<Grid item>
								<Skeleton className={classes.skeleton} height={40} width={176} />
							</Grid>
						</Grid>
					</Grid>
					<Grid item spacing={2}>
						<Grid item>
							<Skeleton className={classes.skeleton} height={40} width={78} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Grid item>
				<Skeleton variant="rect" width="100%" height={600} className={classes.skeleton} />
			</Grid>
		</Grid>
	)
}

export default TagReportsSkeleton
