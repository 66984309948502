import colors from "@/styles/colors"
import newColors from "@/styles/newColors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	scrollBar: {
		maxHeight: 400,
		overflowY: "auto",
		width: "100%",
		margin: "15px 0px"
	},
	centralizedButton: {
		justifyContent: "center",
		alignItems: "center"
	},
	newFeatureItem: {
		fontWeight: "bold",
		cursor: "pointer",
		backgroundColor: colors.unrelated.C3D60D,
		color: colors.grayScale[11],
		marginLeft: 4
	},
	validationDialogCancelButton: {
		padding: theme.spacing(1.25, 4),
		fontSize: theme.spacing(1.75),
		height: theme.spacing(5)
	},
	validationDialogSaveButton: {
		color: newColors.red["500"],
		padding: theme.spacing(1.25, 4),
		backgroundColor: colors.unrelated.D657451A,
		fontSize: theme.spacing(1.75),
		height: theme.spacing(5),
		"&:hover": {
			backgroundColor: colors.unrelated.D6574559
		}
	},
	warningContainer: {
		gap: 24
	},
	picture: {
		width: "60%"
	}
}))

export default useStyles
