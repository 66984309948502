import React, { useState } from "react"

import {
	Grid,
	Tooltip,
	Typography
} from "@material-ui/core"
import {
	InfoOutlined as InfoIcon
} from "@material-ui/icons"

import useActiveCampaignApi from "@/hooks/useActiveCampaignApi"
import useDidMount from "@/hooks/useDidMount"
import useContentEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useContentEditor"

import BlockBodySectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionEditor"
import SelectInput from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/SelectInput"
import { Divider, Loading } from "@/components"

import {
	getCurrentDeal
} from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockContent"

import {
	ActiveCampaignDeal,
	ActiveCampaignPipelineResponse,
	DealGroup,
	DealStage
} from "@/protocols/ActiveCampaignApiProtocol"
import { DeepPartial } from "@/protocols/utility"
import { ChatBotFlowBlockContent } from "@/protocols/chatBot"

import colors from "@/styles/colors"

type DealOptionsSectionEditorProps = {
	onSave: (chatBotFlowBlockContent: ChatBotFlowBlockContent) => void
	chatBotFlowBlockContent: ChatBotFlowBlockContent
	pipelines: Partial<ActiveCampaignPipelineResponse>
	getPipelinesActiveCampaign: () => Promise<void>
}

const DealOptionsSectionEditor: React.FC<DealOptionsSectionEditorProps> = (props) => {
	const {
		onSave,
		chatBotFlowBlockContent,
		pipelines,
		getPipelinesActiveCampaign,
		children
	} = props

	const {
		getPipelineAndStageTitle
	} = useActiveCampaignApi()

	const [loading, setLoading] = useState<boolean>(true)

	const contentEditor = useContentEditor({ chatBotFlowBlockContent })
	const currentDeal = getCurrentDeal(contentEditor.content)

	const {
		pipelineTitle,
		stageTitle
	} = getPipelineAndStageTitle(currentDeal)

	const handleOpen = () => {
		contentEditor.reset()
	}

	const handleSave = () => {
		onSave(contentEditor.content)
	}

	const handleFilterDealGroupsWithDealStages = (groupTitle: string) => {
		const stagesByGroup: DealStage[] = []

		if (groupTitle !== "") {
			const group = {} as DealGroup

			pipelines.dealGroups?.forEach((dealGroup) => {
				if (dealGroup.title === groupTitle) {
					group.stages = dealGroup.stages
					group.title = dealGroup.title
					group.id = dealGroup.id
					group.currency = dealGroup.currency
				}
			})

			group.stages?.forEach(stageId => {
				pipelines.dealStages?.forEach(dealStage => {
					if (dealStage.id === stageId) {
						stagesByGroup.push(dealStage)
					}
				})
			})
		}

		return stagesByGroup
	}

	const handleChangeDealContent = (deal: DeepPartial<ActiveCampaignDeal>) => {
		contentEditor.changeContent({
			deal: deal
		})
	}

	useDidMount(async () => {
		setLoading(true)

		await getPipelinesActiveCampaign()

		setLoading(false)
	})

	return (
		<BlockBodySectionEditor
			title="SELECIONE UM FUNIL DE VENDAS E UMA ETAPA PARA SALVAR O CONTATO"
			onSave={handleSave}
			onOpen={handleOpen}
			AsideDrawerProps={{
				children: (
					<Loading loading={loading} >
						<>
							<Grid
								container
								direction="row"
								spacing={2}
							>
								<Grid
									item
									xs={6}
								>
									<Grid
										container
										direction="column"
										spacing={1}
									>
										<Grid
											item
										>
											<Grid
												container
												alignItems="center"
												spacing={1}
											>
												<Grid
													item
												>
													<Typography variant="h4"> Funil de vendas </Typography>
												</Grid>
												<Grid
													item
												>
													<Grid
														container
														alignItems="center"
													>
														<Tooltip title="Serão exibidos apenas os primeiros 100 negócios retornados pela API do ActiveCampaign.">
															<InfoIcon
																htmlColor={colors.grayScale[3]}
																style={{
																	width: 16,
																	height: 16
																}}
															/>
														</Tooltip>
													</Grid>
												</Grid>
											</Grid>
										</Grid>

										<Grid
											item
										>
											<SelectInput
												defaultOption={{
													title: pipelineTitle
												}}
												onChange={option => {
													handleChangeDealContent({
														pipeline: {
															...option
														},
														stage: {
															title: ""
														}
													})
												}}
												options={[
													...pipelines.dealGroups
												]}
												placeholder="Selecione um Funil de Vendas"
												getOptionSelected={(option, value) => option.title === value.title}
												getOptionLabel={(option) => option.title}
											/>
										</Grid>
									</Grid>
								</Grid>

								<Grid
									item
									xs={6}
								>
									<Grid
										container
									>
										<Typography variant="h4"> Etapa </Typography>

										<Divider orientation="horizontal" size={1}/>

										<SelectInput
											value={{
												title: stageTitle
											}}
											onChange={option => (
												handleChangeDealContent({
													stage: {
														...option
													}
												})
											)}
											options={handleFilterDealGroupsWithDealStages(pipelineTitle)}
											placeholder="Selecione uma Etapa"
											getOptionSelected={(option, value) => option.title === value.title}
											getOptionLabel={(option) => option.title}
										/>
									</Grid>

								</Grid>
							</Grid>
						</>
					</Loading>
				)
			}}
		>
			{children}
		</BlockBodySectionEditor>
	)
}

export default DealOptionsSectionEditor
