import { makeStyles } from "@material-ui/core"
import newColors from "@/styles/newColors"

const useStyles = makeStyles(({
	titleText: {
		fontSize: "32px",
		lineHeight: "24px",
		color: newColors.grey[800]
	},
	subtitleText: {
		fontSize: "16px",
		lineHeight: "24px",
		color: newColors.purple[800]
	},
	tabs: {
		position: "relative",
		"&::before": {
			content: "close-quote",
			position: "absolute",
			width: "100%",
			height: "1px",
			bottom: 0,
			left: 0,
			backgroundColor: newColors.grey[200]
		}
	},
	tabIndicator: {
		backgroundColor: newColors.purple[600]
	},
	tab: {
		color: `${newColors.grey[400]} !important`,
		opacity: 1,
		fontSize: 14
	}
}))

export default useStyles
