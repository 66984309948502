import apiConfig from "@/config/api"
import rdStationMarketingConfig from "@/config/rdStationMarketing"
import { ChatBotFlowBlockType } from "@/protocols/chatBot"
/**
 * Links without specific/unique context
 */
export const externalLinks = {
	merlinInitialSurvey: "https://tools.gomerlin.com.br/quiz/52761ef8-e63b-4fce-82ed-ad67fe23d28f",
	activeCampaignApiDataIntroduction: "https://help.activecampaign.com/hc/pt-br/articles/207317590-Introdu%C3%A7%C3%A3o-%C3%A0-API#how-to-obtain-your-activecampaign-api-url-and-key",
	bumeSignIn: "https://bume.com/public/signin",
	googleFormsNewMetricsResearch: "https://forms.gle/9ccpXkhqAc6XLPqE8",
	tagsReportFeedbackForm: "https://docs.google.com/forms/d/e/1FAIpQLSctKPttvaHHugS9xoy8jbQTlSWbKtUBsmcDWJCLkj6ikPJ2nQ/viewform"
}

export const whatsappLinks = {
	sendMessageToAcquireAditionalUserModule: "https://api.whatsapp.com/send?phone=5531992794500&text=Quero adicionar novos usuários a minha conta",
	sendMessageToAcquireGroupModule: "https://api.whatsapp.com/send?phone=5531992794500&text=Quero incluir o modulo de grupos no meu plano",
	sendMessageToLetalkPhone: "https://web.whatsapp.com/send?phone=5531975436343",
	sendMessageToLetalkSupportPhone: "https://web.whatsapp.com/send?phone=553192794500&text=Estou tendo problema com código de confirmação de login, poderia me ajudar, por favor?",
	termsOfService: "https://www.whatsapp.com/legal/terms-of-service?lang=pt_br",
	sendMessageToMigrateFromPlan: "https://api.whatsapp.com/send?phone=5531992794500&text=Quero migrar de plano",
	sendMessageToAcquireIntegrationModule: "https://api.whatsapp.com/send?phone=5531992794500&text=Quero incluir o modulo de integração no meu plano",
	sendMessageToAcquireMessageBlastModule: "https://api.whatsapp.com/send?phone=5531992794500&text=Quero incluir o modulo de envio em massa no meu plano",
	sendMessageToAcquireChatBotModule: "https://api.whatsapp.com/send?phone=5531992794500&text=Quero incluir o modulo de bot no meu plano",
	sendMessageToAcquireActiveCampaignChatPluginModule: "https://api.whatsapp.com/send?phone=5531992794500&text=Quero incluir o modulo de receber dados do active no meu plano",
	sendMessageToHelpWithUserInvite: "https://api.whatsapp.com/send?phone=5531992794500&text=Estou tendo um problema ao abrir um link de convite de usuário, poderia me ajudar, por favor?",
	sendMessageToAcquireAdditionalAIEnhancementModule: "https://api.whatsapp.com/send?phone=553191115071&text=Quero solicitar otimizações adicionais de texto com IA para a minha conta"
}

export const letalkLinks = {
	inboxEnhancementResearch: "https://docs.google.com/forms/d/e/1FAIpQLScX4syWtZeQBtiFVLHy29AzrZZIYgCop9f2x21aIcma7Ly8JA/viewform?usp=header",
	wikiMainPage: "https://letalk.crunch.help/pt/?utm_source=plataforma&utm_medium=painelgestor",
	siteBecomeAnAffiliate: "https://letalk.com.br/seja-um-afiliado/",
	letalkPlans: "https://letalk.com.br/planos-trial",
	wikiHowToAddBulkContactsOnBot: "https://letalk.crunch.help/pt/chatbot/como-incluir-varios-contatos-em-um-chatbot",
	wikiHowToImportContacts: "https://letalk.crunch.help/pt/contatos-e-grupos/como-importar-contatos-pp-1",
	wikiHowToAddCustomVariablesOnIntegration: "https://letalk.crunch.help/pt/integracoes/como-inserir-variaveis-nas-mensagens-enviadas-via-api?utm_source=Letalk+plataforma&utm_medium=personalizar_variaveis&utm_campaign=Letalk_interno",
	wikiHowToCustomizeMessageBatch: "https://letalk.notion.site/Como-personalizar-mensagens-em-massa-e301091d6ab34e2f8e8f6313f9f69a8c",
	wikiHowToCreateCustomIntegrationChatbot: "https://letalk.crunch.help/pt/chatbot/criando-bot-de-integracao-customizada?utm_source=Letalk+plataforma&utm_medium=bot_customizado&utm_campaign=Letalk_interno",
	wikiHowToCreateCustomField: "https://letalk.crunch.help/pt/contatos-e-grupos/criando-campos-personalizados",
	wikiHowToCreateTag: "https://letalk.crunch.help/pt/contatos-e-grupos/como-criar-e-usar-tags",
	wikiHowToCreateMessageBlast: "https://letalk.crunch.help/pt/envio-em-massa/como-fazer-o-primeiro-envio-em-massa",
	wikiHowToCreateMessageGroupBlast: "https://letalk.crunch.help/pt/envio-em-massa/como-realizar-um-envio-em-massa-para-grupos-1",
	wikiHowToCreateIntegration: "https://letalk.crunch.help/pt/integracoes",
	wikiHowToCreateChatbotFlow: "https://letalk.crunch.help/pt/chatbot",
	wikiHowToRegisteringAttendants: "https://letalk.crunch.help/pt/atendimento/como-cadastrar-atendentes-pp-1",
	wikiHowToCreateTeam: "https://letalk.crunch.help/pt/atendimento/como-criar-uma-equipe",
	wikiHowToCreateQuickReply: "https://letalk.crunch.help/pt/atendimento/como-cadastrar-respostas-rapidas",
	wikiHowToConfigureLetalkToActivePlugin: "https://letalk.crunch.help/pt/integracoes/como-configurar-o-plugin-com-o-active-campaign",
	wikiHowenableDisconnectNotification: "https://letalk.crunch.help/pt/conexao/como-ativar-a-notificacao-de-desconexao-de-numero",
	notionTermsOfService: "https://letalk.com.br/termos-de-uso",
	wikiHotAvoidBlocking: "https://bit.ly/3BwFxMm",
	wikiHowToCreateChatBotWithRDStationByIntegration: "https://letalk.crunch.help/pt/chatbot/criando-chatbot-de-integracao-com-o-rd-station",
	wikiClientTagAssociateTrigger: "https://letalk.crunch.help/pt/chatbot/como-criar-e-utilizar-um-chatbot-acionado-por-tag",
	wikiClientSecurity: "https://letalk.crunch.help/pt/seguranca",
	wikiHowToConfigurePluginWithActiveCampaign: "https://letalk.crunch.help/pt/integracoes/tutorial-como-enviar-mensagens-para-seus-contatos-direto-do-active-campaign-utilizando-a-letalk-atraves-do-plugin-receber-dados-do-active",
	wikiHowToConfigurePluginWithMetaAds: "https://letalk.crunch.help/pt/chatbot/novo-bloco-do-bot-eventos-de-conversao-com-pixel-da-meta",
	wikiWhatHappenAfterImportContacts: "https://letalk.crunch.help/pt/contatos-e-grupos/importei-meus-contatos-na-letalk-e-agora",
	wikiHowToCreateANewSession: "https://letalk.crunch.help/pt/conexao/como-conectar-o-seu-whats-app-na-letalk-2",
	wikiHowToGetWABAInstance: "https://letalk.crunch.help/pt/letalk-waba-api-oficial/desvendando-a-whats-app-business-api-o-que-e-e-como-funciona",
	inboxRecoveryPassword: "https://cp.letalk.com.br/recovery/request"
}

export const videoLinks = {
	activeCampaignTutorial: "https://www.youtube.com/embed/watch?v=ES8Pj7PioOQ",
	directIntegrationTutorial: "https://www.youtube.com/embed/watch?v=WVfjkS1hMKs",
	yampiIntegrationTutorial: "https://www.youtube.com/embed/watch?v=54NtADi2ftU",
	hotmartIntegrationTutorial: "https://www.youtube.com/embed/watch?v=5TUvlIhGB6M",
	hubspotIntegrationTutorial: "",
	metaAdsIntegrationTutorial: ""
}

export const blockLinks = {
	howTheConditionalTextBlockWorks: "https://letalk.crunch.help/pt/chatbot/aprenda-a-fazer-um-fluxo-de-multipla-escolha-no-bot",
	howChatBotTriggersWork: "https://letalk.crunch.help/pt/chatbot/gatilhos-do-chatbot",
	howTheOptinOptoutBlockWorks: "https://letalk.crunch.help/pt/chatbot/bloco-descadastrar"
}

export const howChatBotBlocksWorkLinks: {
	[key in ChatBotFlowBlockType]?: string
} = {
	"send-message": "https://letalk.crunch.help/pt/chatbot/bloco-de-mensagem-de-texto-ou-midia",
	"conditional-text": "https://letalk.crunch.help/pt/chatbot/bloco-de-multipla-escolha",
	"finish-attendance": "https://letalk.crunch.help/pt/chatbot/bloco-de-transferir-atendimento",
	"attendance-schedule": "https://letalk.crunch.help/pt/chatbot/bloco-de-horario-de-atendimento",
	"manage-client-tags": "https://letalk.crunch.help/pt/chatbot/bloco-de-adicionar-ou-remover",
	"validate-and-save-v2": "https://letalk.crunch.help/pt/chatbot/bloco-de-salvar-resposta",
	"go-to-flow": "https://letalk.crunch.help/pt/chatbot/bloco-adicionar-no-bot",
	"send-webhook": "https://letalk.crunch.help/pt/chatbot/bloco-de-enviar-webhook",
	"finish-flow": "https://letalk.crunch.help/pt/chatbot/bloco-de-transferir-atendimento",
	"attendance-transfer": "https://letalk.crunch.help/pt/chatbot/bloco-de-transferir-atendimento",
	repeat: "https://letalk.crunch.help/pt/chatbot/bloco-repetir",
	"optout-optin": "https://letalk.crunch.help/pt/chatbot/bloco-descadastrar",
	wait: "https://letalk.crunch.help/pt/chatbot/bloco-de-mensagem-de-texto-ou-midia",
	condition: "https://letalk.crunch.help/pt/chatbot/bloco-de-condicao",
	"ask-and-wait": "https://letalk.crunch.help/pt/chatbot/bloco-de-mensagem-de-texto-ou-midia",
	"random-path-flow": "https://letalk.crunch.help/pt/chatbot/bloco-dividir",
	"register-tag-to-active-campaign": "https://letalk.crunch.help/pt/chatbot/blocos-do-active-campaign",
	"meta-ads-conversion-event": "https://letalk.crunch.help/pt/chatbot/blocos-do-active-campaign",
	"register-contact-to-active-campaign": "https://letalk.crunch.help/pt/chatbot/blocos-do-active-campaign",
	"register-deal-to-active-campaign": "https://letalk.crunch.help/pt/chatbot/blocos-do-active-campaign"
}

export const rdStationMarketingLinks = {
	rdStationMarketingCallback: `${apiConfig.apiUrl}/plugin-settings/rd-station-marketing/callback`,
	rdStationMarketingAuth: `https://api.rd.services/auth/dialog?client_id=${rdStationMarketingConfig.clientId}`,
	getRdStationMarketingAuthUrl: (callbackUrl: string, stateToken: string) => {
		return `${rdStationMarketingLinks.rdStationMarketingAuth}&redirect_uri=${callbackUrl}&state=${stateToken}`
	}
}

export const celcashLinks = {
	paymentDataPage: "https://cel.cash/landingpage1072886"
}

export const WABALinks = {
	howToResolveBannedOrRestrictedWABAAccount: "https://www.youtube.com/watch?v=g4Q8VS25bzk"
}
