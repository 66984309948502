import generalCodeMessages from "@/utils/responseMessages/generalCodeMessages"
import adminCodeMessages from "@/utils/responseMessages/adminCodeMessages"
import groupCodeMessages from "@/utils/responseMessages/groupCodeMessages"
import importContactsCodeMessages from "@/utils/responseMessages/importContactsCodeMessages"
import customFieldCodeMessages from "@/utils/responseMessages/customFieldCodeMessages"
import wabaTemplateCodeMessages from "@/utils/responseMessages/wabaTemplateCodeMessages"
import metaAdsPluginCodeMessages from "@/utils/responseMessages/metaAdsPluginCodeMessages"

export default {
	...generalCodeMessages,
	...adminCodeMessages,
	...groupCodeMessages,
	...importContactsCodeMessages,
	...customFieldCodeMessages,
	...wabaTemplateCodeMessages,
	...metaAdsPluginCodeMessages
}
