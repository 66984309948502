import React, { useState } from "react"
import useDidMount from "@/hooks/useDidMount"

import {
	Grid, Tooltip,
	Typography
} from "@material-ui/core"

import {
	Devices as DeviceIcon, InfoOutlined as InfoIcon
} from "@material-ui/icons"

import {
	AlertContainer,
	Loading
} from "@/components"

import DeviceItem, { Device } from "@/pages/Admin/Security/DevicesAndSessions/DeviceItem"

import ErrorHandlerService from "@/services/ErrorHandler"
import ApiService from "@/services/Api"

import DeviceAndSessionsSkeleton from "@/skeletons/Admin/DeviceAndSessionsSkeleton"

import newColors from "@/styles/newColors"
import useStyles from "@/pages/Admin/Security/DevicesAndSessions/styles"
import colors from "@/styles/colors"

type DeviceList = {
	devicesWithInactiveSessions: Array<Device>,
	devicesWithActiveSessions: Array<Device>
}

type DevicesAndSessionsProps = {
	onReload?: () => void
}

const DevicesAndSessions: React.FC<DevicesAndSessionsProps> = (props) => {
	const [devices, setDevices] = useState<DeviceList>({} as DeviceList)
	const [loading, setLoading] = useState<boolean>(false)

	const isShowScreenWithoutDevices = devices.devicesWithActiveSessions?.length === 0 && devices.devicesWithInactiveSessions?.length === 0

	const handleGetDevices = async () => {
		setLoading(true)

		try {
			const {
				data
			} = await ApiService.get("/sessions")

			setDevices(data.devices)
		} catch (error) {
			ErrorHandlerService.handle(error)
		}

		setLoading(false)
	}

	const classes = useStyles()

	const getActiveDevices = () => {
		return (
			<>
				{devices.devicesWithActiveSessions?.length === 0 ? (
					<Grid
						container
						justifyContent="center"
						alignItems="center"
						spacing={2}
					>
						<Grid
							item
						>
							<Typography
								variant="body1"
								style={{
									opacity: 0.5
								}}
							>
								No momento, não há dispositivos disponíveis para exibição.
							</Typography>
						</Grid>
					</Grid>
				) : (
					<Grid
						container
						direction="column"
						spacing={1}
					>
						{devices.devicesWithActiveSessions?.map((device, index) => (
							<Grid
								key={index}
								item
							>
								<DeviceItem
									device={device}
									onReload={props?.onReload}
								/>
							</Grid>
						))}
					</Grid>
				)}
			</>
		)
	}

	const getInactiveDevices = () => {
		return (
			<>
				{devices.devicesWithInactiveSessions?.length === 0 ? (
					<Grid
						container
						justifyContent="center"
						alignItems="center"
						spacing={2}
					>
						<Grid
							item
						>
							<Typography
								variant="body1"
								style={{
									opacity: 0.5
								}}
							>
								No momento, não há dispositivos disponíveis para exibição.
							</Typography>
						</Grid>
					</Grid>
				) : (
					<Grid
						container
						direction="column"
					>
						{devices.devicesWithInactiveSessions?.map((device, index) => (
							<Grid
								key={index}
								item
								style={{
									maxHeight: "120px"
								}}
							>
								<DeviceItem
									device={device}
									onReload={props?.onReload}
								/>
							</Grid>
						))}
					</Grid>
				)}
			</>
		)
	}

	useDidMount(() => {
		handleGetDevices()
	})

	return (
		<Loading loading={loading} customLoadingElement={<DeviceAndSessionsSkeleton />}>
			<Grid
				container
				direction="column"
				spacing={3}
			>
				<Grid
					item
				>
					<AlertContainer
						alertType="info"
						title="Gerenciamento de Dispositivos e Sessões na Letalk"
						icon={
							<DeviceIcon
								className={classes.deviceIcon}
								fontSize="large"
							/>
						}
						customStyles={{
							info: {
								containerBackgroundColor: newColors.grey[0]
							}
						}}
					>
						<Typography
							className={classes.alertText}
						>
							Essa aba lista os dispositivos vinculados à conta na Letalk, incluindo cadastrados e
							com sessões ativas. Dispositivos cadastrados são reconhecidos como seguros após o primeiro
							login com MFA, permitindo acessos futuros sem nova autenticação.
						</Typography>
					</AlertContainer>
				</Grid>

				{isShowScreenWithoutDevices ? (
					<Grid
						item
					>
						<Grid
							container
							justifyContent="center"
							alignItems="center"
							style={{
								padding: 20
							}}
						>
							<Typography
								variant="body1"
								style={{
									opacity: 0.5
								}}
							>
								No momento, não há dispositivos disponíveis para exibição.
							</Typography>
						</Grid>
					</Grid>
				) : (
					<Grid
						item
					>
						<Grid
							container
							direction="column"
							spacing={4}
						>
							<Grid
								item
								xs={12}
							>
								<Grid
									container
									direction="column"
									spacing={2}
								>
									<Grid
										item
									>
										<Grid
											container
											alignItems="center"
											spacing={1}
										>
											<Grid
												item
											>
												<Typography
													variant="h4"
													className={classes.sessionTitle}
												>
													Dispositivos com Sessões
												</Typography>
											</Grid>

											<Grid
												item
											>
												<Tooltip
													title={"Dispositivos com Sessões são aqueles nos quais um usuário atualmente está logado e utilizando a conta."}
												>
													<InfoIcon
														htmlColor={colors.grayScale[3]}
														style={{
															width: 14,
															height: 14
														}}
													/>
												</Tooltip>
											</Grid>
										</Grid>
									</Grid>

									<Grid
										item
									>
										{getActiveDevices()}
									</Grid>
								</Grid>
							</Grid>

							<Grid
								item
								xs={12}
							>
								<Grid
									container
									direction="column"
									spacing={2}
								>
									<Grid
										item
									>
										<Grid
											container
											alignItems="center"
											spacing={1}
										>
											<Grid
												item
											>
												<Typography
													variant="h4"
													className={classes.sessionTitle}
												>
													Dispositivos sem Sessões
												</Typography>
											</Grid>

											<Grid
												item
											>
												<Tooltip
													title={"Dispositivos sem Sessões são aqueles onde a sessão do usuário expirou ou foi encerrada, exigindo um novo login para acesso."}
												>
													<InfoIcon
														htmlColor={colors.grayScale[3]}
														style={{
															width: 14,
															height: 14
														}}
													/>
												</Tooltip>
											</Grid>
										</Grid>
									</Grid>

									<Grid
										item
									>
										{getInactiveDevices()}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				)}
			</Grid>
		</Loading>
	)
}

export default DevicesAndSessions
