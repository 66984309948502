import React from "react"
import { Grid, Select, MenuItem, Typography } from "@material-ui/core"
import { ptBR } from "date-fns/locale"
import DateFnsUtils from "@date-io/date-fns"
import { KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"

import { parseBetweenWeekAndTimeValidation } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockRuleValidation"

import { Divider } from "@/components"

import {
	transformHourAndMinutesToDate,
	transformDateIntoHourAndMinutes,
	translatedDaysOfWeek,
	isValidDate
} from "@/utils/time"

import { ChatBotFlowBlockRuleValidationSlug } from "@/protocols/chatBot"

import { AvailableValidationInputValue } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/GenericConditionSectionEditor"
import colors from "@/styles/colors"

const BetweenWeekAndTimeInput: AvailableValidationInputValue = {
	title: "",
	defaultFirstParamValue: "",
	validationRender: ({ validation, onChangeValidation }) => {
		const {
			fromHourAndMinutesValue,
			toHourAndMinutesValue,
			weekDayValue
		} = parseBetweenWeekAndTimeValidation(validation)

		const handleChange = (deepValidationSlug: ChatBotFlowBlockRuleValidationSlug, value: string) => {
			onChangeValidation({
				deep_validations: validation.deep_validations?.map(deepValidation => {
					if (deepValidation.slug === deepValidationSlug) {
						deepValidation.second_param = value
					}

					return deepValidation
				})
			})
		}

		return (
			<Grid
				container
			>
				<Select
					value={weekDayValue || ""}
					variant="outlined"
					onChange={(event) => handleChange("week-day", event.target.value as string)}
					fullWidth
					displayEmpty
					style={{ flex: 1, width: 126 }}
				>
					{Object.entries(translatedDaysOfWeek).map(([weekDay, weekName]) => (
						<MenuItem
							key={weekDay}
							value={weekDay}
						>
							{weekName}
						</MenuItem>
					))}

					<MenuItem
						disabled
						value=""
					>
						<Typography
							variant="body1"
							style={{
								lineHeight: "unset",
								color: colors.unrelated.ADADAD
							}}
						>
							Dia da semana
						</Typography>
					</MenuItem>
				</Select>

				<Divider orientation="vertical" size={1} />

				<MuiPickersUtilsProvider
					locale={ptBR}
					utils={DateFnsUtils}
				>
					<Grid style={{ width: 132 }} >
						<KeyboardTimePicker
							okLabel="Pronto"
							cancelLabel="Cancelar"
							ampm={false}
							value={transformHourAndMinutesToDate(fromHourAndMinutesValue)}
							inputVariant="outlined"
							onChange={(date) => isValidDate(date as Date) && handleChange("from-hour-and-minutes", transformDateIntoHourAndMinutes(date as Date))}
							placeholder="De"
						/>
					</Grid>
				</MuiPickersUtilsProvider>

				<Divider orientation="vertical" size={1} />

				<MuiPickersUtilsProvider
					locale={ptBR}
					utils={DateFnsUtils}
				>
					<Grid style={{ width: 132 }} >
						<KeyboardTimePicker
							okLabel="Pronto"
							cancelLabel="Cancelar"
							ampm={false}
							value={transformHourAndMinutesToDate(toHourAndMinutesValue)}
							inputVariant="outlined"
							onChange={(date) => isValidDate(date as Date) && handleChange("to-hour-and-minutes", transformDateIntoHourAndMinutes(date as Date))}
							placeholder="Até"
						/>
					</Grid>
				</MuiPickersUtilsProvider>
			</Grid>
		)
	},
	types: ["deep-validation"]
}

export default BetweenWeekAndTimeInput
