import React, { useState } from "react"
import {
	Typography,
	TextField,
	Grid
} from "@material-ui/core"

import ApiService from "@/services/Api"
import HardCoded from "@/services/HardCoded"

import useValidation, { ErrorType } from "@/hooks/useValidation"
import { InstanceDataProps, useGlobalStateStore } from "@/store/GlobalState"

import { ActionDialog, Notification, Portlet } from "@/components"
import InfoItemInput from "@/components/InfoItemInput"
import PhoneInputComponent, { OnChangePhoneInputParams } from "@/components/PhoneInput"

import useCustomStyles from "@/styles/custom"

type EditableInstanceDataProps = Pick<InstanceDataProps, "nickname"> & {
	responsible_phone_number: OnChangePhoneInputParams
}

const InstanceDataPortlet = () => {
	const globalStateStore = useGlobalStateStore()
	const instanceStore = globalStateStore.instance
	const userInstances = globalStateStore.user.user_instances

	const [nickname, setNickname] = useState<EditableInstanceDataProps["nickname"]>(instanceStore?.nickname)
	const [responsiblePhoneNumber, setResponsiblePhoneNumber] = useState<EditableInstanceDataProps["responsible_phone_number"]>({
		countryCode: "BR",
		formattedPhone: instanceStore?.responsible_phone_number || "",
		rawPhone: instanceStore?.responsible_phone_number || ""
	})

	const [openNicknameDialog, setOpenNicknameDialog] = useState<boolean>(false)
	const [openResponsiblePhoneNumberDialog, setOpenResponsiblePhoneNumberDialog] = useState<boolean>(false)

	const [loading, setLoading] = useState<boolean>(false)

	const customClasses = useCustomStyles()

	const {
		validation,
		triggerValidation,
		clearValidation
	} = useValidation()

	const isInstanceOwner = globalStateStore.user.is_instance_owner

	const handleCloseResponsiblePhoneNumberDialog = (): void => {
		setOpenResponsiblePhoneNumberDialog(false)
	}

	const handleOpenResponsiblePhoneNumberDialog = (): void => {
		setOpenResponsiblePhoneNumberDialog(true)
	}

	const handleCloseNicknameDialog = (): void => {
		setOpenNicknameDialog(false)
	}

	const handleOpenNicknameDialog = (): void => {
		setOpenNicknameDialog(true)
	}

	const handleChangeNicknameData = (nickname: EditableInstanceDataProps["nickname"]) => {
		const maxSizeNickname = 40
		const sanitizedNickname = nickname?.slice(0, maxSizeNickname)

		setNickname(sanitizedNickname)
		clearValidation("nickname")
	}

	const handleChangeResponsiblePhoneNumberData = (responsiblePhoneNumber: EditableInstanceDataProps["responsible_phone_number"]) => {
		setResponsiblePhoneNumber(responsiblePhoneNumber)
		clearValidation("responsible_phone_number")
	}

	const updateNicknameInGlobalStateAndDB = async (): Promise<void> => {
		await ApiService.put("/instance", {
			nickname
		})

		const updatedInstances = userInstances.map(instance => {
			if (instance.instance_id === instanceStore.instance_id) {
				return {
					...instance,
					nickname
				}
			}
			return instance
		})

		globalStateStore.setUserData({
			user_instances: updatedInstances
		})

		globalStateStore.setInstanceData({
			nickname
		})
	}

	const handleSaveNicknameData = async (): Promise<void> => {
		setLoading(true)
		try {
			await updateNicknameInGlobalStateAndDB()

			Notification.success({ message: `Apelido da instância ${instanceStore.instance_id} alterada com sucesso!` })
			handleCloseNicknameDialog()
		} catch (error) {
			triggerValidation(error as ErrorType)
		}
		setLoading(false)
	}

	const handleSaveResponsiblePhoneNumberData = async (): Promise<void> => {
		setLoading(true)
		try {
			await ApiService.put("/instance", {
				responsiblePhoneNumber,
				nickname: instanceStore.nickname
			})

			globalStateStore.setInstanceData({
				responsible_phone_number: responsiblePhoneNumber?.formattedPhone
			})

			Notification.success({ message: "Telefone do responsável pela instância alterado com sucesso!" })
			handleCloseResponsiblePhoneNumberDialog()
		} catch (error) {
			triggerValidation(error as ErrorType)
		}
		setLoading(false)
	}

	return (
		<Grid
			container
			spacing={1}
			direction="column"
		>
			<Grid
				item
				xs={12}
			>
				<Typography
					variant="h4"
					color="textPrimary"
					className={customClasses.uppercase}
				>
					Informações da instância
				</Typography>
			</Grid>
			<Grid
				item
				xs={12}
			>
				<Portlet>
					<Grid
						container
						direction="column"
					>
						<Grid
							item
						>
							<InfoItemInput
								title={"Apelido da instância"}
								disableEdit={!isInstanceOwner}
								content={instanceStore.nickname}
								onEdit={handleOpenNicknameDialog}
							/>
						</Grid>
						{
							HardCoded.checkFeatureFlag("instanceResponsiblePhoneNumber") && (
								<Grid
									item
									xs={12}
								>
									<InfoItemInput
										title={"Número de telefone do responsável"}
										disableEdit={false}
										content={instanceStore.responsible_phone_number}
										onEdit={handleOpenResponsiblePhoneNumberDialog}
									/>
								</Grid>

							)
						}
					</Grid>
				</Portlet>
			</Grid>

			<ActionDialog
				title={`Alterar apelido da instância ${instanceStore.instance_id}`}
				openDialog={openNicknameDialog}
				fullWidth
				loading={loading}
				onClose={handleCloseNicknameDialog}
				onSave={handleSaveNicknameData}
			>
				<TextField
					placeholder={`Apelido da instância ${instanceStore.instance_id}`}
					value={nickname}
					onChange={({ target }) => handleChangeNicknameData(target.value)}
					variant="outlined"
					color="primary"
					multiline
					fullWidth
					error={!!validation.nickname}
					helperText={validation.nickname}
				/>
			</ActionDialog>

			<ActionDialog
				title={"Alterar telefone do responsável"}
				openDialog={openResponsiblePhoneNumberDialog}
				fullWidth
				loading={loading}
				onClose={handleCloseResponsiblePhoneNumberDialog}
				onSave={handleSaveResponsiblePhoneNumberData}
			>
				<PhoneInputComponent
					placeholder={"Telefone do responsável"}
					value={responsiblePhoneNumber?.formattedPhone}
					onChange={(phoneInput) => handleChangeResponsiblePhoneNumberData(phoneInput)}
					validation={validation.responsible_phone_number}
					country={"br"}
					preferredCountries={["br", "pt", "us"]}
				/>
			</ActionDialog>
		</Grid>

	)
}

export default InstanceDataPortlet
