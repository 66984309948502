import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import {
	Avatar,
	Box,
	Chip,
	Grid,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Tooltip,
	Typography
} from "@material-ui/core"

import {
	Person,
	Delete as TrashIcon
} from "@material-ui/icons"
import {
	ReactComponent as ChatBotIcon
} from "@/assets/icons/chat_bot.svg"

import ContactAction from "@/components/ContactAction"
import {
	Divider,
	Loading,
	LoadingOverlay,
	Notification,
	Portlet,
	TypographyListGroup
} from "@/components"
import Breadcrumb from "@/components/BreadcrumbNew"

import { Tag } from "@/protocols/tag"
import {
	Client,
	ClientCatalogPagination
} from "@/protocols/clientCatalog"

import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"
import TagService from "@/services/Tag"
import HardCoded from "@/services/HardCoded"

import useDidMount from "@/hooks/useDidMount"
import useValidation, { ErrorType } from "@/hooks/useValidation"
import useDebounce from "@/hooks/useDebounce"
import useQueryFilters from "@/hooks/useQueryFilters"
import useSubscriptionLimits from "@/hooks/useSubscriptionLimits"

import ClientManageContactsHeader from "@/pages/Admin/ClientCatalog/ClientManageContactsHeader"
import ClientManageContacts from "@/pages/Admin/ClientCatalog/ClientManageFilters"
import { ContactsFiltersType } from "@/pages/Admin/ClientCatalog/ClientManageFilters/ContactsFilters"
import ClientProfileInfo, { OnDataChangeFn } from "@/pages/Admin/ClientCatalog/ClientProfileInfo"
import ClientCatalogQualifyMetrics from "@/pages/Admin/ClientCatalog/ClientCatalogQualifyMetrics"
import DisableContactDialog from "@/pages/Admin/ClientCatalog/DisableContactDialog"

import { isSmallScreen } from "@/utils/checkDevice"
import { convertRGBAObjectToString } from "@/utils/color"
import { formatPhoneNumber } from "@/utils/mask"
import { getRowsLabel } from "@/utils/table"
import { DEFAULT_WHERE_DATA } from "@/utils/pagination"
import { getPhoneContact, getPhoneNumber } from "@/utils/contact"

import ClientCatalogTableSkeleton from "@/skeletons/Admin/ClientCatalogTable"

import colors from "@/styles/colors"
import useStyles from "@/pages/Admin/ClientCatalog/styles"
import useCustomStyles from "@/styles/custom"

export type ClientCatalogWhereData = {
	page: number
	rowsPerPage: number
	search?: string
	searchTags?: string[]
	contactsFilters?: unknown[]
}
type ClientContactActionData = {
	clientId: number
	contactId?: number
	name?: string
}

const ClientCatalogPage: React.FC = () => {
	const [loadingClientCatalog, setLoadingClientCatalog] = useState(true)
	const [clientCatalog, setClientCatalog] = useState<ClientCatalogPagination>({} as ClientCatalogPagination)
	const [whereData, setWhereData] = useState<ClientCatalogWhereData>(DEFAULT_WHERE_DATA)
	const [customerTags, setCustomerTags] = useState<Tag[]>([])
	const [selectedContactsFilter, setSelectedContactsFilter] = useState<ContactsFiltersType>({} as ContactsFiltersType)
	const { triggerValidation } = useValidation()
	const queryFilter = useQueryFilters()
	const history = useHistory()
	const [contactActionData, setContactActionData] = useState<ClientContactActionData>()
	const [openContactAction, setOpenContactAction] = useState<boolean>(false)

	const contactActionSubscriptionData = useSubscriptionLimits("permission", "bulk_contact_action")
	const muskBlockContactAction = contactActionSubscriptionData.mustBlock
	const contactsCount = clientCatalog.rows?.length > 0

	const setupInitialContactNameSearch = async (): Promise<string | null> => {
		const nickNameSearch = queryFilter.getQueryParamSearch("nickname")
		history.replace({ search: queryFilter.query.toString() })

		return nickNameSearch
	}

	const getAndUpdateClientCatalogData = async (newWhereData?: Partial<ClientCatalogWhereData>) => {
		setLoadingClientCatalog(true)
		try {
			const whereParams = newWhereData

			const nickNameSearch = await setupInitialContactNameSearch()

			const updatedWhereData: ClientCatalogWhereData = {
				...whereData,
				search: nickNameSearch || whereData?.search || ""
			}

			setWhereData({
				...updatedWhereData,
				...(whereParams && whereParams)
			})

			const { data } = await ApiService.get("/clients", {
				params: {
					...whereData,
					...whereParams,
					contactType: "user"
				}
			})

			setClientCatalog({
				clientCatalog: data.clientCatalog,
				count: data.count,
				rows: data.rows
			})
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)

			const isSearchTextHaveLessFourCharacters = (error as ErrorType)?.response?.data?.codeMessages?.search === "number_of_characters_less_than_three"

			if (isSearchTextHaveLessFourCharacters) {
				Notification.warning({
					message: "Você deve inserir mais de 3 caracteres."
				})
			}

			triggerValidation(error as ErrorType)
		}

		setLoadingClientCatalog(false)
	}

	const onDataChange: OnDataChangeFn = (id, data) => {
		const updatedClients = clientCatalog?.rows?.map(client => {
			if (client.id === id) {
				if (data.contacts?.email) {
					client.contacts = client.contacts?.map((contact) => {
						if (contact.channel_type === "email") {
							contact.data.email = data.contacts?.email as string
						}
						return contact
					})
				}
				if ("optIn" in data) {
					client.accepts_automatic_messages = data.optIn as boolean
				}
				if ("nickname" in data) {
					client.nickname = data.nickname as string
				}
				if ("tags" in data) {
					client.tags = data.tags
				}
			}

			return client
		})
		const updatedClientCatalog = {
			...clientCatalog,
			rows: updatedClients
		} as ClientCatalogPagination

		setClientCatalog(updatedClientCatalog)
	}
	const getAndUpdateCustomerTags = async () => {
		const data = await TagService.getTags()

		if (data) {
			setCustomerTags(data)
		}
	}
	const handleWhereDataChange = (newData: Partial<ClientCatalogWhereData>) => {
		setWhereData((currentState) => (
			{
				...currentState,
				...newData
			}))
	}
	const handlePageChange = async (event: unknown, page: number) => {
		handleWhereDataChange({
			page
		})

		await getAndUpdateClientCatalogData({ page })
	}

	const handleChangeRowsPerPage = async (rowsPerPage: number) => {
		handleWhereDataChange({
			rowsPerPage
		})
		await getAndUpdateClientCatalogData({ rowsPerPage })
	}

	const openSendContactToBot = async (client: Client) => {
		const contact = getPhoneContact(client)

		setOpenContactAction(true)
		setContactActionData({
			clientId: client.id,
			contactId: contact?.id,
			name: client.name || client.nickname
		})
	}

	useDebounce(
		async () => {
			getAndUpdateClientCatalogData({ search: whereData?.search })
		},
		whereData.search,
		2250
	)

	useDidMount(() => {
		getAndUpdateClientCatalogData()
		getAndUpdateCustomerTags()
	})

	const classes = useStyles()
	const customClasses = useCustomStyles()
	return (
		<>
			<Breadcrumb
				data={[
					{ name: "Contatos", pathname: "/admin/client-catalog" }
				]}
			/>

			<Divider orientation="horizontal" size={2} />
			<ClientManageContactsHeader
				selectedContactsFilter={selectedContactsFilter}
				setClientCatalog={setClientCatalog} />
			<Divider size={3} orientation="horizontal" />

			<ClientCatalogQualifyMetrics />

			<Divider size={3} orientation="horizontal" />

			<Portlet
				elevation={1}
			>

				<ClientManageContacts
					setWhereData={setWhereData}
					whereData={whereData}
					hasContactsOnCurrentFilter={Boolean(contactsCount)}
					getAndUpdateClientCatalogData={getAndUpdateClientCatalogData}
					setClientCatalog={setClientCatalog}
					setSelectedContactsFilter={setSelectedContactsFilter}
					selectedContactsFilter={selectedContactsFilter}
				/>

				<Grid item xs={12}>
					<Loading
						customLoadingElement={<ClientCatalogTableSkeleton />}
						loading={loadingClientCatalog}
					>
						{
							clientCatalog.count > 0 ? (
								<LoadingOverlay
									loading={loadingClientCatalog}
								>
									<Paper className={classes.paper}>
										<TableContainer>
											<Table stickyHeader size="small">
												<TableHead>
													<TableRow>
														<TableCell
															width="300px"
														>
															Cliente
														</TableCell>

														<TableCell
															width="220px"
														>
															WhatsApp
														</TableCell>

														<TableCell
														>
															Tags
														</TableCell>
														<TableCell
															width="292x"
															style={{ textAlign: "center" }}
														>
															Receber mensagem automática?
														</TableCell>

														<TableCell
															style={{ padding: 0, width: 192 }}
														/>
													</TableRow>
												</TableHead>

												<TableBody
													className={customClasses.reportTableBodyText}
												>
													{clientCatalog.rows.map(client => {
														return (
															<TableRow key={client.id} tabIndex={-1}>
																<TableCell>
																	<Grid container justify="flex-start" alignItems="center" >
																		<Grid item>
																			<Avatar src={client.picture_url} />
																		</Grid>

																		<Divider size={isSmallScreen ? 1 : 2.5} orientation={isSmallScreen ? "horizontal" : "vertical"} />

																		<Grid item xs>
																			<TypographyListGroup>
																				<Typography variant="h4">
																					{client.nickname}
																				</Typography>
																				<Typography style={{ color: colors.unrelated["818285"], fontWeight: 400 }}>
																					~{client.name}
																				</Typography>
																			</TypographyListGroup>
																		</Grid>
																	</Grid>
																</TableCell>

																<TableCell width="185px">
																	<Typography style={{ fontSize: 16 }}>
																		{formatPhoneNumber(getPhoneNumber(client))}
																	</Typography>
																</TableCell>

																<TableCell>
																	<Grid container direction="row" wrap="wrap" spacing={1}>
																		{client.tags?.map(tag => {
																			return (
																				<Grid item key={tag.id}>
																					<Tooltip title={tag.name}>
																						<Chip
																							style={{
																								backgroundColor: convertRGBAObjectToString(tag.color),
																								height: 12,
																								width: 64
																							}}
																							size="small"
																						/>
																					</Tooltip>
																				</Grid>
																			)
																		})}
																	</Grid>
																</TableCell>

																<TableCell>
																	<Grid item style={{ display: "flex", justifyContent: "center" }}>
																		<Box className={classes.clientAutomaticMessages} style={{ backgroundColor: client.accepts_automatic_messages ? colors.unrelated.DCF5D9 : colors.unrelated.F8DAD3 }}>
																			{client.accepts_automatic_messages ? "Sim" : "Não"}
																		</Box>
																	</Grid>
																</TableCell>

																<TableCell>
																	<Grid
																		container
																		justifyContent="flex-end"
																		alignItems="baseline"
																		direction="row"
																	>
																		<Grid item >
																			<ClientProfileInfo
																				customerTags={customerTags}
																				onDataChange={onDataChange}
																				clientId={client.id}
																				onUpdateTags={getAndUpdateCustomerTags}
																			>
																				<IconButton>
																					<Person />
																				</IconButton>
																			</ClientProfileInfo>
																		</Grid>
																		<Grid item>
																			<Tooltip
																				disableHoverListener={!muskBlockContactAction}
																				title={"Essa opção esta disponível apenas para o plano avançado."}
																			>
																				<Grid item>
																					<IconButton
																						disabled={muskBlockContactAction}
																						onClick={() => openSendContactToBot(client)}>
																						<ChatBotIcon style={{ width: 22, height: 22 }} />
																					</IconButton>
																				</Grid>
																			</Tooltip>
																		</Grid>
																		{HardCoded.checkFeatureFlag("canDeleteContact") && (
																			<Grid item>
																				<DisableContactDialog
																					type="single-contact"
																					disabled={false}
																					client={client}
																				>
																					<IconButton>
																						<TrashIcon style={{ width: 22, height: 22 }} />
																					</IconButton>
																				</DisableContactDialog>
																			</Grid>
																		)}
																	</Grid>
																</TableCell>
															</TableRow>
														)
													})}
												</TableBody>
											</Table>
										</TableContainer>

										<TablePagination
											rowsPerPageOptions={[20, 50, 100, 200]}
											component="div"
											count={clientCatalog?.count}
											rowsPerPage={whereData.rowsPerPage}
											page={whereData.page}
											onPageChange={handlePageChange}
											onRowsPerPageChange={({ target }) => {
												handlePageChange(target, 0)
												handleChangeRowsPerPage(+target.value)
											}}
											labelRowsPerPage={"Resultados por página:"}
											labelDisplayedRows={tableData => getRowsLabel(tableData, whereData.rowsPerPage)}
										/>
									</Paper>
								</LoadingOverlay>
							) : (
								<>
									<Divider size={3} orientation="horizontal" />
									<Typography align="center" variant="h2">
										Nenhum resultado encontrado
									</Typography>
								</>
							)
						}
					</Loading>
				</Grid>
				<ContactAction
					contactAction="single-contact"
					client={contactActionData}
					hideOpenButton
					open={openContactAction}
					setOpen={setOpenContactAction}
				/>
			</Portlet >
		</>
	)
}

export default ClientCatalogPage
