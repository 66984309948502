import React, { ComponentType } from "react"
import { NodeProps } from "reactflow"
import { BlockNodeData } from "@/protocols/chatBotFlow"

import useChatBotFlowBlockDetails from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowBlockDetails"

import BaseBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BaseBlock"
import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import BlockBodySectionContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionContainer"
import { Chip, Grid, Typography } from "@material-ui/core"
import { Divider, ProductReportActionButtonGroup } from "@/components"

import { getCurrentMetaAdsEventName } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockContent"
import MetaAdsEventConversionSectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/SendMetaAdsEventConversionBlock/MetaAdsEventConversionSectionEditor"
import { Alert } from "@material-ui/lab"
import useMetaAdsApi from "@/hooks/useMetaAdsApi"
import useDidMount from "@/hooks/useDidMount"
import AfterActionConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/AfterActionConditionSection"
import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/SendMetaAdsEventConversionBlock/styles"

const SendMetaAdsEventConversionBlock: ComponentType<NodeProps<BlockNodeData>> = (node) => {
	const chatBotFlowBlockDetails = useChatBotFlowBlockDetails({
		chatBotFlowBlockId: node.data.chatBotFlowBlockId
	})

	const {
		isEnabledPlugin,
		hasEnabledPlugin
	} = useMetaAdsApi()

	const classes = useStyles()
	const getConversionEventName = () => {
		const conversionEventName = getCurrentMetaAdsEventName(chatBotFlowBlockDetails.content)

		if (conversionEventName) {
			return (
				<Chip
					label={conversionEventName}
					size="small"
					className={classes.eventName}
				/>
			)
		} else {
			return null
		}
	}

	useDidMount(async () => {
		await hasEnabledPlugin()
	})

	return (
		<BaseBlock node={node}>
			<BlockBodySection fullWidth>
				{!isEnabledPlugin &&
				<>
					<Alert severity="warning" style={{ alignItems: "center" }}>
						<Grid container xs={12} spacing={1} justifyContent="space-between" alignItems="center">
							<Grid item xs={9}>
								<Typography style={{ fontSize: 13, lineHeight: "20px" }}>
									O plugin do Meta Ads não está configurado corretamente
								</Typography>
							</Grid>
							<Grid item xs={3} alignItems="center">
								<ProductReportActionButtonGroup
									refresh={{
										tooltip: "Atualizar dados do Plugin",
										onClick: async () => { hasEnabledPlugin() }
									}}
								/>
							</Grid>
						</Grid>
					</Alert>
					<Divider orientation="horizontal" size={2} />
				</>
				}

				<MetaAdsEventConversionSectionEditor
					onSave={content => chatBotFlowBlockDetails.changeContent(content)}
					chatBotFlowBlockContent={chatBotFlowBlockDetails.content}
				>
					<BlockBodySectionContainer
						text={getConversionEventName()}
						placeholder="Nenhum evento selecionado"
						title="Nome do evento"
						className={classes.conversionEventName}
						chatBotFlowBlockId={node?.data?.chatBotFlowBlockId}
						errorType="active_campaign_missing_tag"
					/>
				</MetaAdsEventConversionSectionEditor>
			</BlockBodySection>
			<BlockBodySection>
				<AfterActionConditionSection
					nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
				/>
			</BlockBodySection>
		</BaseBlock>
	)
}

export default SendMetaAdsEventConversionBlock
