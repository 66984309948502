import React, { useState, useEffect, useRef } from "react"
import { useHistory, useParams } from "react-router-dom"
import {
	Typography,
	Grid,
	Tab,
	Tabs,
	TextField,
	Button,
	Box,
	Tooltip
} from "@material-ui/core"

import {
	Edit as EditIcon,
	Info as InfoIcon,
	Delete as DeleteIcon,
	CalendarToday as ScheduleActionIcon,
	Send as SendIcon,
	Close as CancelScheduleIcon
} from "@material-ui/icons"

import {
	Divider,
	Loading,
	Notification,
	InfoDialog,
	PopConfirm,
	ActionDialog
} from "@/components"

import useDidMount from "@/hooks/useDidMount"
import useStyles from "@/pages/Admin/MessageBlast/Management/styles"

import WhatsappConnectionFlow from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow"

import ErrorHandler from "@/services/ErrorHandler"
import ApiService from "@/services/Api"

import MessageBlastManagementSkeleton from "@/skeletons/Admin/MessageBlastManagement"
import {
	MessageBlastContact,
	MessageBlastContactToAdd,
	MessageBlastData
} from "@/protocols/messageBlast"
import Contacts, { ContactsWhereData, CONTACTS_DEFAULT_WHERE_DATA } from "@/pages/Admin/MessageBlast/Management/Contacts"
import Messages from "@/pages/Admin/MessageBlast/Management/Messages"
import FireMessagesDialog from "@/pages/Admin/MessageBlast/Management/FireMessagesDialog"
import Report, {
	ClientMessageBlastWhereData,
	CLIENT_MESSAGE_BLAST_DEFAULT_WHERE_DATA,
	PaginatedClientMessageBlast
} from "@/pages/Admin/MessageBlast/Management/Report"

import { getErrorName } from "@/utils/response"
import { fullDatetime } from "@/utils/time"
import Breadcrumb from "@/components/BreadcrumbNew"
import { renderMessageBlastStatusToMaterialChips } from "@/utils/messageBlast"
import MoreOptionsMenu from "@/components/MoreOptionsMenu"
import ScheduleDialog from "@/components/ScheduleDialog"
import MessageItem from "@/components/ChatMessageBuilder/MessageItem"
import { ErrorType } from "@/hooks/useValidation"

type ManagementTabType = "contacts" | "messages" | "whatsapp" | "report"

type ManagementTab = {
	title: string
	type: ManagementTabType
}

const MANAGEMENT_TABS: ManagementTab[] = [
	{
		title: "CONTATOS",
		type: "contacts"
	},
	{
		title: "MENSAGEM",
		type: "messages"
	},
	{
		title: "WHATSAPP",
		type: "whatsapp"
	},
	{
		title: "RELATÓRIO",
		type: "report"
	}
]

const Management = () => {
	const [messageBlast, setMessageBlast] = useState({} as MessageBlastData)
	const [contacts, setContacts] = useState({} as MessageBlastContact[])
	const [contactCount, setContactCount] = useState(0)
	const [contactsWhereData, setContactsWhereData] = useState<ContactsWhereData>(CONTACTS_DEFAULT_WHERE_DATA)
	const [openFireMessagesDialog, setOpenFireMessagesDialog] = useState(false)

	const [clientMessageBlasts, setClientMessageBlasts] = useState<PaginatedClientMessageBlast>({} as PaginatedClientMessageBlast)

	const [loading, setLoading] = useState(true)
	const [loadingContacts, setLoadingContacts] = useState(false)
	const [currentManagementTabType, setCurrentManagementTabType] = useState<ManagementTabType>(MANAGEMENT_TABS[0]?.type)
	const messageBlastId = +useParams<{ messageBlastId: string }>().messageBlastId
	const [openInfoDialog, setOpenInfoDialog] = useState(false)
	const [editDialogProps, setEditDialogProps] = useState({
		open: false,
		loading: false,
		messageBlast: {
			title: ""
		}
	})

	const [openScheduleMessagesDialog, setOpenScheduleMessagesDialog] = useState<boolean>(false)

	const classes = useStyles()

	const history = useHistory()

	const isMessageBlastSent = messageBlast.status === "SENT"

	const handleChangeCurrentManagementTabType = (type: ManagementTabType): void => {
		setCurrentManagementTabType(type)
	}

	const loadClientMessageBlasts = async (whereData: Partial<ClientMessageBlastWhereData> = CLIENT_MESSAGE_BLAST_DEFAULT_WHERE_DATA) => {
		try {
			const response = await ApiService.get(`/message-blasts/${messageBlastId}/report`, {
				params: whereData
			})

			setClientMessageBlasts(response.data)
		} catch (error) {
			ErrorHandler.handle(error as ErrorType)
		}
	}

	const loadMessageBlast = async () => {
		try {
			const response = await ApiService.get(`/message-blasts/${messageBlastId}`)

			const responseMessageBlast = response.data.messageBlast

			setMessageBlast(responseMessageBlast)

			setEditDialogProps({
				...editDialogProps,
				messageBlast: {
					title: responseMessageBlast.title
				}
			})
		} catch (error) {
			ErrorHandler.handle(error as ErrorType)
		}
	}

	// eslint-disable-next-line
	const loadContacts = useRef(async () => { })
	loadContacts.current = async () => {
		setLoadingContacts(true)

		try {
			const response = await ApiService.get(`/message-blasts/${messageBlastId}/clients`, {
				params: contactsWhereData
			})
			setContacts(response.data.rows)
			setContactCount(response.data.count)
		} catch (error) {
			ErrorHandler.handle(error as ErrorType)
		}

		setLoadingContacts(false)
	}

	const onMessageBlastChange = async (updateMessageBlast: MessageBlastData): Promise<boolean> => {
		try {
			await ApiService.put(`/message-blasts/${updateMessageBlast.id}`, updateMessageBlast)
			Notification.success({ message: "Envio alterado com sucesso!" })
		} catch (error) {
			ErrorHandler.handle(error as ErrorType)
			Notification.error({ message: "Não foi possível alterar o envio!" })
		}

		await loadMessageBlast()
		return true
	}

	const handleChangeMessageBlast = async (data: Partial<MessageBlastData>): Promise<boolean> => {
		const messageBlastData = {
			...messageBlast,
			...data
		}

		return await onMessageBlastChange(messageBlastData)
	}

	const handleFireMessages = async (): Promise<void> => {
		try {
			await ApiService.post(`/message-blasts/${messageBlastId}/fire`)

			await loadClientMessageBlasts()

			Notification.success({ message: "Envio disparado com sucesso!" })
		} catch (error) {
			ErrorHandler.handle(error as ErrorType)

			const errorName = getErrorName(error as ErrorType)

			if (errorName === "ExceededMaxClients") {
				Notification.error({ message: "Você ultrapassou o limite de contatos!" })
			} else if (errorName === "ActionNotAvailableForAdminUser") {
				Notification.error({ message: "Não foi possivel realizar o Envio em Massa, pois você está autenticado via Admin!" })
			} else {
				Notification.error({ message: "Não foi possível disparar o envio!" })
			}
		}

		await loadMessageBlast()
	}

	const handleAddContacts = async (contacts: MessageBlastContactToAdd[]): Promise<void> => {
		await ApiService.post(`/message-blasts/${messageBlastId}/clients`, {
			clients: contacts
		})

		await loadContacts.current()
	}

	const handleClearContacts = async (): Promise<void> => {
		try {
			await ApiService.delete(`/message-blasts/${messageBlastId}/clients/all`)
			Notification.success({ message: "Contatos removidos com sucesso!" })
		} catch (error) {
			ErrorHandler.handle(error as ErrorType)
			Notification.error({ message: "Não foi possível remover os contatos!" })
		}

		await loadContacts.current()
	}

	const handleRemoveContact = async (contact: MessageBlastContact) => {
		try {
			await ApiService.delete(`/message-blasts/${messageBlastId}/clients`, {
				data: {
					clients: [
						contact
					]
				}
			})
			Notification.success({ message: "Contato removido com sucesso!" })
		} catch (error) {
			ErrorHandler.handle(error as ErrorType)
			Notification.error({ message: "Não foi possível remover o contato!" })
		}

		await loadContacts.current()
	}

	const handleCancelSchedule = async (messageBlastId: number, scheduleId?: number) => {
		PopConfirm.open({
			title: "Cancelar Agendamento",
			description: "Tem certeza? Essa ação é irreversível.",
			confirmButtonText: "CONTINUAR",
			cancelButtonText: "VOLTAR",
			onConfirm: async () => {
				try {
					await ApiService.post(`/message-blasts/scheduled/${messageBlastId}/cancel`, {
						scheduleId
					})

					await loadMessageBlast()
				} catch (error) {
					ErrorHandler.handle(error as ErrorType)

					if (getErrorName(error as ErrorType) === "MessageBlastAlreadySent") {
						Notification.error({ message: "Este envio já foi disparado." })
					}

					Notification.error({ message: "Houve um erro ao tentar cancelar o agendamento." })
				}
			}
		})
	}

	const handleDeleteMessageBlast = async (messageBlastId: number) => {
		PopConfirm.open({
			title: "Excluir envio",
			description: "Tem certeza? Essa ação é irreversível.",
			confirmButtonText: "APAGAR",
			cancelButtonText: "CANCELAR",
			onConfirm: async () => {
				try {
					await ApiService.delete(`/message-blasts/${messageBlastId}`)
					history.push("/admin/message-blast")
				} catch (error) {
					Notification.error({ message: "Houve um erro." })
					ErrorHandler.handle(error as ErrorType)
				}
			}
		})
	}

	const setup = async () => {
		await Promise.all([
			loadMessageBlast(),
			loadClientMessageBlasts(),
			loadContacts.current()
		])

		setLoading(false)
	}

	const isFireButtonDisabled = () => {
		const isSent = isMessageBlastSent
		const noContacts = contactCount < 1
		const noMessages = (messageBlast.content?.messages?.length || 0) < 1

		if (isSent || noContacts || noMessages) {
			return true
		}

		return false
	}

	const getFireButtonTooltipTitle = () => {
		if (isMessageBlastSent) {
			return "Você já disparou o envio."
		}

		if (contactCount < 1) {
			return "Adicione contatos antes de disparar o envio."
		}

		const messagesCount = messageBlast.content?.messages?.length || 0
		if (messagesCount < 1) {
			return "Adicione mensagens antes de disparar o envio."
		}

		return ""
	}

	const handleOpenScheduleMessagesDialog = () => {
		setOpenScheduleMessagesDialog(true)
	}

	const handleOpenFireMessagesDialog = () => {
		setOpenFireMessagesDialog(true)
	}

	const handleCloseFireMessagesDialog = () => {
		setOpenFireMessagesDialog(false)
	}

	useDidMount(() => {
		setup()
	})

	useEffect(() => {
		loadContacts.current()
	}, [contactsWhereData])

	return (

		<Loading
			loading={loading}
			customLoadingElement={<MessageBlastManagementSkeleton />}
		>
			<>
				<Grid
					container
					direction="column"
				>
					<Grid
						alignItems="flex-start"
						justify="space-between"
						container
					>
						<Grid item xs>
							<Typography
								variant="h4"
								color="textPrimary"
								className={classes.breadcrumb}
							>
								<Breadcrumb
									data={[
										{ name: "Envios em Massa", pathname: "/admin/message-blast" },
										{ name: "Detalhes do Envio", pathname: "/admin/message-blast/" + messageBlast.id }
									]}
								/>

								<Divider orientation="vertical" size={2} />

								<InfoDialog
									openDialog={openInfoDialog}
									onClose={() => setOpenInfoDialog(false)}
									title="Informações do Envio"
								>
									<Typography variant="body1">
										Data de criação:  {fullDatetime(new Date(messageBlast.createdAt))}
									</Typography>

									{messageBlast.status === "SENT" && (
										<Typography variant="body1">
											Data de envio: {fullDatetime(new Date(messageBlast.statusDate))}
										</Typography>
									)}
								</InfoDialog>

								<ActionDialog
									title="Alterar título do envio"
									openDialog={editDialogProps.open}
									fullWidth
									loading={editDialogProps.loading}
									onSave={async () => {
										setEditDialogProps({ ...editDialogProps, loading: true })
										await handleChangeMessageBlast(editDialogProps.messageBlast)
										setEditDialogProps({ ...editDialogProps, open: false, loading: false })
									}}
									onClose={() => setEditDialogProps({ ...editDialogProps, open: false })}
								>
									<TextField
										placeholder="Título"
										variant="outlined"
										color="primary"
										value={editDialogProps.messageBlast.title}
										onChange={async ({ target }) => setEditDialogProps({
											...editDialogProps,
											messageBlast: { title: target.value as string }
										})}
										fullWidth
									/>
								</ActionDialog>
							</Typography>
						</Grid>
					</Grid>

					<Divider orientation="horizontal" size={5}/>

					<Grid
						container
						alignItems="flex-start"
						justifyContent="space-between"
					>
						<Grid item>
							<Box display="flex" alignItems="center">
								{renderMessageBlastStatusToMaterialChips(messageBlast)}
							</Box>
						</Grid>
					</Grid>

					{
						messageBlast?.title?.length > 55 && <Divider orientation="horizontal" size={2}/>
					}

					<Grid
						container
						alignItems="center"
						justifyContent="space-between"
						style={{ minHeight: "50px" }}
					>
						<Grid item xs={6}>
							<Typography variant="body1">
								<h3 style={{ fontWeight: "600" }}>{messageBlast.title}</h3>
							</Typography>
						</Grid>
						<Grid item>
							<Grid container direction="row" spacing={2} justifyContent="center" alignItems="center">
								{
									messageBlast.status === "DRAFT" && <Grid item>
										<Button
											variant="outlined"
											className={isFireButtonDisabled() ? "" : classes.scheduleMesssageButton}
											onClick={handleOpenScheduleMessagesDialog}
											disabled={isFireButtonDisabled()}
											style={{ height: "50px" }}
										>
											<ScheduleActionIcon fontSize="medium"/>
											<Divider orientation="vertical" size={1} />
											AGENDAR ENVIO
										</Button>
									</Grid>
								}
								{
									messageBlast.status === "DRAFT" && <Grid item>
										<Tooltip
											title={
												getFireButtonTooltipTitle()
											}
										>
											<span>

												<Button
													variant="contained"
													color="primary"
													onClick={handleOpenFireMessagesDialog}
													disabled={isFireButtonDisabled()}
													style={{ height: "50px" }}
												>
													<SendIcon fontSize="medium"/>
													<Divider orientation="vertical" size={1} />
													ENVIAR AGORA
												</Button>
											</span>
										</Tooltip>
									</Grid>
								}
								{
									messageBlast.status === "SCHEDULED" && <Grid item>
										<Button
											variant="outlined"
											className={classes.cancelScheduleMesssageButton}
											onClick={() => handleCancelSchedule(messageBlast.id, messageBlast?.schedule?.id)}
											style={{ height: "50px" }}
										>
											<CancelScheduleIcon fontSize="medium"/>
											<Divider orientation="vertical" size={1} />
											CANCELAR AGENDAMENTO
										</Button>
									</Grid>
								}

								<Grid item>
									<MoreOptionsMenu
										options={[
											{
												title: "Detalhes",
												icon: <InfoIcon/>,
												showIcon: true,
												onClick: async () => setOpenInfoDialog(true)
											},
											{
												title: "Alterar",
												icon: <EditIcon/>,
												showIcon: true,
												onClick: async () => setEditDialogProps({ ...editDialogProps, open: true })
											},
											{
												title: "Excluir",
												icon: <DeleteIcon/>,
												showIcon: true,
												color: "danger",
												onClick: async () => handleDeleteMessageBlast(messageBlast.id)
											}
										]}
										enableBackground={true}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Divider size={6} orientation="horizontal" />

					<Tabs
						color="primary"
						value={currentManagementTabType}
						className={classes.chatTabs}
						onChange={(_, value) => handleChangeCurrentManagementTabType(value)}
						classes={{
							indicator: classes.chatTabIndicator
						}}
					>
						{MANAGEMENT_TABS.map(managementTab => {
							const isReportTab = managementTab.type === "report"

							if (isReportTab && !isMessageBlastSent) {
								return null
							}

							return (
								<Tab
									key={managementTab.title}
									label={managementTab.title}
									value={managementTab.type}
									className={classes.chatTab}
								/>
							)
						})}
					</Tabs>

					<Divider size={3} orientation="horizontal" />

					{currentManagementTabType === "contacts" && (
						<Contacts
							messageBlastId={messageBlastId}
							isMessageBlastSent={messageBlast.status === "SENT"}
							contacts={contacts}
							contactCount={contactCount}
							onClearContacts={handleClearContacts}
							onRemoveContact={handleRemoveContact}
							onAddContacts={handleAddContacts}
							whereData={contactsWhereData}
							setWhereData={setContactsWhereData}
							loading={loadingContacts}
							loadContacts={loadContacts}
							messagesInMessageBlastCount={messageBlast?.content?.messages?.length}
						/>
					)}

					{currentManagementTabType === "messages" && (
						<Messages
							isMessageBlastSent={messageBlast.status === "SENT"}
							messages={messageBlast.content?.messages || []}
							onSave={async (messages) => {
								await handleChangeMessageBlast({
									content: { messages }
								})
							}}
						/>
					)}

					{currentManagementTabType === "whatsapp" && (
						<WhatsappConnectionFlow />
					)}

					{currentManagementTabType === "report" && (
						<Report
							clientMessageBlasts={clientMessageBlasts}
							loadClientMessageBlasts={loadClientMessageBlasts}
						/>
					)}
				</Grid>

				<FireMessagesDialog
					open={openFireMessagesDialog}
					onClose={handleCloseFireMessagesDialog}
					onFire={handleFireMessages}
					contactCount={contactCount}
					messages={messageBlast?.content?.messages || []}
				/>

				<ScheduleDialog
					title="Confirme os dados para o envio"
					openDialog={openScheduleMessagesDialog}
					createScheduleText="AGENDAR ENVIO"
					contactCount={contactCount}
					messageCount={messageBlast?.content?.messages?.length ?? 0}
					onClose={() => setOpenScheduleMessagesDialog(false)}
					scheduleData={{
						callback: {
							type: "webhook"
						},
						payload: {
							messageBlastId
						},
						inboxChannelId: messageBlast?.inboxChannelId
					}}
					actionTrigger="message-blast"
					onSchedule={async () => {
						setOpenScheduleMessagesDialog(false)
						await loadMessageBlast()
					}}
					fullWidth
				>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<b>Número de contatos:</b> {contactCount}
						</Grid>

						<Divider orientation="horizontal" size={1} />

						<Grid item xs={12}>
							<b>Mensagens:</b>
						</Grid>

						<Grid item xs={12}>
							<Grid
								container
								alignItems="flex-start"
								justify="flex-start"
								spacing={1}
							>
								{messageBlast?.content?.messages?.map(message => (
									<Grid item xs={12} key={message.id}>
										<Grid container>
											<MessageItem
												type={message.type}
												content={message.content}
												mediaName={message.mediaName}
											/>
										</Grid>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>
				</ScheduleDialog>
			</>
		</Loading >
	)
}

export default Management
