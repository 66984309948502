import { reportsTypeToTitle, ReportsType } from "@/protocols/reports"
import React from "react"
import TagsReport from "@/pages/Admin/Reports/TagsReport"
import { Switch, Route, useParams } from "react-router-dom"
import { Grid } from "@material-ui/core"
import Breadcrumb from "@/components/BreadcrumbNew"

const reportTypeToComponent: Record<ReportsType, React.ComponentType> = {
	"tags-report": TagsReport
}

export const ReportRoutes = () => {
	return (
		<Switch>
			{Object.entries(reportTypeToComponent).map(([reportType, component]) => (
				<Route
					key={reportType}
					exact
					path={`/admin/report/${reportType}`}
					component={component}
				/>
			))}
		</Switch>
	)
}

const RedirectReport = () => {
	const { reportType } = useParams<{ reportType: ReportsType }>()
	const reportTitle = reportsTypeToTitle[reportType]

	return (
		<>
			<Grid container direction="column" spacing={3}>
				<Grid item>
					<Breadcrumb
						data={[
							{ name: "Relatórios", pathname: "/admin/reports" },
							{ name: `${reportTitle}`, pathname: `/admin/report/${reportType}` }
						]}
					/>
				</Grid>
				<Grid item>
					<ReportRoutes/>
				</Grid>
			</Grid>
		</>
	)
}

export default RedirectReport
