import React from "react"
import { getRowsLabel } from "@/utils/table"
import { TablePagination } from "@material-ui/core"
import { DefaultWhereDataType } from "@/utils/pagination"
import DataTable, { TableColumn } from "@/components/DataTable"

type DataTableWithPaginationProps<RowData> = {
	columns: TableColumn<RowData>[]
	data: RowData[]
	page: number
	rowsPerPage: number
	totalRows: number
	onPaginationChange: (data: Partial<DefaultWhereDataType>) => void
}

const DataTableWithPagination = <RowData, >(props: DataTableWithPaginationProps<RowData>) => {
	const {
		columns,
		data,
		page,
		rowsPerPage,
		totalRows,
		onPaginationChange
	} = props

	return (
		<>
			<DataTable
				columns={columns}
				data={data}
			/>

			<TablePagination
				rowsPerPageOptions={[20, 50, 100, 200]}
				component="div"
				count={totalRows}
				rowsPerPage={rowsPerPage || 0}
				page={page || 0}
				onPageChange={(_, page) => onPaginationChange({ page: page })}
				onRowsPerPageChange={({ target }) => {
					onPaginationChange({ rowsPerPage: +target.value })
					onPaginationChange({ page: 0 })
				}}
				labelRowsPerPage={"Resultados por página:"}
				labelDisplayedRows={(tableData) =>
					getRowsLabel(tableData, rowsPerPage)
				}
			/>
		</>
	)
}

export default DataTableWithPagination
