import React, { useState } from "react"
import {
	Typography,
	Grid,
	TextField,
	FormControl,
	FormLabel,
	Button,
	Link
} from "@material-ui/core"

import {
	ActionDialog,
	Loading,
	Notification,
	Portlet
} from "@/components"

import { integrationInfo } from "@/utils/integration"
import Breadcrumb from "@/components/BreadcrumbNew"
import useDidMount from "@/hooks/useDidMount"
import useStyles from "@/pages/Admin/Plugins/MetaAdsPlugin/styles"
import { MetaAdsPluginSettingsStatusSkeleton } from "@/skeletons/Admin/MetaAdsPluginSkeletons"
import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"
import useValidation, { ErrorType } from "@/hooks/useValidation"
import { PluginActivationStatusType } from "@/hooks/useActiveCampaignApi"
import { Alert } from "@material-ui/lab"
import { letalkLinks } from "@/utils/link"

type MetaAdsAuthenticationSettings = {
	token?: string
	pixel?: string
}
type MetaAdsPluginSettings = {
	id?: number
	instance_id?: number
	plugin_type?: string
	status?: "enabled" | "disabled"
	settings?: MetaAdsAuthenticationSettings
}

const MetaAdsPlugin = () => {
	const [loading, setLoading] = useState(false)
	const [openDialog, setOpenDialog] = useState(false)
	const [pluginSettings, setPluginSettings] = useState<MetaAdsPluginSettings>()
	const [pluginActivationStatus, setPluginActivationStatus] = useState<PluginActivationStatusType>("success")

	const {
		validation,
		triggerValidation,
		clearAllValidations
	} = useValidation()

	const classes = useStyles()

	const handleOpenMetaAdsConfigurationApi = () => {
		setOpenDialog(true)
	}

	const handleCloseMetaAdsConfigurationApi = () => {
		setOpenDialog(false)
	}

	const handleGetPluginSettings = async () => {
		setLoading(true)
		try {
			const response = await ApiService.get("/plugin-settings", {
				params: {
					pluginType: "meta-ads"
				}
			})
			setLoading(false)
			return response?.data.dataValues
		} catch (error) {
			Notification.error({ message: "Não foi possível carregar os dados do plugin." })
		}
		setLoading(false)
	}

	const handleChangePluginSettings = (key: keyof MetaAdsAuthenticationSettings, value: string) => {
		setPluginSettings(lastState => {
			return {
				...lastState,
				settings: {
					...lastState?.settings,
					[key]: value
				}
			}
		})
	}

	const validatePluginSettings = async (pixel: MetaAdsAuthenticationSettings["pixel"], token: MetaAdsAuthenticationSettings["token"]) => {
		setPluginActivationStatus("info")
		try {
			await ApiService.get(`/plugin-settings/meta-ads/auth?pixel=${pixel}&token=${token}`)

			handleCloseMetaAdsConfigurationApi()
			setPluginActivationStatus("success")
			clearAllValidations()
		} catch (error) {
			triggerValidation(error as ErrorType)
			setPluginActivationStatus("warning")
			ErrorHandlerService.handle(error as ErrorType)
		}
	}

	const handleMetaAdsApiAuthCheck = async () => {
		try {
			const pluginSettings = await handleGetPluginSettings()
			const pixel = pluginSettings?.settings?.pixel
			const token = pluginSettings?.settings?.token

			setPluginSettings(pluginSettings)

			if (pixel && token) {
				await validatePluginSettings(pixel, token)
			}
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
	}

	const handleSavePluginSetting = async () => {
		try {
			await ApiService.put(`/plugin-settings/${pluginSettings?.id}`, {
				pluginType: "meta-ads",
				settings: pluginSettings?.settings,
				status: "enabled"
			})

			setPluginSettings(lastState => {
				return {
					...lastState,
					status: "enabled"
				}
			})

			const pixel = pluginSettings?.settings?.pixel
			const token = pluginSettings?.settings?.token

			await validatePluginSettings(pixel, token)
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
	}

	const handleMountPluginActivationStatusText = () => {
		const textsByStatus: Record<PluginActivationStatusType, string> = {
			success: "API conectada, o plugin está funcionando corretamente.",
			warning: "Não foi possível conectar na API com o Pixel e Access Token informados abaixo. Favor informar os dados corretamente para que o plugin do Meta Ads funcione corretamente.",
			info: "Validando informações..."
		}

		return textsByStatus[pluginActivationStatus]
	}

	useDidMount(() => {
		handleMetaAdsApiAuthCheck()
	})

	return (
		<Loading
			loading={loading}
			customLoadingElement={<MetaAdsPluginSettingsStatusSkeleton />}
		>
			<Grid
				container
				direction="column"
				spacing={4}
			>
				<Grid item>
					<Grid
						alignItems="center"
						justifyContent="space-between"
						container
					>
						<Typography
							variant="h4"
							color="textPrimary"
							className={classes.breadcrumb}
						>
							<Breadcrumb
								data={[
									{ name: "Plugins", pathname: "/admin/plugins" },
									{ name: "Meta Ads", pathname: "/admin/plugins/meta-ads" }
								]}
							/>
						</Typography>

						<Grid item>
							<img
								src={integrationInfo.MetaAds?.logo}
								alt="Meta Ads logo"
								className={classes.logoImg}
							/>
						</Grid>
					</Grid>
				</Grid>

				<Grid item>
					<Grid container direction="column" spacing={2}>
						<Grid item>
							<Link
								href={letalkLinks.wikiHowToConfigurePluginWithMetaAds}
								underline="always"
								target="_blank"
								color="inherit"
							>
					Conecte seu pixel do Meta Ads e envie eventos de conversão através de automações na Letalk
							</Link>
						</Grid>
						{pluginSettings?.status === "enabled" && <Grid item>
							<Alert severity={pluginActivationStatus}>
								{handleMountPluginActivationStatusText()}
							</Alert>
						</Grid>}
						<Grid item>
							<Portlet>
								<Grid
									container
									direction="column"
								>
									<Grid
										item
										xs={12}
									>
										<Grid
											container
											justifyContent="space-between"
										>
											<Grid item>
												<Typography variant="h4">
													Configurar Pixel e Token do Meta Ads
												</Typography>
												<Typography variant="body1">
													Configure a Letalk para integrar com o Meta Ads e alimentar o pixel automaticamente
												</Typography>
											</Grid>
										</Grid>
									</Grid>

									<Grid
										item
										xs={12}
									>
										<Grid
											container
											justifyContent="flex-end"
										>
											<Grid item>
												<Button
													variant="contained"
													{...{
														color: "primary"
													}}
													disabled={loading}
													onClick={handleOpenMetaAdsConfigurationApi}
												>
													Configurar
												</Button>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Portlet>
						</Grid>
					</Grid>
				</Grid>

				<ActionDialog
					title="Configurar Pixel e Token do Meta Ads"
					openDialog={openDialog}
					onClose={handleCloseMetaAdsConfigurationApi}
					onSave={handleSavePluginSetting}
					saveText={"VALIDAR E SALVAR"}
					fullWidth
				>
					<Grid container xs={12} spacing={3}>
						<Grid item xs={12}>
							<FormControl fullWidth focused={false}>
								<FormLabel> <b>Pixel</b> </FormLabel>
								<TextField
									variant="outlined"
									fullWidth
									value={pluginSettings?.settings?.pixel}
									placeholder="46389034856012"
									onChange={({ target }) => handleChangePluginSettings("pixel", target.value)}
									helperText={validation.invalid_pixel_id}
									error={!!validation.invalid_pixel_id}
								/>
							</FormControl>
						</Grid>

						<Grid item xs={12}>
							<FormControl fullWidth focused={false}>
								<FormLabel> <b>Token</b> </FormLabel>
								<TextField
									variant="outlined"
									fullWidth
									value={pluginSettings?.settings?.token}
									placeholder="EAAt841XYDVFDOK33012MDS46MSA1..."
									onChange={({ target }) => handleChangePluginSettings("token", target.value)}
									helperText={validation.invalid_access_token}
									error={!!validation.invalid_access_token}
								/>
							</FormControl>
						</Grid>
					</Grid>
				</ActionDialog>
			</Grid>
		</Loading >
	)
}

export default MetaAdsPlugin
