import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"
import newColors from "@/styles/newColors"

const useStyles = makeStyles(theme => ({
	categoryTitleText: {
		margin: 0
	},
	categoryTitleIcon: {
		minWidth: "auto",
		paddingRight: theme.spacing(2),
		"& svg": {
			width: theme.spacing(1.5),
			height: theme.spacing(1.5)
		}
	},
	categoryTitleContainer: {
		padding: 0
	},
	blockContainer: {
		padding: theme.spacing(2),
		borderRadius: 6,
		borderLeft: `3px solid ${newColors.yellow[400]}`,
		backgroundColor: colors.grayScale[11],
		cursor: "grab",
		boxShadow: `0px 2px 2px 0px ${colors.unrelated["00000024"]}`,
		"&:active": {
			animationName: "$draggingBlock",
			animationDuration: "0.1s",
			animationIterationCount: "infinite"
		}
	},
	blockItem: {
		height: "100%"
	},
	blockIcon: {
		minWidth: theme.spacing(2),
		minHeight: theme.spacing(2)
	},
	blockInfoContainer: {
		flex: 1
	},
	blockInfoTitleText: {},
	blockInfoDescriptionText: {
		fontWeight: 400,
		opacity: 0.5
	},
	button: {
		position: "absolute",
		width: theme.spacing(5),
		height: theme.spacing(5),
		minWidth: theme.spacing(5),
		zIndex: 9,
		top: theme.spacing(7),
		left: 0,
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		backgroundColor: colors.unrelated.E809AE,
		fontSize: theme.spacing(3),
		"&:hover": {
			backgroundColor: colors.unrelated.b80087
		}
	},
	"@keyframes draggingBlock": {
		"0%": {
			opacity: 1
		},
		"99%": {
			opacity: 1
		},
		"100%": {
			opacity: 1
		}
	},
	listItem: {
		listStyleType: "disc",
		display: "list-item"
	},
	popper: {
		zIndex: 9999999,
		flexDirection: "column",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: theme.spacing(1.5, 2),
		width: "379px",
		height: theme.spacing(21),
		background: colors.grayScale[11],
		boxShadow: "2px 9px 10px 8px rgba(0, 0, 0, 0.1)",
		borderRadius: theme.spacing(0.5),
		left: "70px !important",
		top: "90px !important"
	},
	markText: {
		fontWeight: "bold",
		"& .MuiTypography-colorTextPrimary": {
			color: colors.unrelated["000000"]
		}
	}
}))

export default useStyles
