import React, { useState } from "react"
import {
	Typography,
	Button,
	TextField,
	Grid
} from "@material-ui/core"

import ApiService from "@/services/Api"
import { UserDataProps } from "@/services/User"

import useValidation, { ErrorType } from "@/hooks/useValidation"
import { useGlobalStateStore } from "@/store/GlobalState"

import { ActionDialog, Notification, Portlet, AlertContainer } from "@/components"
import EmailValidationDialog from "@/components/EmailValidation"
import InfoItemInput from "@/components/InfoItemInput"
import InstanceDataPortlet from "@/components/Profile/InstanceDataPortlet"
import SubscriptionDataPortlet from "@/components/Profile/SubscriptionDataPortlet"

import useStyles from "@/components/Profile/styles"
import useCustomStyles from "@/styles/custom"

import { isAbleToValidateEmail } from "@/utils/time"

type DialogProps = {
	title: string
	field: keyof UserDataProps
	placeholder: string
	saveText: string
}

type DialogContext = "email" | "name"

type UserDialogDataProps = {
	context: DialogContext
	value: string
	newValue: string
	confirmNewValue: string
}

const Profile = () => {
	const globalStateStore = useGlobalStateStore()
	const userStore = globalStateStore.user
	const [userDialogData, setUserDialogData] = useState<UserDialogDataProps>({
		context: "email",
		value: userStore.email,
		newValue: userStore.email,
		confirmNewValue: ""

	})

	const [openDialog, setOpenDialog] = useState<boolean>(false)
	const [openValidateEmailDialog, setOpenValidateEmailDialog] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const {
		validation,
		triggerValidation,
		clearValidation
	} = useValidation()

	const canValidateEmail = isAbleToValidateEmail()

	const isInstanceOwner = userStore.is_instance_owner

	const dialogContentByTypeMap: Record<DialogContext, DialogProps> = {
		email: {
			title: "Alterar e-mail",
			field: "email",
			placeholder: "Email",
			saveText: "ALTERAR"
		},
		name: {
			title: "Alterar nome de usuário",
			field: "name",
			placeholder: "Nome",
			saveText: "ALTERAR"
		}
	}

	const dialogOptions = dialogContentByTypeMap[userDialogData.context]

	const handleCloseDialog = (): void => {
		setOpenDialog(false)
	}

	const handleChangeUserData = async (): Promise<void> => {
		setLoading(true)
		try {
			const field = dialogOptions.field

			const userData = {
				name: userStore?.name,
				email: userStore?.email,
				role_code: globalStateStore.instance.user_in_instance_role.code
			}
			await ApiService.put(`/user/${userStore.id}`, { ...userData, [field]: userDialogData.value })

			globalStateStore.setUserData({
				[field]: userDialogData.value
			})
			Notification.success({ message: "Informação alterada com sucesso!" })
			handleCloseDialog()
		} catch (error) {
			triggerValidation(error as ErrorType)
		}
		setLoading(false)
	}

	const handleUpdatePasswordClose = () => {
		clearValidation("currentPassword")
		clearValidation("newPassword")
		clearValidation("confirmNewPassword")
	}

	const handleOpenValidateEmailDialog = (): void => {
		setOpenValidateEmailDialog(true)
	}

	const handleValidateEmail = async () => {
		await ApiService.post("/auth/email", { email: userStore.email })

		handleOpenValidateEmailDialog()
	}

	const handleOpenDialogByOption = (context: DialogContext): void => {
		setUserDialogData(lastState => ({
			...lastState,
			context: context,
			value: userStore[context],
			newValue: userStore[context]
		}))

		setOpenDialog(true)
	}

	const handleValue = (field: string, value: string): void => {
		handleUpdatePasswordClose()

		setUserDialogData(lastState => ({
			...lastState,
			[field]: value
		}))
	}

	const getManagerAccessUserDataAlert = () => {
		const isAttendantUser = globalStateStore.instance.user_in_instance_role.code === "attendant"

		if (isAttendantUser) {
			return (
				<AlertContainer
					alertType="info"
					title="Alteração de senha"
				>
					<Typography variant="body2">
						A funcionalidade de alteração de senha foi movida para o menu <strong>Segurança</strong>. Agora,
						para alterar sua senha, acesse a opção <strong>Dados de Acesso do Usuário</strong> dentro do menu <strong>Segurança</strong>.
					</Typography>
				</AlertContainer>
			)
		}

		return (
			<AlertContainer
				alertType="info"
				title="Alteração de senha"
				customButton={(
					<Button
						variant="contained"
						className={customClasses.primaryActionButton}
						onClick={() => {
							window.open("/admin/security", "_blank")
						}}
					>
						Ver página de Segurança
					</Button>
				)}
			>
				<Typography variant="body2">
					Obs: Ao clicar no botão, você será redirecionado para a página de segurança, onde poderá alterar a senha da sua conta.
				</Typography>
			</AlertContainer>
		)
	}

	return (
		<Grid
			container
			direction="column"
			spacing={2}
		>
			<Grid
				item
				xs={12}
			>
				<InstanceDataPortlet />
			</Grid>

			<Grid
				item
				xs={12}
			>
				<Grid
					container
					direction="column"
					spacing={1}
				>
					<Grid
						item
						xs={12}
					>
						<Typography
							variant="h4"
							color="textPrimary"
							className={customClasses.uppercase}
						>
							Informações da conta
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<Portlet>
							<Grid
								container
								direction="column"
								spacing={2}
							>
								<Grid
									item
									xs={12}
								>
									{getManagerAccessUserDataAlert()}
								</Grid>

								<Grid
									item
									xs={12}
								>
									<InfoItemInput
										title={"E-mail"}
										disableEdit={isInstanceOwner}
										content={userStore.email}
										severity={canValidateEmail ? "warning" : "blank"}
										onEdit={() => handleOpenDialogByOption("email")}
									>
										{canValidateEmail && <>
											<Button
												className={classes.emailValidationButton}
												onClick={handleValidateEmail}>
												<Typography className={classes.buttonText}>
													Validar e-mail
												</Typography>
											</Button>
										</>}
									</InfoItemInput>
								</Grid>
								<Grid
									item
									xs={12}
								>
									<InfoItemInput
										title={"Nome de usuário"}
										content={userStore.name}
										onEdit={() => handleOpenDialogByOption("name")}
									/>
								</Grid>
							</Grid>
						</Portlet>
					</Grid>
				</Grid>
			</Grid>

			{
				isInstanceOwner && (
					<Grid
						item
						xs={12}
					>
						<SubscriptionDataPortlet />
					</Grid>
				)
			}

			<ActionDialog
				title={dialogOptions.title}
				openDialog={openDialog}
				fullWidth
				loading={loading}
				onClose={handleCloseDialog}
				onSave={handleChangeUserData}
			>
				<Grid
					container
					direction="column"
					spacing={2}
				>
					<Grid
						item
						xs={12}
					>
						<TextField
							placeholder={dialogOptions.placeholder}
							value={userDialogData.value}
							onChange={({ target }) => handleValue("value", target.value)}
							variant="outlined"
							color="primary"
							multiline
							fullWidth
							error={!!validation[dialogOptions.field]}
							helperText={validation[dialogOptions.field]}
						/>
					</Grid>
				</Grid>
			</ActionDialog>

			<EmailValidationDialog open={openValidateEmailDialog} />
		</Grid>
	)
}

export default Profile
