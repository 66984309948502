import React, { ReactNode, useState } from "react"
import { Grid, Tooltip, Typography } from "@material-ui/core"

import {
	ReportProblemOutlined as WarningIcon,
	Close as CloseIcon
} from "@material-ui/icons"

import { ActionDialog } from "@/components"

import ErrorHandlerService from "@/services/ErrorHandler"
import ClientCatalogContactService from "@/services/ClientCatalogContactService"

import { ErrorType } from "@/hooks/useValidation"

import { ContactsFiltersConditions } from "@/pages/Admin/ClientCatalog/ClientManageFilters/ContactsFilters"
import { Client } from "@/protocols/clientCatalog"

import useStyles from "@/pages/Admin/ClientCatalog/DisableContactDialog/styles"
import newColors from "@/styles/newColors"

type DisableContactDialogProps = {
	children: ReactNode
	disabled: boolean
	type: "single-contact" | "many-contacts"
	client?: Client
	filterConditions?: ContactsFiltersConditions[]
}

const DISABLE_CONTACT_WARNINGS: string[] = [
	"O contato desaparecerá da lista de contatos",
	"Ele será removido do bot",
	"Será removido de todos os agendamentos de envio em massa",
	"Se houver um atendimento em aberto, ele será encerrado",
	"As mensagens na fila para esse contato não serão enviadas"
]

const DisableContactDialog: React.FC<DisableContactDialogProps> = (props) => {
	const {
		children,
		disabled,
		type,
		client,
		filterConditions
	} = props

	const classes = useStyles()

	const [openDialog, setOpenDialog] = useState(false)

	const handleOpenDialog = async () => {
		setOpenDialog(true)
	}

	const handleCloseDialog = () => {
		setOpenDialog(false)
	}

	const handleDisableContacts = async () => {
		try {
			await ClientCatalogContactService.disableContactsByFilter(filterConditions)

			handleCloseDialog()

			window.location.reload()
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
	}

	const handleDisableContact = async () => {
		try {
			await ClientCatalogContactService.disableContact(client)

			handleCloseDialog()

			window.location.reload()
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
	}

	const getDialogData = () => {
		const contactDialogPropsTypeToDialogData: Record<DisableContactDialogProps["type"], {
			title: string
			onSave: () => Promise<void>
		}> = {
			"single-contact": {
				title: "Ao excluir este contato, ele será removido permanentemente de várias áreas da plataforma. Isso significa que:",
				onSave: handleDisableContact
			},
			"many-contacts": {
				title: "Ao excluir estes contatos, eles serão removidos permanentemente de várias áreas da plataforma. Isso significa que:",
				onSave: handleDisableContacts
			}
		}

		return contactDialogPropsTypeToDialogData[type]
	}

	const dialogData = getDialogData()

	return (
		<>
			<Tooltip
				title={disabled ? "Para excluir contatos, é necessário realizar um filtro." : ""}
			>
				<Grid
					onClick={disabled ? undefined : handleOpenDialog}
					{...(disabled && {
						style: {
							pointerEvents: "auto"
						}
					})}
				>
					{children}
				</Grid>
			</Tooltip>

			<ActionDialog
				fullWidth
				openDialog={openDialog}
				onClose={handleCloseDialog}
				title={"ATENÇÃO"}
				dialogTitleIcon={<WarningIcon className={classes.titleIcon}/>}
				cancelText="CANCELAR"
				onSave={dialogData.onSave}
				saveText="CONFIRMAR"
				dialogTitleClassName={classes.title}
			>
				<Grid
					container
					direction="column"
					spacing={2}
				>
					<Grid
						item
						xs={12}
					>
						<Typography>
							{dialogData.title}
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<Grid
							container
							spacing={2}
						>
							{DISABLE_CONTACT_WARNINGS.map((warnings, index) => (
								<Grid
									key={index}
									item
									xs={12}
								>
									<Grid
										container
										alignItems="center"
										spacing={1}
									>
										<Grid
											item
										>
											<Grid
												container
												alignItems="center"
											>
												<CloseIcon
													style={{
														width: 24,
														height: 24,
														fill: newColors.red[600]
													}}
												/>
											</Grid>
										</Grid>
										<Grid
											item
										>
											<Typography
												variant="h4"
											>
												{warnings}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							))}
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<Typography>
							Essa ação é irreversível. Tem certeza de que deseja continuar?
						</Typography>
					</Grid>
				</Grid>
			</ActionDialog>
		</>
	)
}

export default DisableContactDialog
