import React, { useState } from "react"

import {
	ReportProblemOutlined as WarningIcon
} from "@material-ui/icons"
import {
	Typography
} from "@material-ui/core"

import {
	ActionDialog,
	Notification
} from "@/components"

import { renderComponent } from "@/utils/node"

import ErrorHandlerService from "@/services/ErrorHandler"
import ApiService from "@/services/Api"

import { ErrorType } from "@/hooks/useValidation"

import useStyles from "@/pages/Admin/Security/DevicesAndSessions/DeviceItem/FinishSessionDialog/styles"

type UnregisterDeviceDialogProps = {
	sessionId: number
	onReload?: () => void
}

type UnregisterDeviceDialogType = {
	open: (props: UnregisterDeviceDialogProps) => void
}

const UnregisterDeviceDialog: UnregisterDeviceDialogType & React.FC<UnregisterDeviceDialogProps> = (props) => {
	const {
		sessionId,
		onReload
	} = props

	const [opened, setOpened] = useState(true)

	const classes = useStyles()

	const handleClose = () => {
		setOpened(false)
	}

	const handleUnregisterDevice = async () => {
		try {
			await ApiService.put(`/session/unregister-device/${sessionId}`)

			Notification.success({
				message: "Dispositivo descadastrado com sucesso!"
			})
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)

			Notification.error({ message: "Algo deu errado e não conseguimos identificar. Entre em contato." })
		}
	}

	const handleSave = async () => {
		await handleUnregisterDevice()

		handleClose()

		onReload?.()
	}

	return (
		<ActionDialog
			fullWidth
			openDialog={opened}
			onClose={handleClose}
			title={"ATENÇÃO"}
			dialogTitleIcon={<WarningIcon className={classes.titleIcon}/>}
			cancelText="CANCELAR"
			onSave={handleSave}
			saveText="CONFIRMAR"
			dialogTitleClassName={classes.title}
		>
			<Typography>
				Na próxima vez que um login for realizado na Letalk com este dispositivo, será necessário o uso de MFA
				para concluir o processo de autenticação na plataforma.
			</Typography>
		</ActionDialog>
	)
}

UnregisterDeviceDialog.open = (props: UnregisterDeviceDialogProps) => {
	renderComponent(
		"finish-session",
		<UnregisterDeviceDialog
			{...props}
		/>
	)
}

export default UnregisterDeviceDialog
