import React from "react"
import { OutlinedInput, InputAdornment } from "@material-ui/core"

import { ChatBotFlowBlockRule } from "@/protocols/chatBot"
import { ChannelType } from "@/protocols/channel"
import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import BlockBodySectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionEditor"
import BlockBodySectionRequiredCondition, { TimeoutConditionIcon } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionRequiredCondition"
import BlockBodySectionHandle from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionHandle"
import { Notification } from "@/components"

import useConditionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useConditionEditor"

import { getConditionByValidationSlug } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockRule"
import { MIN_TIME_IN_MINUTES_TO_WAIT_FLOW } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlow"

type EditTimeoutConditionSectionProps = {
	onSave: (chatBotFlowBlockRule: ChatBotFlowBlockRule) => void
	nextChatBotFlowBlockRules: ChatBotFlowBlockRule[]
	renderText?: (timeText: React.ReactNode) => React.ReactNode
	chatBotFlowChannelType: ChannelType
}

type SaveHandler = () => void

const EditTimeoutConditionSection: React.FC<EditTimeoutConditionSectionProps> = (props) => {
	const {
		onSave,
		nextChatBotFlowBlockRules,
		renderText,
		chatBotFlowChannelType
	} = props

	const timeoutCondition = getConditionByValidationSlug("no-interaction-timeout", nextChatBotFlowBlockRules)
	const conditionEditor = useConditionEditor({ chatBotFlowBlockRule: timeoutCondition?.rule })

	if (!timeoutCondition) {
		return null
	}

	const formattedTimeoutConditionText = (
		<>
			{timeoutCondition.text}
			{" "}
			{timeoutCondition.value === 1 ? "minuto" : "minutos"}
		</>
	)

	const isWabaChannel = chatBotFlowChannelType === "waba"

	const isTimeoutConditionValid = (): {
		isValidTimeoutCondition: boolean
		timeoutConditionConfigError?: "timeout_condition_is_less_than_min_value" | "timeout_condition_is_greater_than_max_value"
	} => {
		const timeoutConditionValue = conditionEditor.retrieveValidationValue(timeoutCondition.validationIndex)

		let timeoutConditionError: "timeout_condition_is_less_than_min_value" | "timeout_condition_is_greater_than_max_value" | undefined

		const isConditionTimeoutValueLessThanMinValue = timeoutConditionValue < MIN_TIME_IN_MINUTES_TO_WAIT_FLOW

		if (isConditionTimeoutValueLessThanMinValue) {
			timeoutConditionError = "timeout_condition_is_less_than_min_value"
		}

		return {
			isValidTimeoutCondition: !isConditionTimeoutValueLessThanMinValue,
			timeoutConditionConfigError: timeoutConditionError
		}
	}

	const handleSaveWhatsappFlowTimeoutCondition = () => {
		const {
			isValidTimeoutCondition
		} = isTimeoutConditionValid()

		if (!isValidTimeoutCondition) {
			Notification.warning({ message: `Só é permitido configurar um período de tempo igual ou acima de ${MIN_TIME_IN_MINUTES_TO_WAIT_FLOW} minuto!` })
			conditionEditor.changeValidationValue(timeoutCondition.validationIndex, MIN_TIME_IN_MINUTES_TO_WAIT_FLOW)
		}

		onSave(conditionEditor.rule)
	}

	const handleSaveWABAFlowTimeoutCondition = () => {
		const {
			isValidTimeoutCondition
		} = isTimeoutConditionValid()

		if (!isValidTimeoutCondition) {
			Notification.warning({ message: `Só é permitido configurar um período de tempo igual ou acima de ${MIN_TIME_IN_MINUTES_TO_WAIT_FLOW} minuto!` })
			conditionEditor.changeValidationValue(timeoutCondition.validationIndex, MIN_TIME_IN_MINUTES_TO_WAIT_FLOW)
		}

		onSave(conditionEditor.rule)
	}

	const handleSave = () => {
		const getSaveHandlerByChannel: Record<ChannelType, SaveHandler> = {
			waba: handleSaveWABAFlowTimeoutCondition,
			whatsapp: handleSaveWhatsappFlowTimeoutCondition
		}

		const handler = getSaveHandlerByChannel[chatBotFlowChannelType]

		if (handler) {
			handler()
		}
	}

	return (
		<BlockBodySection>
			<BlockBodySectionHandle
				chatBotFlowBlockRule={timeoutCondition.rule}
			>
				<BlockBodySectionEditor
					onOpen={conditionEditor.reset}
					title="SEGUIR APÓS DETERMINADO TEMPO"
					onSave={handleSave}
					AsideDrawerProps={{
						children: (
							<OutlinedInput
								type="number"
								placeholder="30"
								value={conditionEditor.retrieveValidationValue(timeoutCondition.validationIndex)}
								fullWidth
								onChange={({ target }) => conditionEditor.changeValidationValue(timeoutCondition.validationIndex, Number(target.value))}
								endAdornment={(
									<InputAdornment
										position="end"
									>
										{Number(timeoutCondition.value) === 1 ? "minuto" : "minutos"}
									</InputAdornment>
								)}
								inputProps={{
									...(isWabaChannel && {
										max: 30
									}),
									min: 1
								}}
							/>
						),
						width: "450px"
					}}
				>
					<BlockBodySectionRequiredCondition
						text={renderText?.(formattedTimeoutConditionText) || formattedTimeoutConditionText}
						icon={<TimeoutConditionIcon />}
					/>
				</BlockBodySectionEditor>
			</BlockBodySectionHandle>
		</BlockBodySection>
	)
}

export default EditTimeoutConditionSection
