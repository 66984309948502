import React from "react"

import { IntegrationType } from "@/protocols/integration"

import ActiveCampaignLogo from "@/assets/images/integrations/activecampaign.png"
import DirectIntegrationLogo from "@/assets/images/integrations/custom_webhook.png"
import YampiLogo from "@/assets/images/integrations/yampi.png"
import HotmartV2Logo from "@/assets/images/integrations/hotmartv2.png"
import RDStationLogo from "@/assets/images/integrations/rd-station.png"
import HubSpotLogo from "@/assets/images/integrations/hubspot.png"
import HotmartLogo from "@/assets/images/integrations/hotmart.png"
import { videoLinks } from "@/utils/link"

import ActiveCampaignIcon from "@/assets/icons/active_campaign.svg"
import DirectIntegrationIcon from "@/assets/icons/custom_webhook.svg"
import HubspotIcon from "@/assets/icons/hubspot.svg"
import MetaExtendedLogoIcon from "@/assets/images/integrations/meta.png"
import HotmartIcon from "@/assets/icons/hotmart.svg"
import RdStationIcon from "@/assets/icons/rd_station.svg"
import YampiIcon from "@/assets/icons/yampi.svg"
import { Icon } from "@material-ui/core"

type IntegrationInfo = {
	[key in IntegrationType]: {
		logo: string
		description: string
		tutorialUrl: string
		title: string
		customStyles?: React.CSSProperties,
		icon?: React.ReactElement
	}
}

const mountIntegrationIcon = (icon: string, customStyle?: React.CSSProperties) => {
	return <Icon style={{
		textAlign: "center",
		fontSize: "0px"
	}}><img src={icon} style={{ height: "34px", ...customStyle }} /></Icon>
}

export const integrationInfo: IntegrationInfo = {
	ActiveCampaign: {
		logo: ActiveCampaignLogo,
		description: "Integração com o ActiveCampaign",
		title: "ActiveCampaign",
		tutorialUrl: videoLinks.activeCampaignTutorial,
		icon: mountIntegrationIcon(ActiveCampaignIcon, { marginLeft: "5px" })
	},
	DirectIntegration: {
		logo: DirectIntegrationLogo,
		description: "Integração direta com a Letalk",
		title: "Integração direta com a Letalk",
		tutorialUrl: videoLinks.directIntegrationTutorial,
		customStyles: {
			padding: "0px 15px 0px 15px",
			height: "35px"
		},
		icon: mountIntegrationIcon(DirectIntegrationIcon)
	},
	Yampi: {
		logo: YampiLogo,
		description: "Integração com a Yampi",
		title: "Yampi",
		tutorialUrl: videoLinks.yampiIntegrationTutorial,
		icon: mountIntegrationIcon(YampiIcon)
	},
	RDStation: {
		logo: RDStationLogo,
		description: "Integração com a RD Station",
		title: "RD Station",
		tutorialUrl: videoLinks.hotmartIntegrationTutorial,
		icon: mountIntegrationIcon(RdStationIcon)
	},
	HotmartV2: {
		logo: HotmartV2Logo,
		description: "Integração com a Hotmart V2",
		title: "Hotmart V2",
		tutorialUrl: videoLinks.hotmartIntegrationTutorial,
		icon: mountIntegrationIcon(HotmartIcon)
	},
	Hotmart: {
		logo: HotmartLogo,
		description: "Integração com a Hotmart",
		title: "Hotmart V1",
		tutorialUrl: videoLinks.hotmartIntegrationTutorial,
		icon: mountIntegrationIcon(HotmartIcon)
	},
	HubSpot: {
		logo: HubSpotLogo,
		description: "Integração com a HubSpot",
		title: "HubSpot",
		tutorialUrl: videoLinks.hubspotIntegrationTutorial,
		icon: mountIntegrationIcon(HubspotIcon)
	},
	MetaAds: {
		logo: MetaExtendedLogoIcon,
		description: "Integração com o Meta Ads",
		title: "Meta Ads",
		tutorialUrl: videoLinks.metaAdsIntegrationTutorial,
		icon: mountIntegrationIcon(MetaExtendedLogoIcon)
	}
}
