import React from "react"
import Breadcrumb from "@/components/BreadcrumbNew"
import { Grid, Typography } from "@material-ui/core"
import useStyles from "@/pages/Admin/Reports/styles"
import ReportCard from "@/pages/Admin/Reports/components/ReportCard"
import { LocalOfferOutlined as TagIcon } from "@material-ui/icons"
import { reportsTypeToTitle, ReportsType } from "@/protocols/reports"

type ReportCardData = {
	icon: React.ReactNode
	title: string
	description: string
	type: ReportsType
}

const tagReports : ReportCardData[] = [
	{
		icon: <TagIcon />,
		title: reportsTypeToTitle["tags-report"],
		description: "Acompanhe a quantidade de clientes associados às suas tags.",
		type: "tags-report"
	}
]

const Reports: React.FC = () => {
	const classes = useStyles()

	return (
		<>
			<Grid
				container
				direction="column"
				spacing={5}>

				<Grid item>
					<Grid
						container
						direction="column"
						spacing={3}
					>
						<Grid item>
							<Breadcrumb
								data={[
									{ name: "Relatórios", pathname: "/admin/reports" }
								]}
							/>
						</Grid>

						<Grid item>
							<Grid container direction="column" spacing={4}>

								<Grid
									item
								>
									<Typography
										variant="h5"
										className={classes.titleText}
									>
										Relatórios
									</Typography>
								</Grid>

							</Grid>
						</Grid>

					</Grid>
				</Grid>

				<Grid item>
					<Grid
						container
						direction="column"
						spacing={3}
					>
						<Grid item>
							<Typography
								variant="h4"
								className={classes.subtitleText}>
									TAGS
							</Typography>
						</Grid>

						{
							tagReports.map((report, index) => (
								<Grid item key={index}>
									<ReportCard
										icon={report.icon}
										title={report.title}
										description={report.description}
										type={report.type}
									/>
								</Grid>
							))
						}

					</Grid>
				</Grid>

			</Grid>
		</>
	)
}

export default Reports
