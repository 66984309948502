import React from "react"

import colors from "@/styles/colors"

import { Divider } from "@/components"
import { Typography } from "@material-ui/core"

import { ChatBotFlowBlockType } from "@/protocols/chatBot"

import { Info as InfoIcon } from "@material-ui/icons"
import { ReactComponent as WarningBlockedBlockIcon } from "@/assets/icons/warning_icon.svg"
import { ReactComponent as TipsIcon } from "@/assets/icons/tips_icon.svg"
import ConditionalTextGif from "@/assets/gifs/conditional_text.gif"
import RepeatGif from "@/assets/gifs/repeat.gif"

import { blockLinks, howChatBotBlocksWorkLinks } from "@/utils/link"

import newColors from "@/styles/newColors"

export type BlockAlertCategory = "disabled" | "tips"

export type BlockAlertContent = {
	header: JSX.Element
	icon: JSX.Element
	blocks: {
		[key in ChatBotFlowBlockType]?: {
			gif?: string
			text: JSX.Element
			title?: string
			link?: string
		}
	}
}

export const blockAlertList: Record<BlockAlertCategory, BlockAlertContent> = {
	disabled: {
		header: (
			<>
				<WarningBlockedBlockIcon style={{ fontSize: "1rem", fill: newColors.yellow[400] }} />

				<Divider orientation="vertical" size={0.5} />

				<Typography style={{ fontSize: "12px", color: newColors.yellow[400] }}>
                    Ops!
				</Typography>
			</>
		),
		icon: <WarningBlockedBlockIcon style={{ cursor: "pointer", fontSize: "1rem", fill: colors.unrelated.C9C9C9 }} />,
		blocks: {
			"conditional-button": {
				title: "O bloco de botão está temporariamente desabilitado",
				text: (
					<>
                        Plataformas com API web do WhatsApp estão
                        enfrentando problemas no envio de mensagens com botões e menus.
                        Estamos trabalhando nisso e recomendamos usar o bloco de
						<span style={{ fontWeight: "bold", color: colors.unrelated["000000"] }}> Múltipla escolha </span>
                        como alternativa.
					</>
				),
				link: blockLinks.howTheConditionalTextBlockWorks
			},
			"conditional-menu": {
				title: "O bloco de menu está temporariamente desabilitado",
				text: (
					<>
                        Plataformas com API web do WhatsApp estão
                        enfrentando problemas no envio de mensagens com botões e menus.
                        Estamos trabalhando nisso e recomendamos usar o bloco de
						<span style={{ fontWeight: "bold", color: colors.unrelated["000000"] }}> Múltipla escolha </span>
                        como alternativa.
					</>
				),
				link: blockLinks.howTheConditionalTextBlockWorks
			}
		}
	},
	tips: {
		header: (
			<>
				<TipsIcon style={{ fontSize: "1rem", marginBottom: "3px" }} />

				<Divider orientation="vertical" size={0.5} />

				<Typography style={{ fontSize: "12px", color: colors.palette.primary }}>
                    Dica
				</Typography>
			</>
		),
		icon: <InfoIcon htmlColor={colors.unrelated.CACACA} style={{ cursor: "pointer", fontSize: "1rem" }} />,
		blocks: {
			"conditional-text": {
				title: "Exemplo de uso: Múltipla escolha",
				text: (
					<>
                        Configurar condições para que o atendimento siga um
						caminho específico com base nas respostas do usuário
						ao receber uma mensagem.
					</>
				),
				gif: ConditionalTextGif,
				link: howChatBotBlocksWorkLinks["conditional-text"]
			},
			"finish-attendance": {
				title: "Exemplo de uso: Finalizar atendimento",
				text: (
					<>
						Finaliza o protocolo daquela conversa, não finaliza o bot.
					</>
				),
				link: howChatBotBlocksWorkLinks["finish-attendance"]
			},
			repeat: {
				title: "Exemplo de uso: Repetir",
				text: (
					<>
						Definir um número de repetições de uma determinada parte do fluxo.
					</>
				),
				gif: RepeatGif,
				link: howChatBotBlocksWorkLinks.repeat
			},
			"finish-flow": {
				title: "Exemplo de uso: Finalizar bot",
				text: (
					<>
						Encerra as interações do fluxo, mas o contato pode iniciar o fluxo novamente caso o gatilho de
						início seja acionado.
					</>
				),
				link: howChatBotBlocksWorkLinks["finish-flow"]
			},
			"send-message": {
				title: "Mais informações sobre: Mensagem de texto ou mídia",
				text: (
					<>
						Este bloco pode conter texto, imagens, vídeos, e é projetado para comunicar uma mensagem para os usuários.
					</>
				),
				link: howChatBotBlocksWorkLinks["send-message"]
			},
			"ask-and-wait": {
				title: "Mais informações sobre: Perguntar e aguardar resposta",
				text: (
					<>
						Após cumprimentar seu cliente, você pode fazer uma pergunta usando o bloco
						&quot;Perguntar e aguardar&quot;, Por exemplo: Com qual time deseja falar? Dando um prazo para ele responder.{" "}
						Depois que receber a resposta, você pode escolher o próximo passo que o cliente deve seguir.
					</>
				),
				link: howChatBotBlocksWorkLinks["ask-and-wait"]
			},
			wait: {
				title: "Mais informações sobre: Aguardar",
				text: (
					<></>
				),
				link: howChatBotBlocksWorkLinks.wait
			},
			"attendance-schedule": {
				title: "Mais informações sobre: Horário de atendimento",
				text: (
					<>
						O bloco de Horário de Atendimento é uma funcionalidade valiosa em um bot que permite aos
						usuários visualizarem os horários disponíveis para atendimento ou serviços.
					</>
				),
				link: howChatBotBlocksWorkLinks["attendance-schedule"]
			},
			"attendance-transfer": {
				title: "Mais informações sobre: Transferir Atendimento",
				text: (
					<>
						O bloco de Transferir Atendimento é uma ferramenta importante em um bot que
						possibilita encaminhar a conversa para um agente humano quando necessário.
					</>
				),
				link: howChatBotBlocksWorkLinks["attendance-transfer"]
			},
			"validate-and-save-v2": {
				title: "Mais informações sobre: Salvar resposta",
				text: (
					<>
						O bloco de Salvar Resposta é uma ferramenta útil em um bot
						que permite armazenar as respostas dos usuários para referência futura
						ou para personalização da interação.
					</>
				),
				link: howChatBotBlocksWorkLinks["validate-and-save-v2"]
			},
			"manage-client-tags": {
				title: "Mais informações sobre: Tags: adiciona/remover",
				text: (
					<>
						O bloco de Adicionar ou Remover é uma ferramenta versátil em um bot
						que permite aos usuários incluir ou remover Tags.
					</>
				),
				link: howChatBotBlocksWorkLinks["manage-client-tags"]
			},
			"random-path-flow": {
				title: "Mais informações sobre: Dividir",
				text: (
					<>
						Para que um bot pareça mais humano, é importante variar suas respostas de acordo com a situação e mostrar empatia.
					</>
				),
				link: howChatBotBlocksWorkLinks["random-path-flow"]
			},
			"go-to-flow": {
				title: "Mais informações sobre: Adicionar no bot",
				text: (
					<>
						Essa função é ideal para encaminhar usuários para outros bots,
						transferindo seus contatos para diferentes partes do fluxo de acordo com a estratégia definida.
					</>
				),
				link: howChatBotBlocksWorkLinks["go-to-flow"]
			},
			"optout-optin": {
				title: "Mais informações sobre: Adicionar no bot",
				text: (
					<>
						Enviar mensagens pelo WhatsApp pode ser benéfico para empresas, mantendo os clientes informados sobre promoções e serviços.
						No entanto, alguns clientes podem preferir não receber essas mensagens, o que pode levar ao bloqueio da conta da empresa pelo WhatsApp.
					</>
				),
				link: howChatBotBlocksWorkLinks["optout-optin"]
			},
			"send-webhook": {
				title: "Mais informações sobre: Webhook",
				text: (
					<>
						Essa função é ótima se você quiser conectar outras plataformas de forma automática usando um webhook.
						Isso significa que você enviará um conjunto de dados para um link específico. Se isso é o que você precisa, este artigo vai te ensinar como fazer.
					</>
				),
				link: howChatBotBlocksWorkLinks["send-webhook"]
			},
			condition: {
				title: "Mais informações sobre: Condição",
				text: (
					<>
						Se você está construindo um bot e quer que ele siga diferentes caminhos dependendo das circunstâncias,
						o uso de blocos de condição pode ser muito útil.
					</>
				),
				link: howChatBotBlocksWorkLinks.condition
			},
			"register-contact-to-active-campaign": {
				title: "Mais informações sobre: Blocos do Active Campaign",
				text: (
					<>
						O ActiveCampaign oferece uma variedade de blocos para criar automações de marketing eficazes.
					</>
				),
				link: howChatBotBlocksWorkLinks["register-contact-to-active-campaign"]
			},
			"register-deal-to-active-campaign": {
				title: "Mais informações sobre: Blocos do Active Campaign",
				text: (
					<>
						O ActiveCampaign oferece uma variedade de blocos para criar automações de marketing eficazes.
					</>
				),
				link: howChatBotBlocksWorkLinks["register-deal-to-active-campaign"]
			},
			"register-tag-to-active-campaign": {
				title: "Mais informações sobre: Blocos do Active Campaign",
				text: (
					<>
						O ActiveCampaign oferece uma variedade de blocos para criar automações de marketing eficazes.
					</>
				),
				link: howChatBotBlocksWorkLinks["register-tag-to-active-campaign"]
			},
			"meta-ads-conversion-event": {
				title: "Mais informações sobre: Blocos de Evento de conversão",
				text: (
					<>
					O Meta Ads oferece uma variedade de blocos para criar automações de marketing eficazes.
					</>
				)
			}
		}
	}
}

export const getBlockAlertCategory = (blockType: ChatBotFlowBlockType): BlockAlertCategory | undefined => {
	const alertCategory = Object.keys(blockAlertList).find(category => {
		if (blockAlertList[category as BlockAlertCategory].blocks[blockType]) {
			return category
		}
	}) as BlockAlertCategory

	if (!alertCategory) {
		return
	}

	return alertCategory
}
