import React, { useEffect } from "react"
import {
	Grid
} from "@material-ui/core"

import {
	PhoneLocked as DeviceAndSessionsIcon,
	Security as UserDataIcon
} from "@material-ui/icons"

import {
	AccessibleDrawer,
	SettingsMenuItem
} from "@/components"

import useChatStyles from "@/pages/Attendance/Chat/styles"
import useDidMount from "@/hooks/useDidMount"

import HeaderDrawer from "@/pages/Attendance/Chat/HeaderDrawer"
import DeviceAndSessionsSettingsDialog from "@/pages/Attendance/Chat/ChatListPanel/ChatListHeader/SecuritySettings/DeviceAndSessionsSettingsDialog"
import ManagementUserAccessDataSettingsDialog from "@/pages/Attendance/Chat/ChatListPanel/ChatListHeader/SecuritySettings/ManagementUserAccessDataSettingsDialog"

type SecuritySettingsProps = {
	openDrawer: boolean
	onClose: () => void
}

const SecuritySettings = (props: SecuritySettingsProps) => {
	const { openDrawer, onClose } = props

	const chatClasses = useChatStyles()

	const handleCloseDrawer = () => {
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		turnOnEventListenerKeydown(false)
		onClose()
	}

	const eventKeyDownHandler = (event: KeyboardEvent) => {
		const { key } = event

		if (key === "Escape") {
			handleCloseDrawer()
		}
	}

	const turnOnEventListenerKeydown = (turnOn: boolean) => {
		if (turnOn) {
			window.addEventListener(
				"keydown",
				eventKeyDownHandler,
				{ capture: true }
			)
		} else {
			window.removeEventListener(
				"keydown",
				eventKeyDownHandler,
				{ capture: true }
			)
		}
	}

	const handleOpenDeviceAndSessionsSettings = () => {
		DeviceAndSessionsSettingsDialog.open()
	}

	const handleOpenManagementUserAccessDataSettings = () => {
		ManagementUserAccessDataSettingsDialog.open()
	}

	useDidMount(() => {
		turnOnEventListenerKeydown(true)
	})

	useEffect(() => {
		turnOnEventListenerKeydown(false)
	})

	return (
		<>
			<AccessibleDrawer
				id="new-chat-drawer"
				anchor="left"
				open={openDrawer}
				variant="persistent"
				onClose={handleCloseDrawer}
				onMobileBackButtonPress={handleCloseDrawer}
				classes={{
					paper: chatClasses.drawerPaper
				}}
			>
				<Grid
					container
					spacing={2}
				>
					<Grid item xs={12}>
						<HeaderDrawer
							title="Configurações"
							onClose={handleCloseDrawer}
						/>
					</Grid>

					<Grid item xs={12}>
						<SettingsMenuItem
							key="device-and-sessions-settings"
							icon={<DeviceAndSessionsIcon />}
							text={"Dispositivos e Sessões"}
							onClick={handleOpenDeviceAndSessionsSettings}
						/>
					</Grid>
					<Grid item xs={12}>
						<SettingsMenuItem
							key="management-user-access-data-settings"
							icon={<UserDataIcon />}
							text={"Dados de Acesso do Usuário"}
							onClick={handleOpenManagementUserAccessDataSettings}
						/>
					</Grid>
				</Grid>
			</AccessibleDrawer>
		</>
	)
}

export default SecuritySettings
