import ApiService from "@/services/Api"
import { Notification } from "@/components"
import { useState } from "react"
import { MetaAdsPluginSettings } from "@/protocols/metaAdsProtocol"

const useMetaAdsApi = () => {
	const [isEnabledPlugin, setIsEnabledPlugin] = useState<boolean>(false)
	const getPluginSettings = async (): Promise<MetaAdsPluginSettings | undefined> => {
		try {
			const response = await ApiService.get("/plugin-settings", {
				params: {
					pluginType: "meta-ads"
				}
			})
			const settings = response.data.dataValues
			return settings
		} catch (error) {
			Notification.error({ message: "Não foi possível carregar os dados do plugin." })
		}
	}
	const hasEnabledPlugin = async (): Promise<void> => {
		const pluginSettings = await getPluginSettings()

		const isEnabledPlugin = pluginSettings?.status === "enabled"

		setIsEnabledPlugin(isEnabledPlugin)
	}
	return {
		getPluginSettings,
		hasEnabledPlugin,
		isEnabledPlugin
	}
}

export default useMetaAdsApi
