import React, { useState } from "react"
import {
	Grid,
	List,
	ListItem,
	ListItemText,
	Typography,
	Fab,
	Popover,
	Tooltip,
	Divider as MuiDivider
} from "@material-ui/core"

import {
	ExpandMore as MessageOptionsIcon,
	Smartphone as ExternalPlatformIcon
} from "@material-ui/icons"

import {
	Divider,
	MessageContentBox,
	InfoDialog,
	PopConfirm
} from "@/components"
import Reactions from "@/components/Reactions"
import { MediaMessageContent } from "@/components/Messages/MediaMessage"
import CatalogueMessage from "@/components/Messages/CatalogueMessage"
import ExtendedMessage from "@/components/Messages/ExtendedMessage"

import { isSmallScreen } from "@/utils/checkDevice"
import { getInboxMessageColor } from "@/utils/message"
import { transformReactionArrayIntoString } from "@/utils/reaction"

import ReplyMessage from "@/components/ACExternalConversationPanel/MessageList/ReplyMessage"
import MessageFeature from "@/components/ACExternalConversationPanel/MessageList/MessageFeature"
import MessageDeletedContent from "@/components/ACExternalConversationPanel/MessageList/MessageDeletedContent"
import InboxMessageFooter from "@/components/ACExternalConversationPanel/MessageList/InboxMessageFooter"

import useSocket from "@/hooks/useSocket"
import useCustomMemo from "@/hooks/useCustomMemo"

import HardCoded from "@/services/HardCoded"

import useStyles from "@/components/ACExternalConversationPanel/MessageList/CommonMessage/BaseMessage/styles"
import { useActiveCampaignExternalChatGlobalStateStore } from "@/store/ActiveCampaignExternalChatGlobalState"

import { ChannelType } from "@/protocols/channel"
import { Message } from "@/protocols/chatGlobalStateProtocol"

export type BaseMessageProps = {
	message: Message
	childContent: React.ReactElement
	childContentFooter?: React.ReactElement
	childContainerFooter?: React.ReactElement
	messageContentBoxStyle?: React.CSSProperties
	childContentHeader?: React.ReactElement
}

const BaseMessage: React.FC<BaseMessageProps> = (props) => {
	const {
		message,
		childContent,
		childContentFooter,
		childContainerFooter,
		messageContentBoxStyle,
		childContentHeader
	} = props

	const classes = useStyles()
	const activeCampaignExternalChatGlobalState = useActiveCampaignExternalChatGlobalStateStore()
	const socket = useSocket()

	const inboxChannelChat = activeCampaignExternalChatGlobalState.chat.current
	const currentChatAttendanceStatus = activeCampaignExternalChatGlobalState.chat.current?.attendance?.status

	const messageId = message.id
	const inboxChannelChatId = message.inboxChannelChatId
	const content = message.content
	const createdAt = message.createdAt
	const deletedAt = message.deletedAt
	const extraData = message.extraData
	const feature = message.feature
	const statusMessage = message.extraData?.statusMessage
	const status = message.status
	const catalogueOrderMessage = message.extraData?.catalogueOrderMessage
	const sentByCustomer = message.sentByCustomer
	const senderName = message.senderName
	const sentByExternalPlatform = message.sentByExternalPlatform
	const type = message.type
	const uploadingMedia = message.uploadingMedia
	const messageError = message.inboxChannelChatMessageLog?.error
	const replyMessage = message.replyMessage && {
		id: message.replyMessage.id,
		caption: message.replyMessage.caption,
		content: message.replyMessage.content,
		senderName: activeCampaignExternalChatGlobalState.chat.current?.client?.nickname || "",
		sentByCustomer: message.replyMessage.sentByCustomer,
		type: message.replyMessage.type,
		extraData: message.replyMessage.extraData
	}
	const editedMessage = message.extraData?.editedMessage
	const reactions = message?.extraData?.reactions
	const uploadProgressInPercentage = message?.uploadProgressInPercentage

	const canViewThumbnail = HardCoded.checkFeatureFlag("canViewThumbnail") && Boolean(message.extraData?.extendedMessage?.thumbnailUrl)

	const extendedMessage = message.extraData?.extendedMessage
	const extendedMessageHasExtendedUrl = extendedMessage && (Boolean(message.extraData?.extendedMessage?.extendedUrl) || canViewThumbnail)

	const reactionsString = reactions && transformReactionArrayIntoString(reactions)

	const showMessageOptionsButton = (
		currentChatAttendanceStatus === "active" &&
		!message.deletedAt &&
		!activeCampaignExternalChatGlobalState.conversationPanel.inputBlocked
	)

	const [messageOptionsRef, setMessageOptionsRef] = useState<HTMLElement | null>(null)
	const [optionsIconVisible, setOptionsIconVisible] = useState(false)

	const handleShowOptionsIcon = () => {
		setOptionsIconVisible(true)
	}

	const handleHideOptionsIcon = () => {
		setOptionsIconVisible(false)
	}

	const handleCloseMessageOptionsPopover = () => {
		setMessageOptionsRef(null)
	}

	const handleOpenMessageOptionsPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
		setMessageOptionsRef(event.currentTarget)
	}

	const handleClose = () => {
		handleCloseMessageOptionsPopover()
		handleHideOptionsIcon()
	}

	const handleReplyMessage = () => {
		handleClose()

		activeCampaignExternalChatGlobalState.conversationPanel.replyMessage.change(messageId)

		activeCampaignExternalChatGlobalState.conversationPanel.textInput.focus()
	}

	const handleDeleteMessage = () => {
		handleClose()

		if (!activeCampaignExternalChatGlobalState.chat.current) {
			return
		}

		const formattedMessageDate = new Date(message.createdAt)

		const beforeYesterdayDate = new Date()
		beforeYesterdayDate.setHours(beforeYesterdayDate.getHours() - 3)

		if (formattedMessageDate < beforeYesterdayDate) {
			InfoDialog.open({
				title: "Está mensagem não pode mais ser apagada",
				children: <Typography key="alert">
					Permitimos deletar mensagens até 3 horas após o envio, se for necessário deletar após esse prazo, considere fazer a exclusão pelo próprio celular!
				</Typography>,
				openDialog: true
			})
		} else {
			PopConfirm.open({
				title: "Excluir mensagem",
				description: "Deseja excluir esta mensagem?",
				confirmButtonText: "EXCLUIR",
				onConfirm: async () => {
					if (!activeCampaignExternalChatGlobalState.chat.current) {
						return
					}

					const { id, inboxChannelId, channelType } = activeCampaignExternalChatGlobalState.chat.current

					socket.deleteMessage({
						inboxChannelId,
						inboxChannelChatId: id,
						inboxChannelChatMessageId: messageId,
						channelType
					})
				}
			})
		}
	}
	const handleShowAdditionalInfo = () => {
		handleClose()

		InfoDialog.open({
			title: "Informações adicionais",
			children: (
				<Typography
					variant="body1"
				>
					<b>ID da Mensagem:</b> {messageId}
					<br />
					<b>ID do Chat:</b> {inboxChannelChatId}
					<br />
					<br />
					<b>Tipo da Mensagem:</b> {type}
					<br />
					<b>Status da Mensagem:</b> {status}
					<br />
					<b>Conteúdo da Mensagem:</b> {content}
					<br />
					<br />
					<b>Data de criação da Mensagem (Whatsapp):</b> {new Date(createdAt).toLocaleString()}
					<br />
					<b>Data de criação da Mensagem (Inbox):</b> {extraData?.inboxCreatedAt ? (
						new Date(extraData?.inboxCreatedAt).toLocaleString()
					) : "Data não encontrada"}
					{messageError && <>
						<br />
						<b>Erro:</b> {messageError}
					</>}
				</Typography>
			),
			openDialog: true
		})
	}

	const isGroupChat = inboxChannelChat?.type === "group"
	const messageOptionsPopoverOpened = Boolean(messageOptionsRef)
	const showExternalPlatformIcon = sentByExternalPlatform && sentByCustomer
	const showOptionsButton = (
		showMessageOptionsButton &&
		(optionsIconVisible || isSmallScreen)
	)

	if (isGroupChat && replyMessage) {
		replyMessage.senderName = message?.replyMessage?.senderName || ""
	}

	return useCustomMemo(() => (
		<>
			<Grid
				container
				direction="column"
				className={classes.messageContainer}
				justifyContent={sentByCustomer ? "flex-end" : "flex-start"}
				alignItems={sentByCustomer ? "flex-end" : "flex-start"}
				style={{ paddingBottom: reactions ? 22 : "auto" }}
			>
				<MessageContentBox
					id={messageId}
					onMouseEnter={() => handleShowOptionsIcon()}
					onMouseLeave={() => handleHideOptionsIcon()}
					uploadingMedia={uploadingMedia}
					mediaUploadProgress={uploadProgressInPercentage}
					style={{
						backgroundColor: getInboxMessageColor(sentByCustomer, status),
						...messageContentBoxStyle
					}}
				>
					{catalogueOrderMessage && <CatalogueMessage {...catalogueOrderMessage} />}
					{extendedMessageHasExtendedUrl && <ExtendedMessage {...extendedMessage} fileName={message.fileName} />}
					<Grid
						container
						alignItems="center"
						justifyContent="flex-end"
						className={classes.messageInfoContainer}
					>
						{showExternalPlatformIcon && (
							<Tooltip
								title="Essa mensagem foi enviada pelo Whatsapp (Celular ou Web)."
							>
								<ExternalPlatformIcon
									fontSize="small"
									className={classes.infoIcon}
								/>
							</Tooltip>
						)}

						<MessageFeature
							message={message}
							isAdmin={false}
						/>

						{showOptionsButton && (
							<>
								<Popover
									open={messageOptionsPopoverOpened}
									anchorEl={messageOptionsRef}
									onClose={handleCloseMessageOptionsPopover}
									anchorOrigin={{ vertical: "top", horizontal: "left" }}
									transformOrigin={{ vertical: "top", horizontal: "left" }}
								>
									<List>
										{currentChatAttendanceStatus === "active" && <ListItem
											button
											onClick={() => handleReplyMessage()}
										>
											<ListItemText primary="Responder" />
										</ListItem>}

										{sentByCustomer && currentChatAttendanceStatus === "active" && (
											<ListItem
												button
												onClick={() => handleDeleteMessage()}
											>
												<ListItemText primary="Apagar para todos" />
											</ListItem>
										)}

										<ListItem
											button
											onClick={handleShowAdditionalInfo}
										>
											<ListItemText primary="Informações adicionais" />
										</ListItem>
									</List>
								</Popover>

								<Fab
									onClick={(event) => handleOpenMessageOptionsPopover(event)}
									/**
									 * Setting up that way instead of using className since for some reason
									 * the className disappears in production.
									 */
									style={{
										minHeight: 30,
										height: 30,
										width: 30,
										boxShadow: "none",
										backgroundColor: "transparent"
									}}
								>
									<MessageOptionsIcon />
								</Fab>
							</>
						)}
					</Grid>

					{(replyMessage && !deletedAt) && (
						<>
							<ReplyMessage
								id={replyMessage.id}
								senderName={replyMessage.senderName}
								sentByCustomer={replyMessage.sentByCustomer}
								content={replyMessage.content}
								type={replyMessage.type}
								caption={replyMessage.caption}
								extraData={replyMessage.extraData}
							/>

							<Divider orientation="horizontal" size={1} />
						</>
					)}
					{HardCoded.checkFeatureFlag("isAbleToSeeStatusMessageResponse") && statusMessage && <>
						<Grid item>
							<Typography variant="body2"> Resposta de status</Typography>
						</Grid>
						<Grid item>
							<MediaMessageContent
								content={statusMessage.thumbnail}
								caption={message.caption}
								type={"picture"}
								fancyBoxSlug={message.fileName}
								fileName={message.fileName}
							/>
						</Grid>
						<Divider orientation="horizontal" size={1} />
					</>}

					{(isGroupChat && !sentByCustomer) && (
						<>
							<Typography
								variant="caption"
								className={classes.messageSenderName}
							>
								{message.senderName}
							</Typography>

							<Divider orientation="horizontal" size={0.5} />
						</>
					)}

					{childContentHeader && (
						<>
							{childContentHeader}

							<Divider orientation="horizontal" size={0.5} />

							<MuiDivider />

							<Divider orientation="horizontal" size={0.5} />
						</>
					)}

					{deletedAt ? (
						<MessageDeletedContent
							sentByCustomer={sentByCustomer}
						/>
					) : (
						childContent
					)}

					<Divider orientation="horizontal" size={1} />

					{childContentFooter && (
						<>
							<Divider orientation="horizontal" size={0.5} />

							<MuiDivider />

							<Divider orientation="horizontal" size={0.5} />

							{childContentFooter}
						</>
					)}

					<InboxMessageFooter
						status={status}
						sentByExternalPlatform={sentByExternalPlatform}
						senderName={senderName}
						sentByCustomer={sentByCustomer}
						messageExtraData={message?.extraData}
						feature={feature}
						createdAt={createdAt}
						deletedAt={deletedAt}
						messageId={messageId}
						error={messageError}
						channelType={inboxChannelChat?.channelType as ChannelType}
					/>

					{reactions && <Reactions reactions={reactions} maxReactionsAllowed={2} />}
				</MessageContentBox>

				{childContainerFooter && (
					<>
						<Divider orientation="horizontal" size={0.5} />

						{childContainerFooter}
					</>
				)}
			</Grid>
		</>

	), [
		createdAt,
		deletedAt,
		content,
		status,
		editedMessage,
		uploadingMedia,
		messageError,
		optionsIconVisible,
		messageOptionsRef,
		currentChatAttendanceStatus,
		reactionsString,
		uploadProgressInPercentage
	])
}

export default BaseMessage
