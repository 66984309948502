import React, { useState } from "react"

import { InfoDialog } from "@/components"
import { renderComponent } from "@/utils/node"

import DevicesAndSessions from "@/pages/Admin/Security/DevicesAndSessions"

type DeviceAndSessionsSettingsDialogType = {
	open: () => void
}

const DeviceAndSessionsSettingsDialog: DeviceAndSessionsSettingsDialogType & React.FC = () => {
	const [opened, setOpened] = useState(true)

	const handleClose = () => {
		setOpened(false)
	}

	return (
		<InfoDialog
			title="DISPOSITIVOS E SESSÕES"
			openDialog={opened}
			onClose={handleClose}
			maxWidth="md"
		>
			<DevicesAndSessions
				onReload={handleClose}
			/>
		</InfoDialog>
	)
}

DeviceAndSessionsSettingsDialog.open = () => {
	renderComponent("device-and-settings", <DeviceAndSessionsSettingsDialog />)
}

export default DeviceAndSessionsSettingsDialog
