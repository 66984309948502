import React, { useState, useEffect, useRef } from "react"
import { Grid, Typography, IconButton, Select, MenuItem, Button, CircularProgress } from "@material-ui/core"
import FileDownload from "js-file-download"

import ApiService from "@/services/Api"
import ErrorHandler from "@/services/ErrorHandler"

import { MINUTE_IN_MS } from "@/utils/time"

import useStyles from "@/pages/Admin/Dashboard/Metrics/AttendancesMetrics/styles"

import { Divider, Portlet, Loading, ActionDialog } from "@/components"

import { Refresh as RefreshIcon } from "@material-ui/icons"

import DailyAttendancesChart from "@/pages/Admin/Dashboard/Metrics/AttendancesMetrics/DailyAttendancesChart"
import CumulativeAttendancesChart from "@/pages/Admin/Dashboard/Metrics/AttendancesMetrics/CumulativeAttendancesChart"

import useDidMount from "@/hooks/useDidMount"
import useCustomStyles from "@/styles/custom"
import useContainerStyles from "@/pages/Admin/Dashboard/Metrics/ProductMessageMetrics/styles"
import { ErrorType } from "@/hooks/useValidation"

import { AttendancesCount } from "@/protocols/metrics"

import AttendancesMetricsSkeleton from "@/skeletons/Admin/AttendancesMetricsSkeleton"

import DonwloadFileIcon from "@/assets/icons/styled_file_download_icon.svg"

import UserInInstanceService from "@/services/UserInInstance"
import { UserInInstance } from "@/protocols/userInInstance"

import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import ptBR from "date-fns/locale/pt-BR"
import addDays from "date-fns/addDays/index"
import DateFnsUtils from "@date-io/date-fns"
import { Alert } from "@material-ui/lab"

type AttendancesMetricsData = {
	attendancesCount: AttendancesCount
	avgWaitTimeInMs: number
	avgAttendanceTimeInMs: number
	attendancesHistogram: Array<{
		day: Date
		attendancesCount: AttendancesCount
	}>
}

const AttendancesMetrics: React.FC = () => {
	const [metrics, setMetrics] = useState<AttendancesMetricsData>()
	const [nLastDays, setNLastDays] = useState(7)
	const [attendants, setAttendants] = useState<UserInInstance[]>([])
	const [attendantId, setAttendantId] = useState(0)
	const [loading, setLoading] = useState(true)
	const [openExportAttendancesCsv, setOpenExportAttendancesCsv] = useState(false)
	const [exportingAttendanceCsv, setExportingAttendanceCsv] = useState(false)
	const [timeRange, setTimeRange] = useState<{ startDate: Date, endDate: Date }>({ startDate: new Date(), endDate: new Date() })
	const classes = useStyles()
	const customClasses = useCustomStyles()
	const containerStyles = useContainerStyles()
	// eslint-disable-next-line
	const getAndUpdateMetrics = useRef(() => { })

	getAndUpdateMetrics.current = async () => {
		setLoading(true)

		try {
			const { data } = await ApiService.get("/metrics/attendances", {
				params: {
					nLastDays,
					attendantId: attendantId === 0 ? undefined : attendantId
				}
			})
			setMetrics(data)
		} catch (error) {
			ErrorHandler.handle(error as ErrorType)
		}

		setLoading(false)
	}

	const handleOpenExportAttendancesCsvDialog = async () => {
		setOpenExportAttendancesCsv(true)
	}
	const handleCloseExportAttendancesCsvDialog = async () => {
		setOpenExportAttendancesCsv(false)
	}
	const handleExportCsvAttendancesMetrics = async () => {
		setExportingAttendanceCsv(true)
		try {
			const response = await ApiService.post("/metrics/attendances/history/csv", {
				...timeRange
			})
			FileDownload(response.data, "atendimentos.csv")
		} catch (error) {
			ErrorHandler.handle(error as ErrorType)
		}
		handleCloseExportAttendancesCsvDialog()
		setExportingAttendanceCsv(false)
	}

	const handleChangeNLastDays = (value: number) => {
		setNLastDays(value)
	}

	const handleChangeAttendantId = (value: number) => {
		setAttendantId(value)
	}

	const handleChangeTimeRange = (time: string, field: string) => {
		setTimeRange(lastState => {
			return { ...lastState, [field]: time }
		})
	}

	const getAttendants = async () => {
		const data = await UserInInstanceService.getUsersInInstance({
			type: ["human", "virtual-attendant"],
			status: ["active"]
		})

		if (data) {
			setAttendants(data)
		}
	}

	useEffect(() => {
		getAndUpdateMetrics.current()
	}, [nLastDays, attendantId, getAndUpdateMetrics])

	useDidMount(() => {
		getAttendants()
	})

	return (
		<>
			<Grid container>
				<Grid item xs={12}>
					<Grid container className={containerStyles.constainerDivision} >
						<Grid item >
							<Grid container alignItems="center">
								<Grid item>
									<Typography
										variant="h2"
										color="textPrimary"
										style={{ fontSize: 18 }}
										className={customClasses.uppercase}
									>
										Atendimentos
									</Typography>

								</Grid>
								<Divider orientation="vertical" size={1} />
								<Grid item>
									<Button
										variant="text"
										color="primary"
										style={{ fontSize: 14, fontWeight: 600 }}
										onClick={handleOpenExportAttendancesCsvDialog}
										endIcon={exportingAttendanceCsv ? <CircularProgress size={20} /> : <img src={DonwloadFileIcon} alt="donwload icon" />}
									>
										EXPORTAR CSV
									</Button>
								</Grid>
							</Grid>
						</Grid>

						<Grid item>
							<Grid container spacing={1} justifyContent="flex-end" alignItems="center">
								<Grid item>
									<IconButton onClick={getAndUpdateMetrics.current} disabled={loading}>
										<RefreshIcon />
									</IconButton>
								</Grid>

								<Grid item >
									<Select
										fullWidth
										variant="outlined"
										value={attendantId}
										onChange={({ target }) => handleChangeAttendantId(target.value as number)}
										disabled={loading}
									>
										<MenuItem value={0}>Todos atendentes</MenuItem>

										{attendants.map((attendant) => (
											<MenuItem key={attendant.id} value={attendant.id}>
												{attendant.name}
											</MenuItem>
										))}
									</Select>
								</Grid>

								<Grid item >
									<Select
										fullWidth
										variant="outlined"
										value={nLastDays}
										onChange={({ target }) => handleChangeNLastDays(target.value as number)}
										disabled={loading}
									>
										<MenuItem value={0}>Hoje</MenuItem>
										<MenuItem value={7}>Últimos 7 dias</MenuItem>
									</Select>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Divider orientation="horizontal" size={2} />
				<Grid item>
					<Loading loading={loading} customLoadingElement={<AttendancesMetricsSkeleton />}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<DailyAttendancesChart attendancesHistogram={metrics?.attendancesHistogram} />
							</Grid>

							<Grid item xs={12} md={6}>
								<CumulativeAttendancesChart attendancesCount={metrics?.attendancesCount} />
							</Grid>

							<Grid item xs={12} md={6}>
								<Portlet style={{ height: "100%" }}>
									<Grid container spacing={2} className={classes.indicatorContainer} justify="center">
										<Grid item xs={12}>
											<Typography variant="h2" className={customClasses.uppercase}>
												Tempo médio por atendimento
											</Typography>
										</Grid>

										<Grid item >
											<Grid container alignItems="center" direction="column" spacing={1}>
												<Grid item>
													<Typography align="center" className={classes.largeText}>
														{Math.round((metrics?.avgAttendanceTimeInMs || 0) / MINUTE_IN_MS)}
													</Typography>
												</Grid>

												<Grid item>
													<Typography
														align="center"
														color="textPrimary"
														variant="caption"
														className={customClasses.uppercase}
													>
														minutos
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Portlet>
							</Grid>

							<Grid item xs={12} md={6}>
								<Portlet style={{ height: "100%" }}>
									<Grid container spacing={2} className={classes.indicatorContainer} justify="center">
										<Grid item xs={12}>
											<Typography variant="h2" className={customClasses.uppercase}>
												Tempo médio na fila de espera
											</Typography>
										</Grid>

										<Grid item >
											<Grid container alignItems="center" direction="column" spacing={1}>
												<Grid item>
													<Typography align="center" className={classes.largeText}>
														{Math.round((metrics?.avgWaitTimeInMs || 0) / MINUTE_IN_MS)}
													</Typography>
												</Grid>

												<Grid item>
													<Typography
														align="center"
														color="textPrimary"
														variant="caption"
														className={customClasses.uppercase}
													>
														minutos
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Portlet>
							</Grid>
						</Grid>
					</Loading>
				</Grid>
			</Grid>
			<ActionDialog
				title="Aviso"
				onSave={handleExportCsvAttendancesMetrics}
				saveText="Download"
				loading={exportingAttendanceCsv}
				cancelText="Voltar"
				onClose={handleCloseExportAttendancesCsvDialog}
				openDialog={openExportAttendancesCsv}
			>
				<Grid container>
					<Grid item>
						<Typography>
							O relatório a ser baixado é um arquivo em CSV que contém
							os dados dos <b>do periodo selecionado</b> de operação no atendimento
						</Typography>
					</Grid>
					<Divider orientation="horizontal" size={2} />
					<Grid container justifyContent="space-between">
						<Grid item>
							<Typography variant="subtitle1">
								Data de inicio:
							</Typography>
							<Divider orientation="horizontal" size={1} />
							<MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
								<DateTimePicker
									ampm={false}
									format="dd/MM/yyyy HH:mm"
									animateYearScrolling
									views={["year", "month", "date", "hours", "minutes"]}
									variant="inline"
									value={timeRange.startDate}
									inputVariant="outlined"
									fullWidth
									onChange={(date) => handleChangeTimeRange(date, "startDate")}
									autoOk
								/>
							</MuiPickersUtilsProvider>
						</Grid>

						<Grid item>
							<Typography variant="subtitle1">
								Data final:
							</Typography>
							<Divider orientation="horizontal" size={1} />
							<MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
								<DateTimePicker
									ampm={false}
									format="dd/MM/yyyy HH:mm"
									animateYearScrolling
									views={["year", "month", "date", "hours", "minutes"]}
									variant="inline"
									value={timeRange.endDate}
									inputVariant="outlined"
									fullWidth
									minDate={timeRange.startDate}
									maxDate={timeRange.startDate && addDays(timeRange.startDate, 60)}
									minDateMessage="A data final deve estar até 60 dias da inicial"
									onChange={(date) => handleChangeTimeRange(date, "endDate")}
									autoOk
								/>
							</MuiPickersUtilsProvider>
						</Grid>
					</Grid>
					<Divider orientation="horizontal" size={2} />
					<Grid item>
						<Alert severity="warning" icon={false}>
							O filtro por data está disponível para todos os usuários, limitado a um intervalo máximo de 60 dias.
						</Alert>
					</Grid>
				</Grid>
			</ActionDialog >
		</>
	)
}

export default AttendancesMetrics
