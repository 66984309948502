import React from "react"
import { TextField, Select, MenuItem, Grid } from "@material-ui/core"
import BlockBodySectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionEditor"
import useContentEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useContentEditor"
import { ChatBotFlowBlockContent, ConversionEvent } from "@/protocols/chatBot"
import SelectInput from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/SelectInput"

type MetaAdsEventConversionSectionEditorProps = {
	onSave: (chatBotFlowBlockContent: ChatBotFlowBlockContent) => void
	chatBotFlowBlockContent: ChatBotFlowBlockContent
}

const MetaAdsEventConversionSectionEditor: React.FC<MetaAdsEventConversionSectionEditorProps> = ({
	chatBotFlowBlockContent,
	onSave,
	children
}) => {
	const contentEditor = useContentEditor({ chatBotFlowBlockContent })

	const defaultMetaAdsConversionEventOption: ConversionEvent = {
		name: "",
		value: "",
		type: "native"
	}

	const metaAdsConversionEvent = contentEditor.content?.metaAdsConversionEvent || defaultMetaAdsConversionEventOption

	const eventNameToLabel: Record<ConversionEvent["value"], ConversionEvent["name"]> = {
		ViewContent: "Ver conteúdo",
		Search: "Pesquisar",
		AddToWishlist: "Adicionar à lista de desejos",
		AddToCart: "Adicionar ao carrinho",
		AddPaymentInfo: "Adicionar informações de pagamento",
		Purchase: "Comprar",
		Subscribe: "Assinar",
		StartTrial: "Iniciar período de avaliação",
		CompleteRegistration: "Concluir inscrição",
		Contact: "Contato",
		FindLocation: "Encontrar localização",
		Schedule: "Programar",
		CustomizeProduct: "Personalizar produto",
		Donate: "Doar",
		InitiateCheckout: "Iniciar finalização da compra",
		Lead: "Lead",
		SubmitApplication: "Enviar candidatura"
	}

	const selectoptions = Object.entries(eventNameToLabel).map(([value, label]) => ({
		value: value,
		name: label
	}))

	const handleCustomEventData = (key: keyof ConversionEvent, value: string) => {
		contentEditor.changeContent({
			metaAdsConversionEvent: {
				...metaAdsConversionEvent,
				name: value,
				[key]: value
			}
		})
	}

	const handleNativeEventData = (key: keyof ConversionEvent, value: string) => {
		contentEditor.changeContent({
			metaAdsConversionEvent: {
				...metaAdsConversionEvent,
				name: eventNameToLabel[value],
				[key]: value
			}
		})
	}

	const handleChange = (key: keyof ConversionEvent, value: string) => {
		const eventTypeToEventHandler: Record<ConversionEvent["type"], () => void> = {
			custom: () => handleCustomEventData(key, value),
			native: () => handleNativeEventData(key, value)
		}

		const handler = eventTypeToEventHandler[metaAdsConversionEvent.type]

		if (handler) {
			handler()
		}
	}

	const handleChangeType = (type: ConversionEvent["type"]) => {
		contentEditor.changeContent({
			metaAdsConversionEvent: {
				value: "",
				name: "",
				type
			}
		})
	}

	const handleOpen = () => {
		contentEditor.reset()
	}

	const handleSave = () => {
		onSave(contentEditor.content)
	}

	return (
		<BlockBodySectionEditor
			title="Evento de Conversão"
			onSave={handleSave}
			onOpen={handleOpen}
			AsideDrawerProps={{
				children: (
					<Grid container xs={12} spacing={2}>
						<Grid item xs={12}>
							<Select
								labelId="conversion-type-label"
								label="Tipo de Evento"
								variant="outlined"
								fullWidth
								value={metaAdsConversionEvent?.type}
								onChange={({ target }) => handleChangeType(target.value as ConversionEvent["type"])}
							>
								<MenuItem value="custom">Eventos de Conversão Customizados</MenuItem>
								<MenuItem value="native">Eventos de Conversão Nativos</MenuItem>
							</Select>
						</Grid>
						<Grid item xs={12}>
							{metaAdsConversionEvent?.type === "native" ? (
								<SelectInput
									defaultOption={{ name: metaAdsConversionEvent.name }}
									onChange={(target) => handleChange("value", target?.value as ConversionEvent["value"])}
									options={selectoptions}
									placeholder="Evento de conversão"
									getOptionLabel={(option) => option.name}
									noOptionsText="Evento de conversão não encontrado"
								/>
							) : (
								<TextField
									variant="outlined"
									placeholder="Nome do evento"
									value={metaAdsConversionEvent?.value}
									onChange={({ target }) => handleChange("value", target.value as string)}
									fullWidth
								/>
							)}
						</Grid>
					</Grid>
				)
			}}
		>
			{children}
		</BlockBodySectionEditor>
	)
}

export default MetaAdsEventConversionSectionEditor
