import colors from "@/styles/colors"
import newColors from "@/styles/newColors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	eventName: {
		textAlign: "start",
		whiteSpace: "nowrap",
		overflow: "hidden",
		maxWidth: 227,
		textOverflow: "ellipsis",
		backgroundColor: newColors.blue[400],
		borderRadius: "8px",
		color: colors.grayScale[11],
		fontWeight: "bold"
	},
	conversionEventName: {
		width: "100%"
	}
})

export default useStyles
